import React from "react";
import Countdown from "react-countdown";
import { CarouselAuctionDetails } from "../carousel/CarouselAuctionDetails";
import i18n from "../../i18n";
import { getAuction, addViewCount } from "../../models/auctions";
import { AuctionsDetailTabs } from "./helpers/auctionDetailTabs";
import { BiddingComp } from "../helpers/biddingComp";
import { AuctionShare } from "./helpers/auctionShare";
import MapPicker from "react-google-map-picker";
import { SkeletonAuctionDetails } from "../helpers/skeletons/skeletonAuctionDetails";
import { AuctionDocuments } from "./helpers/auctionDocuments";
import labelarabic from "../../images/labelarabic.png";
import LabelEng from "../../images/LabelEng.png";

let current_lng = localStorage.getItem("I18N_LANGUAGE");
const FinishBid = () => (
  <div className="p-3 text-center text-success" style={{ fontSize: "1.1rem" }}>
    {i18n.t("auction_started")}
  </div>
);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className=" text-success">
        <div className="timer-div-1">{i18n.t("starts_after")}</div>
        <div className="timer-div-2">
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_day")}</div>
            <div className="timer-count">{days} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_hour")}</div>
            <div className="timer-count">{hours} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_minute")}</div>
            <div className="timer-count">{minutes} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_second")}</div>
            <div className="timer-count">{seconds}</div>
          </div>
        </div>
      </div>
    );
  }
};
export class AuctionDetails extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auctionId: auctionId,
      auction: [],
      toggleArrow: false,
      bid_increment: 0,
      current_bid_amount: 0,
      user_bid_amount: 0,
      left_button_disable: true,
      is_bid_sending: false,
      bid_place_message: "",
      enquiries: [{ label: "", value: "" }],
      is_loading: false,
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.addViewCount();
    this.loadAuction();
  }

  async addViewCount() {
    await addViewCount({ auction_id: this.state.auctionId });
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    let id = this.state.auctionId;
    const response = await getAuction(id);
    if (response) {
      let auction = response.data;
      let enquiry = auction.enquiry;
      auction.selected_mask = auction.mask;
      this.setState({
        auction: auction,
        current_bid_amount: auction.start_amount,
        enquiries: enquiry,
        is_loading: false,
      });
    }
  }
  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.state.auction;
    let action_name = current_lng === "ar" ? auction.title_ar : auction.title;
    let start_date = auction.start_date_formatted;
    let end_date = auction.end_date_formatted;
    let auction_images = auction.images;

    let statusAr = auction.status_label;
    let disp_status = "";
    let statusClass = "";
    let statusClasses = {
      E: "",
      F: "status-feat",
      A: "status-act",
      U: "status-upc",
    };
    if (statusAr && statusAr["status"]) {
      statusClass = statusClasses[statusAr["status"]];
      disp_status = current_lng === "ar" ? statusAr["ar"] : statusAr["en"];
    }
    let startDateAr = auction.start_date_ar;
    let endDateAr = auction.end_date_ar;

    let now = this.state.now;
    let endTime = new Date(this.createDate(auction.start_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));

    return (
      <React.Fragment>
        {this.state.is_loading && (
          <div className="cover-image sptb-1 bg-white text-center mt-8 mb-8">
            <SkeletonAuctionDetails />
          </div>
        )}

        {!this.state.is_loading && (
          <div className="">
            <div>
              <div
                className="cover-image sptb-1 bg-background"
                data-image-src="../assets/images/banners/banner1.jpg"
              >
                <div className="header-text1 mb-0">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-12 d-block mx-auto">
                        <div className="text-center">
                          <h1 className="mb-5 auction-title-cust">
                            <div className="ribbon-custom">
                              <span className={statusClass}>{disp_status}</span>
                            </div>
                            {action_name}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="sptb auction-details-section">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-12">
                    <div className="card overflow-hidden image-holder">
                      {auction.status_dis === "A" && (
                        <div className="ribbon ribbon-top-left text-danger">
                          <span className="bg-active">{i18n.t("active")}</span>
                        </div>
                      )}
                      {auction.status_dis === "E" && (
                        <div className="ribbon ribbon-top-left text-warning">
                          <span className="bg-expiry">{i18n.t("expired")}</span>
                        </div>
                      )}
                      {auction.status_dis === "U" && (
                        <div className="ribbon ribbon-top-left text-warning">
                          <span className="bg-upcoming">
                            {i18n.t("upcoming")}
                          </span>
                        </div>
                      )}
                      {auction.status_dis === "F" && (
                        <div className="ribbon ribbon-top-left text-warning">
                          <span className="bg-featured">
                            {i18n.t("featured")}
                          </span>
                        </div>
                      )}
                      <div className="card-body" style={{ padding: 0 }}>
                        <CarouselAuctionDetails images={auction_images} />
                        <div className="carousel-bottom-details">
                          <div className="detail-item">
                            <a href={true} className="icons text-muted">
                              {i18n.t("bid_counr")}
                              {"#:"}
                              {auction.bid_count}
                            </a>
                          </div>
                          {auction.vat && (
                            <div className="detail-item">
                              <a href={true} className="icons text-muted">
                                {i18n.t("vat")} :{auction.vat}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row p-5">
                        <div className="one-by-three">
                          <div
                            className="auc-box text-center"
                            style={{ minHeight: "8.5rem", marginBottom: "1px" }}
                          >
                            <h4 className="mb-5">
                              {startDateAr && startDateAr["day"]
                                ? i18n.t(startDateAr["day"])
                                : ""}
                            </h4>
                            <p className="m-0 lineHeight1">
                              {i18n.t("start_date")}
                            </p>
                            <p
                              className="m-0 lineHeight1"
                              style={{
                                fontSize:
                                  current_lng === "ar" ? "2rem" : "1.2rem",
                              }}
                            >
                              {startDateAr && startDateAr["date"]
                                ? startDateAr["date"]
                                : ""}
                            </p>
                            <p className="m-0 lineHeight1">({start_time})</p>
                            <div className="">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/days_of-auction.png"
                                }
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "1px solid #473828",
                                  borderRadius: "5px",
                                  margin: "0.5rem",
                                }}
                                alt=""
                              />
                              <span className="text-center">
                                {" "}
                                {auction.days_remaining}{" "}
                              </span>
                              <span className="text-center mb-0">
                                {" "}
                                {i18n.t("days_of_auction")}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="one-by-three one-by-40">
                          <div className="auc-timer">
                            {auction.is_bid_time_ends && (
                              <div className="p-3 text-center text-danger">
                                {i18n.t("bid_time_ends")}
                              </div>
                            )}

                            {!auction.is_bid_time_ends && auction.end_date && (
                              <Countdown
                                date={endTime}
                                renderer={renderer}
                                onComplete={() => {
                                  this.setState({ bidTimeStopped: true });
                                }}
                                now={now}
                              />
                            )}
                          </div>
                          <div
                            className="auc-box font-1rem"
                            style={{
                              padding: "0.9rem 1.2rem",
                            }}
                          >
                            <div className="row">
                              <div className="col-7 m-0 p-0">
                                {i18n.t("start_bid_amount")} :{" "}
                              </div>
                              <div className="col-5 m-0 p-0">
                                {current_lng === "ar" ? i18n.t("omr") : " "}{" "}
                                {auction.start_amount}{" "}
                                {current_lng === "ar" ? " " : i18n.t("omr")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-7 m-0 p-0">
                                {i18n.t("gurantee_amount")} :{" "}
                              </div>
                              <div className="col-5 m-0 p-0">
                                {current_lng === "ar" ? i18n.t("omr") : " "}{" "}
                                {auction.guarantee_amount}{" "}
                                {current_lng === "ar" ? " " : i18n.t("omr")}
                              </div>
                            </div>
                            <div className="row text-danger">
                              <div className="col-7 m-0 p-0">
                                {i18n.t("current_amount")} :{" "}
                              </div>
                              <div className="col-5 m-0 p-0">
                                {current_lng === "ar" ? i18n.t("omr") : " "}{" "}
                                {auction.current_amount}{" "}
                                {current_lng === "ar" ? " " : i18n.t("omr")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="one-by-three">
                          <div className="auc-box">
                            <div
                              className="ms-auto text-primary"
                              style={{
                                direction: current_lng === "ar" ? "rtl" : "ltr",
                                fontSize: "1rem",
                              }}
                            >
                              <a
                                href={true}
                                style={{ fontSize: "1.2rem" }}
                                className="icon d-none d-md-inline-block ms-5"
                                onClick={(event) => {
                                  this.addWishList(!this.state.wishlist);
                                }}
                              >
                                <i
                                  className={
                                    this.state.wishlist
                                      ? "fa fa-heart"
                                      : "fa fa-heart-o"
                                  }
                                ></i>
                              </a>
                              <a
                                href={true}
                                style={{ fontSize: "1.2rem" }}
                                className="icon d-none d-md-inline-block ms-5"
                                onClick={(event) => {
                                  this.addLike(!this.state.like);
                                }}
                              >
                                <i
                                  className={
                                    this.state.like
                                      ? "fa fa-thumbs-up"
                                      : "fa fa-thumbs-o-up"
                                  }
                                ></i>
                              </a>
                              <a
                                href={true}
                                className="icon d-none d-md-inline-block"
                                style={{
                                  fontSize: "1.2rem",
                                  float:
                                    current_lng === "ar" ? "left" : "right",
                                }}
                              >
                                <span className="text-primary d-flex">
                                  <span
                                    className="text-danger"
                                    style={{
                                      marginRight:
                                        current_lng === "ar" ? "" : "5px",
                                      marginLeft:
                                        current_lng === "ar" ? "5px" : "",
                                      marginTop:
                                        current_lng === "ar" ? "-4px" : "-1px",
                                      fontSize:
                                        current_lng === "ar"
                                          ? "1.1rem"
                                          : "1rem",
                                    }}
                                  >
                                    {auction.total_views}
                                  </span>
                                  <span>
                                    <i className="icon icon-eye"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <hr
                              style={{
                                marginTop: "0.1rem",
                                marginBottom: "0rem",
                              }}
                            />
                            <div className="auc-box">
                              <div style={{ clear: "both" }}>
                                <span>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/icons/time.png"
                                    }
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      border: "1px solid #473828",
                                      borderRadius: "20px",
                                      margin: "0.7rem 0",
                                      float:
                                        current_lng === "ar" ? "right" : "left",
                                    }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  style={{
                                    fontSize:
                                      current_lng === "ar" ? "1.5rem" : "1rem",
                                    margin:
                                      current_lng === "ar"
                                        ? "0.2rem 0.3rem"
                                        : "0.7rem 0.3rem",
                                    float:
                                      current_lng === "ar" ? "right" : "left",
                                  }}
                                >
                                  &nbsp;{i18n.t("time")}
                                </span>
                                <span
                                  style={{
                                    fontSize:
                                      current_lng === "ar" ? "1.5rem" : "1rem",
                                    margin:
                                      current_lng === "ar"
                                        ? "0.2rem 0.3rem"
                                        : "0.7rem 0.3rem",
                                    float:
                                      current_lng === "ar" ? "left" : "right",
                                  }}
                                >
                                  {i18n.t("end_date")}
                                </span>
                              </div>
                              <div style={{ clear: "both" }}>
                                <span
                                  style={{
                                    float:
                                      current_lng === "ar" ? "right" : "left",
                                  }}
                                >
                                  ({end_time})
                                </span>
                                <span
                                  style={{
                                    float:
                                      current_lng === "ar" ? "left" : "right",
                                  }}
                                >
                                  {endDateAr && endDateAr["date"]
                                    ? endDateAr["date"]
                                    : ""}
                                </span>
                              </div>
                              <div className="approve_lbl">
                                <img
                                  src={
                                    current_lng === "ar"
                                      ? labelarabic
                                      : LabelEng
                                  }
                                  alt=""
                                  className="m-auto"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AuctionDocuments auction={auction} lng={current_lng} />
                    <AuctionsDetailTabs auction={auction} lng={current_lng} />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12">
                    {this.state.auctionId && auction && (
                      <BiddingComp auction={auction} lng={current_lng} />
                    )}

                    <div className="card">
                      <div className="card-header" style={{ zIndex: "9" }}>
                        <h3 className="card-title">
                          {i18n.t("project_location")}
                        </h3>
                      </div>
                      <div
                        className="card-body"
                        style={{
                          padding: 0,
                          margin: "-20px 0px 0px 0px",
                          zIndex: 1,
                        }}
                      >
                        <div className="">
                          <div className="map-header-layer" id="map2">
                            {auction.latitude && auction.longitude && (
                              <MapPicker
                                defaultLocation={{
                                  lat: parseFloat(auction.latitude),
                                  lng: parseFloat(auction.longitude),
                                }}
                                zoom={15}
                                mapTypeId="roadmap"
                                style={{ height: "50vh" }}
                                apiKey="AIzaSyCtGIYq4ASmgZhxWY8VadcPd5XXXSofK5Q"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer"
                        style={{ background: "#f1f3f4" }}
                      >
                        <a
                          href={
                            "https://www.google.com/maps?q=" +
                            auction.latitude +
                            ", " +
                            auction.longitude
                          }
                          target="_new"
                          className="btn btn-primary btn-block text-white"
                        >
                          {i18n.t("open_google_map")}
                        </a>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{i18n.t("social_share")}</h3>
                      </div>
                      <div className="card-body">
                        <AuctionShare auction={auction} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </React.Fragment>
    );
  }
}
