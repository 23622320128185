import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
};

const endpoint = apiUrl + "groups";
const endpoint_main = apiUrl + "main_groups";
const user = getLoggedUser();
var header = {
  "Content-Type": "application/json",
};
if (user != null && user.token) {
  header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.token,
  };
}
async function createMainGroup(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint_main, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function createGroup(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllMainGroups() {
  return fetch(endpoint_main, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAllGroups() {
  return fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getMainGroup(id) {
  return fetch(endpoint_main + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateMainGroup(payload, id) {
  return fetch(endpoint_main + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateGroup(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      endpoint + "/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteMainGroup(id) {
  return fetch(endpoint_main + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

function deleteGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGroupsByTypes(type, subType) {
  return fetch(`getGroups/${type}/${subType}`, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getEnrolledGroups() {
  return fetch(apiUrl + "groups_enrolled", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createGroup,
  getAllGroups,
  getGroup,
  updateGroup,
  deleteGroup,
  getGroupsByTypes,
  getEnrolledGroups,
  createMainGroup,
  getAllMainGroups,
  getMainGroup,
  updateMainGroup,
  deleteMainGroup,
  maskClasses,
};
