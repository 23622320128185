import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export class WhoWeAre extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="auction-title-cust auction-title-cust-1">
                    {i18n.t("aboutus")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb cont-pad">
          <div className="container mb-8">
            <div className="text-justify">
              <p className="leading-Automatic">
                The Ministry of Defence undertakes the implementation of Oman
                defence policy, based on the enlightened thought and the Royal
                directives of His Majesty Sultan Haitham bin Tariq, the Supreme
                Commander - may God protect him - through well-thought-out plans
                and conscious planning that enable Sultan Armed Forces (SAF) to
                play its role in the country’s comprehensive development
                process. This comes in the belief that development must have an
                impenetrable fence that protects and prepares it for prosperity
                and progress, and that the pillars of security, safety,
                stability and peace are contingent on building a military force
                at a high level of efficiency and readiness to be capable of
                defending the homeland and preserving its sanctities.
              </p>
              <p className="leading-Automatic">
                His Majesty the Sultan has been very keen - may God protect him
                - since he took the reins of power in the country on 11 January
                2020 – to take care of His Armed Forces to ensure that it
                continues to perform the sacred duties with high combat
                efficiency, according to a rule that depends primarily on
                building and rehabilitating of human being in the best way to
                fulfill their role in these forces that have been restructured
                according to contemporary modern foundations within the
                framework of national strategic constants to keep pace with the
                requirements of the third millennium and the challenges of the
                twenty-first century.During the course of the modern Omani
                Renaissance, SAF's three components ( the Royal Army of Oman,
                the Royal Air Force of Oman, the Royal Navy of Oman in addition
                to the Royal Guard of Oman ) made many achievements in various
                aspects of armament, training, organizational, modern technology
                and manpower development, which made it a newly organized
                military force that includes among its ranks the elements of
                modern combined arms operations.
              </p>
              <p className="leading-Automatic mb-7">
                The Sultan’s Armed Forces were reorganized in 1987, and the
                position of Chief of Defence Staff was changed to the position
                of Chief of Staff of the Sultan’s Armed Forces. The same year
                witnessed the creation of the rank of Minister in the
                organizational structure of the Ministry of Defence under the
                name of the Minister of State for Defence Affairs, His Majesty
                Sultan Qaboos bin Said- May God rest his soul - remained the
                Minister of Defence. This was followed by another change two
                years later, where the position of Minister of State for Defence
                Affairs was replaced in January 1989 with the position of
                Secretary General at the Ministry of Defence with the rank of
                Minister.
              </p>
              <p className="leading-Automatic mb-7">
                In October 1996, the position of Deputy Prime Minister for
                Security and Defence Affairs was abolished and a new position
                was created in November of the same year, the position of
                Minister responsible for defence affairs. The position of
                Secretary-General at the Ministry of Defence was abolished and
                replaced by the position of Undersecretary of the Ministry of
                Defence, and on January 16, 2014, the position of
                Secretary-General in the Ministry of Defence was restored again.
                However, on March 9, 2020, the position of the Minister
                responsible for Defence Affairs was abolished to be replaced by
                the position of Deputy Prime Minister for Defence Affairs, which
                is currently occupied by His Highness Sayyid Shihab bin Tariq
                bin Taimur Al Said.
              </p>
              <p className="leading-Automatic mb-7">
                Today, the Sultan's Armed Forces, based on their motto, which
                they will never deviate from (belief in God, loyalty to the
                Sultan, defence of the homeland), is one of the main pillars in
                the majestic building of the modern Omani Renaissance, thanks to
                the high and generous patronage of His Majesty the Sultan, the
                Supreme Commander - may God protect and preserve him - Its
                members are dedicated to maintaining the security and stability
                of beloved Oman.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
