import React from "react";
import i18n from "../../../i18n";

const ICONS = {
  pdf: { icon: "fa fa-file-pdf-o", color: "#FF0000" },
  png: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpg: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpeg: { icon: "fa fa-picture-o", color: "#FF0000" },
  bmp: { icon: "fa fa-picture-o", color: "#FF0000" },
  gif: { icon: "fa fa-picture-o", color: "#FF0000" },
  doc: { icon: "fa fa-file", color: "#db9c5f" },
  docx: { icon: "fa fa-file", color: "#db9c5f" },
  xls: { icon: "fa fa-file", color: "#db9c5f" },
  xlsx: { icon: "fa fa-file", color: "#db9c5f" },
};

export class AuctionDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getFileIcon(url) {
    if (url) {
      let extension = url.split(/[#?]/)[0].split(".").pop().trim();
      return (
        ICONS[extension.toLowerCase()] || {
          icon: "fa fa-file",
          color: "#db9c5f",
        }
      );
    }
  }

  render() {
    let auction = this.props.auction;
    let lng = this.props.lng;
    let downloads = auction.downloads;
    let downloads_length =
      (auction && auction.downloads && auction.downloads.length) || 0;
    return (
      <React.Fragment>
        {downloads_length > 0 && (
          <div className="">
            <div className="card active card-details">
              <h4 className="m-auto p-3 pt-5">{i18n.t("documents")}</h4>
              <div className="m-auto pt-0 pb-0 pr-5 pl-5">
                {downloads.map((item, i) => {
                  let details = this.getFileIcon(item.file);
                  return (
                    <div>
                      {item.file && (
                        <a
                          href={item.file}
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-center"
                        >
                          <p className="p-0 m-0" style={{ fontSize: "5rem" }}>
                            <i
                              className={details.icon}
                              style={{ color: details.color }}
                            />
                          </p>
                          <p>
                            {lng === "ar" && item.label_ar
                              ? item.label_ar
                              : item.label}
                          </p>
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
