import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  checkUserValidity,
  sendNotification,
  bidNow,
  getTopBidders,
  extraTimeCheck,
} from "../../models/auctions";
import { getLoggedUser } from "../../state/user";
import { BiddersPosition } from "./biddersPosition";

const is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
var countdownComplete = false;
const FinishBid = () => <span>{i18n.t("bid_time_ends")}</span>;
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    countdownComplete = true;
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="sliderCountContainer">
        <span className="sliderCountIcon">
          <i className="fa fa-clock-o" />
        </span>
        <span className="sliderCount">
          <span className="sliderCountNumber">{days}</span>
          <span className="sliderCountLabel">{i18n.t("lbl_day")}</span>
        </span>
        <span className="sliderCount">
          <span className="sliderCountNumber">{hours}</span>
          <span className="sliderCountLabel">{i18n.t("lbl_hour")}</span>
        </span>
        <span className="sliderCount">
          <span className="sliderCountNumber">{minutes}</span>
          <span className="sliderCountLabel">{i18n.t("lbl_minute")}</span>
        </span>
        <span className="sliderCount">
          <span className="sliderCountNumber">{seconds}</span>
          <span className="sliderCountLabel">{i18n.t("lbl_second")}</span>
        </span>
      </div>
    );
  }
};

let lang = localStorage.getItem("I18N_LANGUAGE");

export class BiddingComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidTimeStopped: false,
      toggleArrow: false,
      bid_increment: 0,
      current_bid_amount: 0,
      user_bid_amount: 0,
      left_button_disable: false,
      is_bid_sending: false,
      bid_place_message: "",
      bid_place_error: false,
      is_loading: false,
      user_valid: false,
      is_blacklisted: false,
      date_out_of_range: false,
      need_enroll: true,
      bid_count: 0,
      winner: {},
      is_completed: false,
      auction_page: "",
      top_bidders: {},
      enroll_status: "",
      endDate: null,
      extra_time_message: false,
      increments: [1000, 2000, 5000, 10000, 15000, 20000],
      total_enrolls: 0,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };

    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.bidNow = this.bidNow.bind(this);
    //this.isComplete = this.isComplete.bind(this);
    this.auctionFinished = this.auctionFinished.bind(this);
    this.checkUserAuctionValidity = this.checkUserAuctionValidity.bind(this);
    this.loadTopBidders = this.loadTopBidders.bind(this);
    this.checkTime = this.checkTime.bind(this);
  }

  componentDidMount() {
    this.showStaffAuctionPopup();
    let auction = this.props.auction;
    let bid_increment = 0;
    if (auction.bid_increment) {
      bid_increment = auction.bid_increment;
      this.setState({ bid_increment: bid_increment });
    }

    let start_amount = parseFloat(auction.start_amount);
    let current_bid_amount = parseFloat(start_amount);
    if (auction.latest_bid && auction.latest_bid.bid_amount) {
      current_bid_amount = parseFloat(auction.latest_bid.bid_amount);
    }
    let bid_count = auction.bid_count;
    let winner = auction.winner;
    let is_completed = auction.is_completed;
    let auction_page = this.props.auction_page;
    start_amount = parseFloat(start_amount) + parseFloat(bid_increment);
    let increments = auction.increment_numbers;
    this.setState({
      user_bid_amount: start_amount,
      current_bid_amount,
      is_loading: true,
      bid_count,
      winner,
      is_completed,
      auction_page,
      endDate: auction.end_date,
      increments: increments,
    });

    this.checkUserAuctionValidity();
    if (auction && auction.id) {
      this.loadTopBidders();
    }

    let intervalId = setInterval(this.checkTime, 5000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  showStaffAuctionPopup() {
    let auction = this.props.auction;
    if (is_logged && !user.is_staff && auction.is_staff_auction) {
      swal(i18n.t("un_authorized"), i18n.t("non_staff_popup"), "warning");
    }
  }

  async checkUserAuctionValidity() {
    let auction = this.props.auction;
    if (auction && auction.id) {
      this.setState({ is_loading: true });
      let response = await checkUserValidity({ auction: auction.id });
      let data = response.data;
      if (data) {
        //this.setState({ is_blacklisted: data.blackList });
        this.setState({ user_valid: data.valid });
        this.setState({ is_blacklisted: data.is_blacklisted });
        this.setState({ date_out_of_range: data.date_out_of_range });
        this.setState({ is_loading: false });
        this.setState({ total_enrolls: data.total_enrolls });
        this.setState({ need_enroll: data.is_need_enroll });

        if (data.enroll_status && data.enroll_status !== "C") {
          this.setState({ enroll_status: data.enroll_status });
        }
      }
    }
  }

  // isComplete() {
  //   this.setState({ bidTimeStopped: true });
  //   //this.auctionFinished();
  // }
  async auctionFinished() {
    let auction = this.props.auction;
    await sendNotification({ auction: auction.id });
  }

  async checkTime() {
    let auction = this.props.auction;
    let endDate = this.state.endDate;

    let time = new Date().toLocaleTimeString("en-US", {
      timeZone: "Asia/Muscat",
    });
    let dt = new Date().toISOString("en-US").split("T")[0];
    let newDt = new Date(dt + " " + time);

    var diff = Math.abs(new Date(endDate) - newDt);
    var minutes = Math.floor(diff / 1000 / 60);

    if (auction && auction.id && minutes < 2) {
      const response = await extraTimeCheck({ auction: auction.id });
      let data = response.data;

      var g1 = new Date(endDate);
      var g2 = new Date(data.end_date);
      console.log(data);
      if (
        data &&
        typeof data !== "undefined" &&
        typeof data.end_date !== "undefined" &&
        g1.getTime() !== g2.getTime() &&
        !this.state.bidTimeStopped
      ) {
        console.log(endDate, data.end_date);
        this.createDate(data.end_date);
        this.setState({
          endDate: data.end_date,
          extra_time_message: true,
        });

        setTimeout(() => {
          this.setState({
            extra_time_message: false,
          });
        }, 3000);
      }
    }
  }

  handleChange(event) {
    let state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
    if (event.target.name === "bid_increment") {
      this.setCurrentBidAmount(event.target.value);
    }
  }

  setCurrentBidAmount(new_incr) {
    let user_bid_amount = this.state.user_bid_amount;
    let current_amount = parseFloat(this.state.current_bid_amount);
    user_bid_amount = parseFloat(current_amount) + parseFloat(new_incr);
    this.setState({ user_bid_amount });
  }

  decrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) - parseFloat(bid_increment);
    let left_button_disable = false;
    let minAmount = parseFloat(current_amount) + parseFloat(bid_increment);
    if (user_bid_amount <= minAmount) {
      left_button_disable = true;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  incrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) + parseFloat(bid_increment);
    let left_button_disable = true;
    if (user_bid_amount > current_amount) {
      left_button_disable = false;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  async bidNow() {
    this.setState({ is_bid_sending: true });
    let auction = this.props.auction;
    let user_bid_amount = this.state.user_bid_amount;
    const response = await bidNow({ user_bid_amount, auction: auction.id });
    let data = response.data;
    let current_amount = data.current_amount;
    let message =
      lang === "ar" && response.meta && response.meta.trim() !== ""
        ? response.meta
        : response.message;
    this.setState({
      current_bid_amount: current_amount,
      bid_place_message: message,
      bid_place_error: data.bid_place_error,
    });

    if (data) {
      this.setState({ is_bid_sending: false });
      this.checkTime();
    }
    setTimeout(() => {
      this.setState({
        bid_place_message: "",
        bid_place_error: false,
      });
    }, 5000);

    this.loadTopBidders();
  }

  async loadTopBidders() {
    let auction = this.props.auction;
    if (auction) {
      this.setState({ loading: true });
      this.setState({ refresh_loading: true });
      const response = await getTopBidders({ auction: auction.id });
      let data = response.data;
      this.setState({ top_bidders: data });
      if (response) {
        this.setState({ refresh_loading: false });
      }
      if (response && response.meta && response.meta.bid_amount) {
        let current_bid_amount = parseFloat(response.meta.bid_amount);
        let bid_count = response.meta.bid_count;
        let user_bid_amount =
          parseFloat(current_bid_amount) + parseFloat(this.state.bid_increment);
        this.setState({ current_bid_amount, user_bid_amount, bid_count });
        this.props.updateRow(bid_count);
      }
      if (response && response.meta && response.meta.myPosition) {
        let myBidData = response.meta.myPosition;
        this.setState({
          myPosition: myBidData.position,
          myPrice: myBidData.bid_amount,
        });
      }

      if (response && response.meta && response.meta.total_enrolls) {
        this.setState({ total_enrolls: response.meta.total_enrolls });
      }

      if (response && response.meta && response.meta.extra_time) {
        let now = () => {
          let time = new Date().toLocaleTimeString("en-US", {
            timeZone: "Asia/Muscat",
          });
          let dt = new Date().toISOString("en-US").split("T")[0];
          let newDt = dt + " " + time;
          newDt = new Date(newDt.replace(/-/g, "/"));
          return newDt;
        };
        console.log("setting now");
        this.setState({ now });
      }
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.props.auction;
    let reg_status = auction.registartion_status;
    let now = this.state.now;
    let endTime = new Date(this.createDate(this.state.endDate)).getTime();
    let toggleClasses = "";
    if (this.state.toggleArrow) {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap justify-content-end";
    } else {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap";
    }
    let increments = this.state.increments;
    let total_enrolls = this.state.total_enrolls;
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h3
              className="card-title"
              style={{
                fontSize: "0.8rem",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {i18n.t("start_bid_amount")} :{" "}
              {lang === "ar" ? "" : i18n.t("omr")} {auction.start_amount}
              {lang === "ar" ? i18n.t("omr") : ""}
              <span
                style={{ fontSize: "0.7rem", color: "#FF0000", marginTop: "0" }}
              >
                {i18n.t("enrolled_bidders")} : {total_enrolls}
              </span>
            </h3>
          </div>
          <div className="card-body">
            {this.state.is_blacklisted && (
              <div className="alert alert-danger">
                {i18n.t("blacklist_message")}
              </div>
            )}

            {this.state.extra_time_message && (
              <div className="alert alert-info">
                You have got extra minutes for bidding!!!
              </div>
            )}
            <a
              href={true}
              className="btn bg-warning-transparent text-primary mb-4 btn-lg btn-block"
              style={{ textAlign: "center" }}
            >
              {this.state.endDate && (
                <Countdown
                  date={endTime}
                  renderer={renderer}
                  onComplete={() => {
                    this.setState({ bidTimeStopped: countdownComplete });
                  }}
                  now={now}
                />
              )}
            </a>
            {!is_logged && (
              <div className="text text-danger text-center">
                <Link to="/login" className="text-danger">
                  {i18n.t("login_to_make_bids")}
                </Link>
              </div>
            )}
            {is_logged && auction.status !== "A" && (
              <div className="text text-danger text-center">
                {i18n.t("not_active")}
              </div>
            )}
            {is_logged && auction.status === "A" && (
              <div>
                <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <a
                        href={true}
                        className="btn-left btn-link p-2 toggle text-dark"
                        onClick={(event) => {
                          this.setState({ toggleArrow: false });
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </a>
                    </div>

                    <div className="flex-grow-1 w-100 o-hidden bid-amounts">
                      <ul className={toggleClasses}>
                        {increments.map((incr, i) => {
                          return (
                            <li className="nav-item" key={i}>
                              <button
                                className={
                                  Number(this.state.bid_increment) ===
                                  Number(incr)
                                    ? "btn btn-primary"
                                    : "btn btn-light"
                                }
                                onClick={() => {
                                  this.setState({
                                    bid_increment: incr,
                                  });
                                  this.setCurrentBidAmount(incr);
                                }}
                              >
                                {incr}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <hr />
                    <div className="flex-shrink-0">
                      <a
                        href={true}
                        className="btn-right btn-link toggle p-2 text-dark"
                        onClick={(event) => {
                          this.setState({ toggleArrow: true });
                        }}
                      >
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="container mb-2">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("latest_bid_amount")}
                    </label>
                    <div className="input-group">
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default btn-number"
                          disabled={this.state.left_button_disable}
                          onClick={this.decrementNumber}
                        >
                          <span className="glyphicon glyphicon-minus"></span>
                        </button>
                      </span>
                      <input
                        type="number"
                        name="user_bid_amount"
                        className="form-control input-number"
                        placeholder="Bid Amount"
                        value={this.state.user_bid_amount}
                        onChange={this.handleChange}
                        readOnly={true}
                        style={{ textAlign: "center" }}
                      />
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default btn-number"
                          onClick={this.incrementNumber}
                        >
                          <span className="glyphicon glyphicon-plus"></span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="container mb-2">
                  <div>
                    {is_logged &&
                      this.state.need_enroll &&
                      reg_status === "open" && (
                        <div>
                          {(this.state.enroll_status === "N" ||
                            this.state.enroll_status === "R" ||
                            this.state.enroll_status === "") && (
                            <div>
                              <div className="text text-center text-danger">
                                {i18n.t("msg_enroll_waiting")}
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    is_logged &&
                                    !user.is_staff &&
                                    auction.is_staff_auction
                                  ) {
                                    swal(
                                      i18n.t("un_authorized"),
                                      i18n.t("non_staff_popup"),
                                      "warning"
                                    );
                                    return;
                                  }
                                  window.location.href =
                                    "/enroll/" + auction.id;
                                }}
                                className="btn btn-warning btn-block mb-4"
                              >
                                {i18n.t("enroll")}
                              </button>
                            </div>
                          )}

                          {this.state.enroll_status === "N" && (
                            <div>
                              <div className="text text-center text-danger">
                                Your enrollment verfication process is pending.
                                Please contact administrator.
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    {this.state.enroll_status !== "A" &&
                      reg_status === "closed" && (
                        <div className="text text-center text-danger">
                          {i18n.t("reg_closed")}
                        </div>
                      )}

                    {is_logged && !this.state.need_enroll && (
                      <button
                        type="button"
                        className="btn btn-primary btn-block mb-4 text-white"
                        onClick={this.bidNow}
                        disabled={
                          this.state.is_bid_sending ||
                          countdownComplete ||
                          (!user.is_staff && auction.is_staff_auction)
                        }
                      >
                        {this.state.is_bid_sending && (
                          <span>
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {i18n.t("sending")}...
                          </span>
                        )}
                        {!this.state.is_bid_sending && (
                          <span>
                            <i className="fa fa-gavel"></i>&nbsp;
                            {i18n.t("bid_now")}
                          </span>
                        )}
                      </button>
                    )}
                    {this.state.bid_place_message && (
                      <div
                        className={
                          this.state.bid_place_error
                            ? "alert alert-danger"
                            : "alert alert-success"
                        }
                      >
                        {this.state.bid_place_message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {is_logged && (
          <BiddersPosition
            top_bidders={this.state.top_bidders}
            loadTopBidders={this.loadTopBidders}
            refresh_loading={this.state.refresh_loading}
            user={user}
            total_enrolls={total_enrolls}
          />
        )}
      </React.Fragment>
    );
  }
}
