import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import getUser from "../../models/getUser";
import updateUser from "../../models/updateUser";
import swal from "sweetalert";
import i18n from "../../i18n";
import { sendOTP, verifyOTP } from "../../models/users";
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
export class EditProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      input: {},
      errors: {},
      is_mail_loading: false,
      is_phone_loading: false,
      otp_msg: "",
      open_popup: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendVerification = this.sendVerification.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const response = await getUser();
    let user = response.data;
    user.otp = "";
    if (user.mobile.length < 3) {
      user.mobile = "";
    }
    this.setState({ input: user });
    this.setState({ user });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateUser(this.state.input);
      if ("id" in response.data) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            this.setState({ user: response.data });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "mzad_user_info",
              JSON.stringify(response.data)
            );
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  async sendVerification(type) {
    if (type === "email") {
      this.setState({ is_mail_loading: true });
    }
    if (type === "sms") {
      this.setState({ is_phone_loading: true });
    }
    let response = await sendOTP(type);
    let msg = response.message;
    this.setState({ otp_msg: msg });
    if (response.success) {
      let { input } = this.state;
      input.verify_type = type;
      this.setState({
        is_mail_loading: false,
        is_phone_loading: false,
        open_popup: true,
        input,
      });
    } else {
      swal("Failed", msg, "error");
      this.setState({ is_mail_loading: false, is_phone_loading: false });
    }
  }

  async verifyOTP() {
    let { input } = this.state;
    const response = await verifyOTP({
      otp: input.otp,
      type: input.verify_type,
    });
    if (response.success) {
      let user = response.data;
      this.setState({ user });
      this.setState({ open_popup: false });
      swal("Success", response.message, "success");
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("profile")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("profile")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                  <div className="">
                    {/* {"card mb-0"} */}
                    {/* <div className="card-header">
                      <h3 className="card-title">
                        {i18n.t("verify_mob_email")}
                      </h3>
                    </div> */}
                    <div className="">
                      {/* card-body */}
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          {!this.state.user.email_verified_at && (
                            <div className="form-group">
                              <div className="row">
                                <div className="col-sm-9 col-md-9">
                                  <div className="alert alert-danger">
                                    Your email is not verified yet. Please
                                    verify now.
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                  <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => {
                                      this.sendVerification("email");
                                    }}
                                  >
                                    {this.state.is_mail_loading && (
                                      <Spinner animation="border" size="sm" />
                                    )}
                                    {!this.state.is_mail_loading && (
                                      <span>{i18n.t("send_otp")}</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {!this.state.user.mobile_verified_at && (
                            <div className="form-group">
                              <div className="row">
                                <div className="col-sm-9 col-md-9">
                                  <div className="alert alert-danger">
                                    Your mobile is not verified yet. Please
                                    verify now.
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                  <button
                                    type="button"
                                    className="btn btn-success btn-block"
                                    onClick={() => {
                                      this.sendVerification("sms");
                                    }}
                                  >
                                    {this.state.is_phone_loading && (
                                      <Spinner animation="border" size="sm" />
                                    )}
                                    {!this.state.is_phone_loading && (
                                      <span>{i18n.t("send_otp")}</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="form-group">
                            <div className="row">
                              {this.state.user.email_verified_at && (
                                <div className="col-sm-6 col-md-6">
                                  <div className="alert alert-success">
                                    <b>{this.state.user.email} : </b>E-mail
                                    address is verified.
                                  </div>
                                </div>
                              )}
                              {this.state.user.mobile_verified_at && (
                                <div className="col-sm-6 col-md-6">
                                  <div className="alert alert-success">
                                    <b>{this.state.user.mobile} : </b> Mobile
                                    number is verified.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("edit_profile")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("name")}</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder={i18n.t("name")}
                            value={this.state.input.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("username")}
                          </label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            placeholder={i18n.t("username")}
                            value={this.state.input.username}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("email")} &nbsp;
                            {this.state.user.email_verified_at && (
                              <small className="text-success">
                                <i className="fa fa-check-circle" />
                                &nbsp;Verified
                              </small>
                            )}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={i18n.t("email")}
                            name="email"
                            value={this.state.input.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}&nbsp;
                            {this.state.user.mobile_verified_at && (
                              <small className="text-success">
                                <i className="fa fa-check-circle" />
                                &nbsp;Verified
                              </small>
                            )}
                          </label>
                          <input
                            type="number"
                            name="mobile"
                            className="form-control"
                            placeholder={i18n.t("phone_number")}
                            value={this.state.input.mobile}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("civil_id_number")}
                          </label>
                          <input
                            type="text"
                            name="civil_id_number"
                            className="form-control"
                            placeholder={i18n.t("civil_id_number")}
                            value={this.state.input.civil_id_number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("password")}
                          </label>
                          <input
                            type="text"
                            name="password"
                            className="form-control"
                            placeholder={i18n.t("password")}
                            value={this.state.input.password}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-success">
                      {i18n.t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>OTP verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.otp_msg && (
              <div className="row p-5">
                <div className="col-sm-12 col-md-12">
                  <div>{this.state.otp_msg}</div>
                </div>
              </div>
            )}

            <div className="row px-5">
              <div className="col-sm-9 col-md-9">
                <div className="form-group">
                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder={i18n.t("Enter OTP and verify")}
                    value={this.state.input.otp}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="form-group">
                  <button className="btn btn-primary" onClick={this.verifyOTP}>
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
