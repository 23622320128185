import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { sendBulkMail } from "../../models/users";
import { getAllGroups } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
import { Spinner } from "react-bootstrap";

export class BulkMail extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        notify_type: "Email",
        notify_category: "Y",
        group: false,
      },
      groups: {},
      is_loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    console.log(input);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let { input } = this.state;
    if (!input.title || !input.body || !input.title_ar || !input.body_ar) {
      swal("Failed", "Please enter mandatory fields!!", "error");
      return;
    }
    this.setState({ is_loading: true });

    try {
      let payload = this.state.input;
      const response = await sendBulkMail(payload);
      if (response) {
        this.setState({ is_loading: false });
      }

      if (response && response.data && response.data.success) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            //this.setState({ input: {} });
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let groups = this.state.groups;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("mails")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("composer")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("notify_type")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Type"
                            name="notify_type"
                            value={this.state.input.notify_type}
                            onChange={this.handleChange}
                          >
                            <option value="">
                              --{i18n.t("notify_type")}--
                            </option>
                            <option value="Email">Email</option>
                            <option value="SMS">SMS</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("categories")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select category"
                            name="notify_category"
                            value={this.state.input.notify_category}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("category")}--</option>
                            <option value="Y">All users</option>
                            <option value="G">Group wise</option>
                            <option value="N">
                              Custom {this.state.input.notify_type}
                            </option>
                          </select>
                        </div>
                      </div>

                      {this.state.input.notify_category === "G" && (
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("group")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="group"
                              value={this.state.group}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("group")}--</option>
                              {groups.length > 0 &&
                                groups.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.group_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      {this.state.input.notify_type === "Email" &&
                        this.state.input.notify_category === "N" && (
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("Emails")} ({"comma seperated"})
                              </label>
                              <textarea
                                className="form-control"
                                name="emails"
                                value={this.state.input.emails}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>
                        )}

                      {this.state.input.notify_type === "SMS" &&
                        this.state.input.notify_category === "N" && (
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("Phone Numbers")} ({"comma seperated"},{" "}
                                {"without `+` sign"})
                              </label>
                              <textarea
                                className="form-control"
                                name="phones"
                                value={this.state.input.phones}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>
                        )}

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title")}
                            <span className="text-danger">(*)</span>
                          </label>
                          <input
                            className="form-control"
                            name="title"
                            value={this.state.input.title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title_ar")}
                            <span className="text-danger">(*)</span>
                          </label>
                          <input
                            className="form-control"
                            style={{ direction: "rtl" }}
                            name="title_ar"
                            value={this.state.input.title_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("body")}
                            <span className="text-danger">(*)</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="body"
                            value={this.state.input.body}
                            onChange={this.handleChange}
                            rows={5}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("body_ar")}
                            <span className="text-danger">(*)</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="body_ar"
                            style={{ direction: "rtl" }}
                            value={this.state.input.body_ar}
                            onChange={this.handleChange}
                            rows={5}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("link")}</label>
                          <input
                            className="form-control"
                            name="link"
                            value={this.state.input.link}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    {this.state.is_loading && (
                      <Spinner animation="border" size="sm" />
                    )}
                    {!this.state.is_loading && (
                      <button type="submit" className="btn btn-primary">
                        Send Mail
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
