import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";
//import { getLoggedUser } from "../../../state/user";
import { toggleLike, toggleWishlist } from "../../../models/auctions";
import { AuctionShare } from "./auctionShare";
import swal from "sweetalert";
import { getLoggedUser } from "../../../state/user";
import labelarabic from "../../../images/labelarabic.png";
import LabelEng from "../../../images/LabelEng.png";

//const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
const FinishBid = () => (
  <div className="p-3 text-center text-success">
    {i18n.t("auction_started")}
  </div>
);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className=" text-success">
        <div className="timer-div-1">{i18n.t("starts_after")}</div>
        <div className="timer-div-2">
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_day")}</div>
            <div className="timer-count">{days} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_hour")}</div>
            <div className="timer-count">{hours} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_minute")}</div>
            <div className="timer-count">{minutes} : </div>
          </div>
          <div className="timer-div">
            <div className="timer-label">{i18n.t("lbl_second")}</div>
            <div className="timer-count">{seconds}</div>
          </div>
        </div>
      </div>
    );
  }
};

export class AuctionCard extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      wishlist: false,
      like: false,
      is_active_light_box: false,
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.addWishList = this.addWishList.bind(this);
    this.addLike = this.addLike.bind(this);
  }

  componentDidMount() {
    this.setState({ like: this.props.auction.auction_liked });
    this.setState({ wishlist: this.props.auction.auction_wishlisted });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }
  async addWishList(wishlist) {
    if (is_logged) {
      const response = await toggleWishlist({
        auction_id: this.props.auctionId,
        wishlist,
      });
      if (response) {
        this.setState({ wishlist: wishlist });
      }
    }
  }

  async addLike(like) {
    if (is_logged) {
      const response = await toggleLike({
        auction_id: this.props.auctionId,
        like,
      });
      if (response) {
        this.setState({ like: like });
      }
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let auction = this.props.auction;
    let images = this.props.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    let imgFilter = { filter: "brightness(0.8) invert(1)" };
    if (images.length > 0) {
      imgFilter = {};
      img_src = images[0].image;
    }
    let key = this.props.key;

    let amount_label = "";
    if (this.props.type === "upcoming") {
      amount_label = i18n.t("start_bid_amount");
    } else if (this.props.type === "previous") {
      amount_label = i18n.t("last_bid_amount");
    } else {
      amount_label = i18n.t("current_amount");
    }

    let startDateAr = auction.start_date_ar;
    let endDateAr = auction.end_date_ar;

    // let auctionImageStyle = {};
    // if (this.props.isDetailsPage) {
    //   auctionImageStyle = { height: "90%" };
    // }
    let statusAr = auction.status_label;
    let disp_status = "";
    let statusClass = "";
    let statusClasses = {
      E: "",
      F: "status-feat",
      A: "status-act",
      U: "status-upc",
    };
    if (statusAr && statusAr["status"]) {
      statusClass = statusClasses[statusAr["status"]];
      disp_status = current_lng === "ar" ? statusAr["ar"] : statusAr["en"];
    }

    let now = this.state.now;
    let endTime = new Date(this.createDate(auction.start_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));

    let containerClass = "card";
    if (this.props.total_items === 1 && this.props.screen !== "groupList") {
      containerClass = "card card-single";
    }
    return (
      <React.Fragment key={this.props.key}>
        {this.props.total_items > 0 && (
          <div className="m-2" key={this.props.key}>
            <div className={containerClass}>
              <div className="ribbon-custom">
                <span className={statusClass}>{disp_status}</span>
              </div>
              <div className="auction-card-img-cont">
                {" "}
                {/* item-card9-imgs */}
                <img
                  src={img_src}
                  alt="img"
                  className="auction-card-img cover-image"
                  style={imgFilter}
                  onClick={() => {
                    this.props.getImagePopup(true, images);
                  }}
                />
              </div>
              <div className="auction-card-middle-cont">
                <h4
                  className="font-weight-semibold"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textAlign: current_lng === "ar" ? "right" : "",
                  }}
                  data-tip={
                    current_lng === "ar"
                      ? this.props.title_ar
                      : this.props.title
                  }
                >
                  {current_lng === "ar"
                    ? this.props.title_ar
                    : this.props.title}
                  &nbsp;
                  {(auction.location || auction.location_ar) && (
                    <small className="text-muted">
                      {"("}
                      {current_lng === "ar"
                        ? auction.location_ar
                        : auction.location}
                      {")"}
                    </small>
                  )}
                  <ReactTooltip />
                </h4>
                <div
                  className="ms-auto text-primary d-flex"
                  style={{
                    direction: current_lng === "ar" ? "rtl" : "ltr",
                    fontSize: "1rem",
                    justifyContent: "space-around",
                  }}
                >
                  <a href={true} className="icon d-md-inline-block">
                    <span className="text-primary d-flex">
                      <span>
                        <i className="icon icon-eye"></i>
                      </span>
                      <span
                        className="text-danger"
                        style={{
                          marginLeft: current_lng === "ar" ? "" : "5px",
                          marginRight: current_lng === "ar" ? "5px" : "",
                        }}
                      >
                        {this.props.total_views}
                      </span>
                    </span>
                  </a>
                  <a
                    href={true}
                    className="icon ms-5"
                    onClick={(event) => {
                      this.addWishList(!this.state.wishlist);
                    }}
                  >
                    <i
                      className={
                        this.state.wishlist ? "fa fa-heart" : "fa fa-heart-o"
                      }
                    ></i>
                  </a>
                  <a
                    href={true}
                    className="icon ms-5"
                    onClick={(event) => {
                      this.addLike(!this.state.like);
                    }}
                  >
                    <i
                      className={
                        this.state.like
                          ? "fa fa-thumbs-up"
                          : "fa fa-thumbs-o-up"
                      }
                    ></i>
                  </a>

                  <a
                    href={true}
                    className="icon ms-5 bid-count-item"
                    style={{
                      float: current_lng === "ar" ? "left" : "right",
                    }}
                  >
                    {i18n.t("bid_counr")}
                    {"#:"}
                    {auction.bid_count}
                  </a>
                </div>
              </div>
              <div className="auction-card-bottom-cont">
                <div className="auction-card-main-cont">
                  <div className="one-by-eight">
                    <div className="auc-timer">
                      {auction.is_bid_time_ends && (
                        <div className="p-3 text-center text-danger">
                          {i18n.t("bid_time_ends")}
                        </div>
                      )}

                      {!auction.is_bid_time_ends && auction.end_date && (
                        <Countdown
                          date={endTime}
                          renderer={renderer}
                          onComplete={() => {
                            this.setState({ bidTimeStopped: true });
                          }}
                          now={now}
                        />
                      )}
                    </div>
                    <div
                      className="auc-box"
                      style={{
                        paddingLeft: current_lng === "ar" ? "0.5rem" : "1.2rem",
                        paddingRight:
                          current_lng === "ar" ? "1.2rem" : "0.5rem",
                      }}
                    >
                      <div className="row">
                        <div className="col-7 m-0 p-0">
                          {i18n.t("start_bid_amount")} :{" "}
                        </div>
                        <div className="col-5 m-0 p-0">
                          {auction.start_amount} {i18n.t("omr")}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-7 m-0 p-0">
                          {i18n.t("gurantee_amount")} :{" "}
                        </div>
                        <div className="col-5 m-0 p-0">
                          {auction.guarantee_amount} {i18n.t("omr")}
                        </div>
                      </div>
                      <div className="row text-danger">
                        <div className="col-7 m-0 p-0">
                          {i18n.t("current_amount")} :{" "}
                        </div>
                        <div className="col-5 m-0 p-0">
                          {auction.current_amount} {i18n.t("omr")}
                        </div>
                      </div>
                    </div>
                    <div className="auc-box">
                      <div style={{ clear: "both" }}>
                        <span>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/icons/time.png"
                            }
                            style={{
                              width: "25px",
                              height: "25px",
                              border: "1px solid #473828",
                              borderRadius: "20px",
                              margin: "0.7rem 0",
                              float: current_lng === "ar" ? "right" : "left",
                            }}
                            alt=""
                          />
                        </span>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            margin: "0.7rem 0.3rem",
                            float: current_lng === "ar" ? "right" : "left",
                          }}
                        >
                          &nbsp;{i18n.t("time")}
                        </span>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            margin: "0.7rem 0.3rem",
                            float: current_lng === "ar" ? "left" : "right",
                          }}
                        >
                          {i18n.t("end_date")}
                        </span>
                      </div>
                      <div style={{ clear: "both" }}>
                        <span
                          style={{
                            float: current_lng === "ar" ? "right" : "left",
                          }}
                        >
                          ({end_time})
                        </span>
                        <span
                          style={{
                            float: current_lng === "ar" ? "left" : "right",
                          }}
                        >
                          {endDateAr && endDateAr["date"]
                            ? endDateAr["date"]
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="one-by-four">
                    <div
                      className="auc-box text-center"
                      style={{ minHeight: "8.5rem", marginBottom: "1px" }}
                    >
                      <h4 className="mb-5">
                        {startDateAr && startDateAr["day"]
                          ? i18n.t(startDateAr["day"])
                          : ""}
                      </h4>
                      <p className="m-0 lineHeight1">{i18n.t("start_date")}</p>
                      <p
                        className="m-0 lineHeight1"
                        style={{
                          fontSize: current_lng === "ar" ? "0.9rem" : "1rem",
                        }}
                      >
                        {startDateAr && startDateAr["date"]
                          ? startDateAr["date"]
                          : ""}
                      </p>
                      <p className="m-0 lineHeight1">({start_time})</p>
                    </div>
                    <div>
                      {/* <div className="one-by-two">
                        <div className="auc-box">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/icons/total_auction.png"
                            }
                            style={{
                              width: "25px",
                              height: "25px",
                              border: "1px solid #473828",
                              borderRadius: "5px",
                            }}
                            alt=""
                          />
                          <h3 className="text-center">22</h3>
                          <p
                            className="text-center mb-0"
                            style={{ fontSize: "0.5rem" }}
                          >
                            {i18n.t("total_auctions")}
                          </p>
                        </div>
                      </div> */}
                      <div className="">
                        <div className="auc-box">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/icons/days_of-auction.png"
                            }
                            style={{
                              width: "25px",
                              height: "25px",
                              border: "1px solid #473828",
                              borderRadius: "5px",
                            }}
                            alt=""
                          />
                          <h3 className="text-center mb-0">
                            {auction.days_remaining}
                          </h3>
                          <p
                            className="text-center mb-0"
                            style={{ fontSize: "0.7rem" }}
                          >
                            {i18n.t("days_of_auction")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="approve_lbl">
                  <img
                    src={lng === "ar" ? labelarabic : LabelEng}
                    alt=""
                    className="m-auto"
                  />
                </div>

                <div className="auction-card-button-cont pt-2 pb-2">
                  <div className="d-flex justify-content-center pt-2 mt-auto auction-card-buttons">
                    <Link
                      to={"/auction-details/" + this.props.auctionId}
                      className="btn btn-success m-1"
                    >
                      <i className="fa fa-info-circle"></i>&nbsp;
                      {i18n.t("view_details")}
                    </Link>

                    {this.props.can_bid && (
                      <button
                        className="btn btn-success m-1"
                        onClick={() => {
                          if (
                            is_logged &&
                            !user.is_staff &&
                            auction.is_staff_auction
                          ) {
                            swal(
                              i18n.t("un_authorized"),
                              i18n.t("non_staff_popup"),
                              "warning"
                            );
                            return;
                          }
                          this.setState({ open_popup: true });
                        }}
                      >
                        <i className="fa fa-gavel"></i>
                        &nbsp;
                        {i18n.t("bid_now")}
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  {/** className="card-footer" */}
                  <AuctionShare auction={auction} />
                </div>
              </div>
            </div>
          </div>
        )}

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={current_lng}
          key={key}
        />
      </React.Fragment>
    );
  }
}
