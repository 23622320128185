import React from "react";
import { baseUrl } from "../../../constants/global";
//import { Link } from "react-router-dom";
//import ReactTooltip from "react-tooltip";
import {
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

let path = window.location.pathname;

export class AuctionShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: path,
    };
  }

  render() {
    let auction = this.props.auction;
    let url = baseUrl + "auction-details/" + auction.id;
    return (
      <React.Fragment>
        <ul className="socials">
          <li>
            {/**style={{ margin: "auto" }} */}
            <WhatsappShareButton url={url} title={auction.title} separator=",">
              {/* title={auction.title} */}
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </li>
          <li>
            {/**style={{ margin: "auto" }} */}
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </li>
          <li>
            {/**style={{ margin: "auto" }} */}
            <TwitterShareButton url={url}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
