//Production
//const apiUrl = "";

//Test
let env = "production";
let baseUrl = "";
let apiUrl = "";
let thawaniUrl = "";
let thawaniConfig = {};
let paymentSuccessUrl = "";
let paymentCancelledUrl = "";
let thawaniPaymentUrl = "https://uatcheckout.thawani.om/pay/";
let scopeUrl = "";
if (env === "test") {
  baseUrl = "https://modtest.mzadcom.om/";
  scopeUrl = "https://modtest.mzadcom.om";
  apiUrl = "https://modtest.mzadcom.om/services/public/api/";
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };

  paymentSuccessUrl = "https://modtest.mzadcom.om/payment-success/";
  paymentCancelledUrl = "https://modtest.mzadcom.om/payment-cancelled";
}
//Dev
if (env === "dev") {
  baseUrl = "http://localhost:3000/";
  scopeUrl = "http://localhost:3000";
  apiUrl = "http://localhost/mod/mod-api/public/api/";
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };

  paymentSuccessUrl = "http://localhost:3000/payment-success/";
  paymentCancelledUrl = "http://localhost:3000/payment-cancelled";
}

if (env === "production") {
  baseUrl = "https://mod.mzadcom.om/";
  apiUrl = "https://mod.mzadcom.om/services/public/api/";
  thawaniUrl = "https://checkout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "SQeBH2iJiRsRS2r4yPClP1mHO1UkPH",
    publishable_key: "R0YzWOnB1HdUKZmiiQqnjuNJjscETk",
  };

  paymentSuccessUrl = "https://mod.mzadcom.om/payment-success/";
  paymentCancelledUrl = "https://mod.mzadcom.om/payment-cancelled";
  thawaniPaymentUrl = "https://checkout.thawani.om/pay/";
}

export {
  apiUrl,
  thawaniUrl,
  thawaniConfig,
  thawaniPaymentUrl,
  paymentSuccessUrl,
  paymentCancelledUrl,
  baseUrl,
  scopeUrl,
};
