import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import {
  checkUserValidity,
  bidNow,
  getTopBidders,
  maskClasses,
} from "../../models/auctions";
import Countdown from "react-countdown";

let bidTimeEnds = false;
const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const FinishBid = () => <span>{i18n.t("bid_time_ends")}</span>;
const FinishEnroll = () => <small className="text text-danger">Closed</small>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <span>
        <span className="text text-primary">{days}</span> Days :
        <span className="text text-primary">{hours}</span> Hours :
        <span className="text text-primary">{minutes}</span> Minutes :
        <span className="text text-primary">{seconds}</span> Seconds
      </span>
    );
  }
};

let rendererList = function ({ days, hours, minutes, seconds, completed }) {
  if (completed) {
    //bidTimeEnds = true;
    return <FinishBid />;
  } else {
    let blinkBg = "auc-num";
    let childClass = "text text-primary auc-num";
    if (days === 0 && hours === 0 && minutes < 3) {
      blinkBg = "blink-bg";
      childClass = "text text-white auc-num";
    }
    return (
      <span className={blinkBg}>
        <span className={childClass}>
          <strong>
            {days}:{hours}:{minutes}:{seconds}
          </strong>
        </span>
      </span>
    );
  }
};

let rendererListEnroll = function ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) {
  if (completed) {
    //bidTimeEnds = true;
    return <FinishEnroll />;
  } else {
    let blinkBg = "";
    let childClass = "text text-primary";
    if (days === 0 && hours === 0 && minutes < 3) {
      blinkBg = "blink-bg";
      childClass = "text text-white";
    }
    return (
      <span className={blinkBg}>
        <span className={childClass}>
          <strong>
            {days}:{hours}:{minutes}:{seconds}
          </strong>
        </span>
      </span>
    );
  }
};

export class AuctionRow extends React.Component {
  constructor() {
    super();
    this.state = {
      lgShow: false,
      showDetails: false,
      user_valid: false,
      is_loading: false,
      current_bid_amount: 0,
      user_bid_amount: 0,
      bid_increment: 50,
      left_button_disable: true,
      top_bidders: {},
      bid_place_message: "",
      toggleArrow: false,
      bid_place_error: false,
      date_out_of_range: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.bidNow = this.bidNow.bind(this);
  }

  componentDidMount() {
    let start_amount = parseFloat(this.props.item.start_amount);
    let current_bid_amount = parseFloat(start_amount);
    start_amount =
      parseFloat(start_amount) + parseFloat(this.state.bid_increment);
    this.setState({
      user_bid_amount: start_amount,
      current_bid_amount: current_bid_amount,
      is_loading: true,
    });
  }

  async loadTopBidders() {
    this.setState({ loading: true });
    const response = await getTopBidders({ auction: this.props.item.id });
    let data = response.data;
    this.setState({ top_bidders: data });
    if (response && response.meta && response.meta.bid_amount) {
      let current_bid_amount = parseFloat(response.meta.bid_amount);
      let user_bid_amount =
        parseFloat(current_bid_amount) + parseFloat(this.state.bid_increment);
      this.setState({ current_bid_amount, user_bid_amount });
    }
  }

  handleChange(event) {
    if (event.target.name === "bid_increment") {
      this.setCurrentBidAmount(this.state.bid_increment, event.target.value);
    }
    let state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
  }

  async checkUserAuctionValidity(auction) {
    this.setState({ is_loading: true });
    const response = await checkUserValidity({ auction: auction });
    let data = response.data;
    if (data) {
      this.setState({ user_valid: data.valid });
      this.setState({ date_out_of_range: data.date_out_of_range });
      this.setState({ is_loading: false });
    }
  }

  setLgShow(opt, auction) {
    if (opt) {
      this.checkUserAuctionValidity(auction);
      this.loadTopBidders();
    }
    this.setState({ lgShow: opt });
  }
  toggleShowDetails() {
    let showDetails = this.state.showDetails;
    this.setState({ showDetails: !showDetails });
  }

  setCurrentBidAmount(old_incr, new_incr) {
    let user_bid_amount = this.state.user_bid_amount;
    let current_amount = parseFloat(this.state.current_bid_amount);
    user_bid_amount = parseFloat(current_amount) + parseFloat(new_incr);
    this.setState({ user_bid_amount });
  }

  decrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) - parseFloat(bid_increment);
    let left_button_disable = false;
    let minAmount = parseFloat(current_amount) + parseFloat(bid_increment);
    if (user_bid_amount <= minAmount) {
      left_button_disable = true;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  incrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) + parseFloat(bid_increment);
    let left_button_disable = true;
    if (user_bid_amount > current_amount) {
      left_button_disable = false;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  async bidNow() {
    let auction = this.props.item;
    let user_bid_amount = this.state.user_bid_amount;
    const response = await bidNow({ user_bid_amount, auction: auction.id });
    let data = response.data;
    let current_amount = data.current_amount;
    this.setState({
      current_bid_amount: current_amount,
      bid_place_message: response.message,
      bid_place_error: data.bid_place_error,
    });

    setTimeout(() => {
      this.setState({
        bid_place_message: "",
        bid_place_error: false,
      });
    }, 5000);

    this.loadTopBidders();
  }
  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.props.item;
    let enquiries = auction.enquiry;
    let enquiry_length =
      (auction && auction.enquiry && auction.enquiry.length) || 0;
    let index = this.props.index;
    let valid_user = this.state.user_valid;
    //let endDt = new Date(auction.end_date);
    let endTime = new Date(this.createDate(auction.end_date)).getTime();
    let enrollCloseDate = new Date(
      this.createDate(auction.enroll_close_date)
    ).getTime();
    let toggleClasses = "";
    if (this.state.toggleArrow) {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap justify-content-end";
    } else {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap";
    }

    let counterHidden = bidTimeEnds ? { display: "none" } : {};
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let bid_place_error = this.state.bid_place_error;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={() => this.setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {auction.phone_number}{" "}
              <span className="badge badge-dark">
                {i18n.t(maskClasses[auction.class].toLowerCase())}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_loading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            )}

            {!this.state.is_loading &&
              is_logged &&
              !valid_user &&
              !this.state.date_out_of_range && (
                <div className="text-center">
                  <p className="text text-danger text-center">
                    You not allowed for bidding auctions now. Enroll and make
                    your payments by clicking the below button. This enroll will
                    makes you to allow for bidding in all the auctions in this
                    current group.
                  </p>
                  <Link
                    to={"/enroll/" + auction.id}
                    className="btn btn-warning"
                  >
                    {i18n.t("enroll")}
                  </Link>
                </div>
              )}

            {!this.state.is_loading &&
              is_logged &&
              !valid_user &&
              this.state.date_out_of_range && (
                <div className="text-center">
                  <p className="text text-danger text-center">
                    Enrollment closed for this auction
                  </p>
                </div>
              )}

            {!this.state.is_loading && is_logged && valid_user && (
              <div>
                <div className="row">
                  <div className="col-12">
                    <div className="item-card9-desc mb-2">
                      <a href={true} className="me-4">
                        <span className="">{i18n.t("start_date")} : </span>
                        <span className="text text-primary">
                          {auction.start_date_formatted}
                        </span>
                      </a>
                      <a href={true} className="me-4">
                        <span className="">{i18n.t("end_date")} : </span>
                        <span className="text text-primary">
                          {auction.end_date_formatted}
                        </span>
                      </a>

                      <a href={true} className="me-4">
                        <span className="">{i18n.t("incr_amount")} : </span>
                        <span className="text text-primary">
                          {i18n.t("omr")} {auction.bid_increment}
                        </span>
                      </a>
                      <a href={true} className="me-4">
                        <span className="">{i18n.t("bid_count")} : </span>
                        <span className="text text-primary">5</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <a
                          href={true}
                          className="btn bg-danger-transparent text-danger mb-4 btn-lg btn-block"
                          style={{ textAlign: "center" }}
                        >
                          <Countdown date={endTime} renderer={renderer} />
                        </a>

                        <button className="btn bg-light-transparent text-primary mb-4 btn-lg btn-block">
                          {i18n.t("current_amount")} :{" "}
                          <b>
                            {i18n.t("omr")} {this.state.current_bid_amount}
                          </b>
                        </button>

                        <div className="container">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <a
                                href={true}
                                className="btn-left btn-link p-2 toggle text-dark"
                                onClick={(event) => {
                                  this.setState({ toggleArrow: false });
                                }}
                              >
                                <i className="fa fa-arrow-left"></i>
                              </a>
                            </div>
                            <div className="flex-grow-1 w-100 o-hidden bid-amounts">
                              <ul className={toggleClasses}>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 50
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 50 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        50
                                      );
                                    }}
                                  >
                                    50
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 100
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 100 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        100
                                      );
                                    }}
                                  >
                                    100
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 150
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 150 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        150
                                      );
                                    }}
                                  >
                                    150
                                  </button>
                                </li>
                                <li className="nav-item">
                                  {" "}
                                  <button
                                    className={
                                      this.state.bid_increment === 200
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 200 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        200
                                      );
                                    }}
                                  >
                                    200
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 250
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 250 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        250
                                      );
                                    }}
                                  >
                                    250
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 300
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 300 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        300
                                      );
                                    }}
                                  >
                                    300
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 350
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 350 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        350
                                      );
                                    }}
                                  >
                                    350
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 400
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 400 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        400
                                      );
                                    }}
                                  >
                                    400
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 450
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 450 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        450
                                      );
                                    }}
                                  >
                                    450
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    className={
                                      this.state.bid_increment === 500
                                        ? "btn btn-primary"
                                        : "btn btn-light"
                                    }
                                    onClick={() => {
                                      this.setState({ bid_increment: 500 });
                                      this.setCurrentBidAmount(
                                        this.state.bid_increment,
                                        500
                                      );
                                    }}
                                  >
                                    500
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div className="flex-shrink-0">
                              <a
                                href={true}
                                className="btn-right btn-link toggle p-2 text-dark"
                                onClick={(event) => {
                                  this.setState({ toggleArrow: true });
                                }}
                              >
                                <i className="fa fa-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="container mb-2">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("latest_bid_amount")}
                            </label>
                            <div className="input-group">
                              <span className="input-group-btn">
                                <button
                                  type="button"
                                  className="btn btn-default btn-number"
                                  disabled={this.state.left_button_disable}
                                  onClick={this.decrementNumber}
                                >
                                  <span className="glyphicon glyphicon-minus"></span>
                                </button>
                              </span>
                              <input
                                type="number"
                                name="user_bid_amount"
                                className="form-control input-number"
                                placeholder="Bid Amount"
                                value={this.state.user_bid_amount}
                                onChange={this.handleChange}
                                readOnly={true}
                              />
                              <span className="input-group-btn">
                                <button
                                  type="button"
                                  className="btn btn-default btn-number"
                                  onClick={this.incrementNumber}
                                >
                                  <span className="glyphicon glyphicon-plus"></span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="container mb-2">
                          {is_logged && valid_user && renderer && (
                            <div>
                              <button
                                type="button"
                                className="btn btn-success btn-block mb-4 text-white"
                                onClick={this.bidNow}
                              >
                                {i18n.t("bid_now")}
                              </button>
                              {this.state.bid_place_message && (
                                <div
                                  className={
                                    bid_place_error
                                      ? "alert alert-danger"
                                      : "alert alert-success"
                                  }
                                >
                                  {this.state.bid_place_message}
                                </div>
                              )}
                            </div>
                          )}

                          {(!is_logged || !valid_user) && (
                            <Link
                              to={"/enroll/" + auction.id}
                              className="btn btn-light btn-block mb-4"
                            >
                              {i18n.t("enroll")}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          {i18n.t("top_3_bidders")}
                        </h3>
                      </div>
                      <div className="card-body">
                        {this.state.top_bidders &&
                          this.state.top_bidders.length > 0 && (
                            <table className="table table-stripped">
                              <thead>
                                <tr>
                                  <th>{i18n.t("bidder_id")}</th>
                                  <th>{i18n.t("rank")}</th>
                                  <th>{i18n.t("amount")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.top_bidders.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>
                                        <span className="badge badge-pill badge-info me-2">
                                          {i + 1}
                                        </span>
                                      </td>
                                      <td>{item.bid_amount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}

                        {this.state.top_bidders &&
                          this.state.top_bidders.length < 1 && (
                            <div>{i18n.t("no_bidders_found")}</div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>
                      <a
                        className="btn btn-info btn-sm"
                        data-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        onClick={() => this.toggleShowDetails()}
                      >
                        {this.state.showDetails && (
                          <span>
                            <i className="fa fa-minus"></i>&nbsp;
                            {i18n.t("hide_details")}
                          </span>
                        )}
                        {!this.state.showDetails && (
                          <span>
                            <i className="fa fa-plus"></i>&nbsp;
                            {i18n.t("show_details")}
                          </span>
                        )}
                      </a>
                    </p>
                    <div
                      className={
                        this.state.showDetails
                          ? "card collapse show"
                          : "card collapse hide"
                      }
                    >
                      <div className="">
                        <div className="border-0 mb-5">
                          <div className="wideget-user-tab wideget-user-tab3">
                            <div className="tab-menu-heading">
                              <div className="tabs-menu1">
                                <ul className="nav">
                                  <li className="">
                                    <a
                                      href="#tab-1"
                                      className="active"
                                      data-bs-toggle="tab"
                                    >
                                      {i18n.t("description")}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#tab-2"
                                      data-bs-toggle="tab"
                                      className=""
                                    >
                                      {i18n.t("enquiry")}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#tab-3"
                                      data-bs-toggle="tab"
                                      className=""
                                    >
                                      {i18n.t("terms_conditions")}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content border-start border-end border-top br-ts-3 p-5 bg-white">
                            <div className="tab-pane active" id="tab-1">
                              <h3 className="card-title mb-3 font-weight-semibold">
                                {i18n.t("details")}
                              </h3>
                              <div className="row">
                                <div className="col-xl-12 col-md-12">
                                  {auction.description}
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="tab-2">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                    {enquiry_length > 0 && (
                                      <table className="table table-bordered border-top mb-0">
                                        <tbody>
                                          {enquiries.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{item.label}</td>
                                                <td>{item.value}</td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="tab-3">
                              <h3 className="card-title mb-3 font-weight-semibold">
                                {i18n.t("terms_conditions")}
                              </h3>
                              <div className="row">
                                <div className="col-xl-12 col-md-12">
                                  {current_lng === "ar"
                                    ? auction.terms_arabic
                                    : auction.terms}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <tr key={index} style={counterHidden}>
          <td className="number auc-num">
            <strong>{auction.phone_number}</strong>
            {user && user.role === "U" && is_logged && (
              <div>
                <Countdown
                  date={enrollCloseDate}
                  renderer={rendererListEnroll}
                />
              </div>
            )}
          </td>
          <td>
            <small
              style={{ fontSize: "100%" }}
              className={
                auction.class === "G" ? "badge badge-dark" : "badge badge-dark"
              }
            >
              {maskClasses[auction.class]}
            </small>
          </td>
          <td>
            {i18n.t("omr")} {auction.start_amount}
          </td>
          <td>
            <Countdown date={endTime} renderer={rendererList} />
          </td>
          <td>{auction.bid_count}</td>
          <td className="text text-center">
            {user && user.role === "U" && is_logged && (
              <button
                className="btn btn-primary btn-sm btn-block font-1vw"
                onClick={() => this.setLgShow(true, auction.id)}
              >
                {i18n.t("bid_now")}
              </button>
            )}
            {user && user.role === "U" && !is_logged && (
              <Link
                to="/login"
                className="btn btn-info btn-sm btn-block font-1vw"
              >
                Login to Bid
              </Link>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
