import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "../sidebar";
import {
  getAllAuctionsByGroup,
  getAllBiddersByGroup,
} from "../../../models/auctions";
import { getAllGroups } from "../../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../../constants/global";
import { getLoggedUser } from "../../../state/user";
const user = getLoggedUser();

export class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      groups: {},
      titles: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e60000",
            color: "#FFFFFF",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: i18n.t("bidder_id"),
          selector: "id",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("name"),
          selector: "name",
          wrap: true,
          cell: (row) => <span>{row.user.name}</span>,
        },
        {
          name: i18n.t("username"),
          selector: "username",
          wrap: true,
          cell: (row) => <span>{row.user.username}</span>,
        },
        {
          name: i18n.t("latest_bid_amount"),
          selector: "bid_amount",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("bid_date"),
          selector: "created_at",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("title"),
          selector: "title",
          wrap: true,
          cell: (row) => <span>{row.auction.title}</span>,
        },
        {
          name: i18n.t("group"),
          selector: "group",
          wrap: true,
          cell: (row) => <span>{row.group.group_name}</span>,
        },
        {
          name: i18n.t("start_date"),
          selector: "start_date",
          wrap: true,
          cell: (row) => <span>{row.auction.start_date}</span>,
        },
        {
          name: i18n.t("end_date"),
          selector: "end_date",
          wrap: true,
          cell: (row) => <span>{row.auction.end_date}</span>,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadAuctions = this.loadAuctions.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadAuctions(event.target.value);
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctions(group) {
    if (group) {
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ titles: response.data });
      }
    }
  }

  async searchSubmit() {
    this.setState({ bidder_loading: true });
    const response = await getAllBiddersByGroup(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  render() {
    let groups = this.state.groups;
    let titles = this.state.titles;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("reports")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("reports")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("search")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="auction"
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("auction")}--</option>
                              {titles.length > 0 &&
                                titles.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="fa fa-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={!this.state.input.group}
                            onClick={this.searchSubmit}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title" style={{ width: "65%" }}>
                      {i18n.t("result")}
                    </h3>
                    {bidders.length > 0 && (
                      <form
                        className="form-inline"
                        action=""
                        method="post"
                        target="_new"
                        style={{ width: "35%" }}
                        onSubmit={this.downloadDoc}
                      >
                        <div class="form-group mx-sm-3">
                          <select
                            className="form-control"
                            name="downloadType"
                            value={this.state.input.downloadType}
                            onChange={this.handleChange}
                          >
                            <option value={""}>Download As</option>
                            <option value={"pdf"}>PDF</option>
                            <option value={"excel"}>Excel</option>
                          </select>
                        </div>
                        {this.state.input.downloadType !== "" && (
                          <a
                            type="submit"
                            target="_new"
                            href={
                              apiUrl +
                              "bidder_report/export?q=" +
                              this.state.input.downloadType +
                              "&group=" +
                              this.state.input.group +
                              "&auction=" +
                              this.state.input.auction +
                              "&u=" +
                              user.role
                            }
                            className="btn btn-outline-danger"
                          >
                            Download
                          </a>
                        )}
                      </form>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <DataTable
                          title=""
                          columns={this.state.columns}
                          data={bidders}
                          customStyles={this.state.customStyles}
                          highlightOnHover
                          pagination
                          paginationPerPage={15}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Bidders per page:",
                            rangeSeparatorText: "out of",
                          }}
                          //actions={this.state.actionsMemo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
