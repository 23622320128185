import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  getAllMainGroups,
  getAllGroups,
  createGroup,
  deleteGroup,
} from "../../models/groups";
export class Groups extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        is_extra_time: false,
        is_staff_auction: false,
        extra_time: 0,
      },
      errors: {},
      main_groups: [],
      groups: [],
      collapse: true,
      enquiries: [{ label: "", value: "" }],
      file_group_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleGrouped = this.toggleGrouped.bind(this);
    this.toggleEnrollGrouped = this.toggleEnrollGrouped.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.loadAllMainGroups();
    this.loadAllGroups();
  }

  async loadAllMainGroups() {
    const response = await getAllMainGroups();
    let main_groups = response.data;
    this.setState({ main_groups });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({ input });
  };

  toggleStaffAuction = () => {
    let input = this.state.input;
    input.is_staff_auction = !this.state.input.is_staff_auction;
    this.setState({
      input,
    });
  };

  toggleGrouped = () => {
    let input = this.state.input;
    input.is_auctions_grouped = !this.state.input.is_auctions_grouped;
    this.setState({
      input,
    });
  };

  toggleEnrollGrouped = () => {
    let input = this.state.input;
    input.is_grouped_enroll = !this.state.input.is_grouped_enroll;
    this.setState({
      input,
    });
  };

  onFileChange = (event) => {
    if (event.target.name === "file_group_image") {
      this.setState({ file_group_image: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (!this.state.input.main_group) {
      swal("Failed", "Please select main group", "error");
    }
    let payload = this.state.input;
    payload.enquiries = this.state.enquiries;

    const formData = new FormData();

    formData.append("group_name", this.state.input.group_name);
    formData.append("group_name_ar", this.state.input.group_name_ar);
    formData.append("description", this.state.input.description);
    formData.append("description_ar", this.state.input.description_ar);
    formData.append("start_date", this.state.input.start_date);
    formData.append("end_date", this.state.input.end_date);
    formData.append("reg_start_date", this.state.input.reg_start_date);
    formData.append("reg_end_date", this.state.input.reg_end_date);
    formData.append(
      "is_auctions_grouped",
      this.state.input.is_auctions_grouped
    );
    formData.append("is_staff_auction", this.state.input.is_staff_auction);
    formData.append("is_grouped_enroll", this.state.input.is_grouped_enroll);
    formData.append("terms", this.state.input.terms);
    formData.append("terms_arabic", this.state.input.terms_arabic);
    formData.append("amount", this.state.input.amount);
    formData.append("vat", this.state.input.vat);
    formData.append("service_charge", this.state.input.service_charge);
    formData.append("payment_type", this.state.input.payment_type);
    formData.append("enquiries", JSON.stringify(this.state.enquiries));
    formData.append("file_group_image", this.state.file_group_image);
    formData.append("is_extra_time", this.state.input.is_extra_time);
    formData.append("extra_time", this.state.input.extra_time);
    formData.append("main_group", this.state.input.main_group);

    const response = await createGroup(formData);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      this.loadAllGroups();
    } else {
      let error_message = response.message;
      let resObject = response.data;
      for (const key in resObject) {
        if (Object.hasOwnProperty.call(resObject, key)) {
          let element = resObject[key];
          error_message += "\n" + element;
        }
      }
      swal("Failed", error_message, "error");
    }
  }

  deleteRow(id) {
    const response = deleteGroup(id);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllGroups();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries;
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let main_groups = this.state.main_groups;
    let groups = this.state.groups;
    let enquiries = this.state.enquiries;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Groups
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <p style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.setState({ collapse: !this.state.collapse });
                    }}
                  >
                    {this.state.collapse ? "Add New Group" : "Hide Panel"}
                  </button>
                </p>
                <div className={this.state.collapse ? "collapse" : ""}>
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">Create Group</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("main_group")}{" "}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="main_group"
                              value={this.state.input.main_group}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              {main_groups.length > 0 &&
                                main_groups.map((item, i) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">Group Name</label>
                            <input
                              type="text"
                              name="group_name"
                              className="form-control"
                              placeholder="Group Name"
                              value={this.state.input.group_name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Group Name(Arabic)
                            </label>
                            <input
                              type="text"
                              style={{ direction: "rtl" }}
                              name="group_name_ar"
                              className="form-control"
                              placeholder="Group Name"
                              value={this.state.input.group_name_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">Start Date</label>
                            <input
                              type="datetime-local"
                              name="start_date"
                              className="form-control"
                              placeholder="Start Date"
                              value={this.state.input.start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">End Date</label>
                            <input
                              type="datetime-local"
                              name="end_date"
                              className="form-control"
                              placeholder="End Date"
                              value={this.state.input.end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">Reg Start Date</label>
                            <input
                              type="datetime-local"
                              name="reg_start_date"
                              className="form-control"
                              placeholder="Reg Start Date"
                              value={this.state.input.reg_start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">Reg. End Date</label>
                            <input
                              type="datetime-local"
                              name="reg_end_date"
                              className="form-control"
                              placeholder="Reg. End Date"
                              value={this.state.input.reg_end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_auctions_grouped"
                              type="checkbox"
                              checked={this.state.input.is_auctions_grouped}
                              onChange={this.toggleGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Auctions Grouped.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_staff_auction"
                              type="checkbox"
                              checked={this.state.input.is_staff_auction}
                              onChange={this.toggleStaffAuction}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Staff Auction.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_grouped_enroll"
                              type="checkbox"
                              checked={this.state.input.is_grouped_enroll}
                              onChange={this.toggleEnrollGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Grouped Enroll.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              <input
                                name="is_extra_time"
                                type="checkbox"
                                checked={this.state.input.is_extra_time}
                                value={this.state.input.is_extra_time}
                                onChange={this.toggleChange}
                              />
                              &nbsp;
                              {i18n.t("extra_time")}
                            </label>

                            <input
                              name="extra_time"
                              type="number"
                              className="form-control text-center"
                              onChange={this.handleChange}
                              placeholder={i18n.t("in_minutes")}
                              value={this.state.input.extra_time}
                              disabled={!this.state.input.is_extra_time}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">VAT(%)</label>
                            <input
                              type="text"
                              name="vat"
                              className="form-control"
                              placeholder="VAT"
                              value={this.state.input.vat}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">Service Charge</label>
                            <input
                              type="text"
                              name="service_charge"
                              className="form-control"
                              placeholder="Service charge"
                              value={this.state.input.service_charge}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">Enroll Amount</label>
                            <input
                              type="text"
                              name="amount"
                              className="form-control"
                              placeholder="Enroll Amount"
                              value={this.state.input.amount}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("pay_type")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="payment_type"
                              value={this.state.input.payment_type}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Online">Online</option>
                              <option value="Offline">Offline</option>
                              <option value="Both">
                                Supports both Online & Offline
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Upload group image
                            </label>
                            <input
                              className="form-control "
                              type="file"
                              placeholder=""
                              name="file_group_image"
                              onChange={this.onFileChange}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              {/* (Image must be of size 168(w) X 84(h)) */}
                            </small>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")}
                            </label>
                            <textarea
                              className="form-control"
                              name="description"
                              value={this.state.input.description}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")} ({i18n.t("arabic")})
                            </label>
                            <textarea
                              className="form-control"
                              name="description_ar"
                              value={this.state.input.description_ar}
                              onChange={this.handleChange}
                              rows={5}
                              style={{ direction: "rtl" }}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")}
                            </label>
                            <textarea
                              className="form-control"
                              name="terms"
                              value={this.state.input.terms}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")} (Arabic)
                            </label>
                            <textarea
                              style={{ direction: "rtl" }}
                              className="form-control"
                              name="terms_arabic"
                              value={this.state.input.terms_arabic}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <h5>{i18n.t("enquiry")}</h5>
                          {enquiries.length > 0 &&
                            enquiries.map((item, i) => {
                              return (
                                <div className="row" key={i}>
                                  <div className="col-sm-5 col-md-5">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Label
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonLabel"
                                        className="form-control"
                                        placeholder="Label"
                                        value={item.label || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "label",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-5 col-md-5">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Value
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonValue"
                                        className="form-control"
                                        placeholder="Value"
                                        value={item.value || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "value",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {i > 0 && (
                                    <div className="col-sm-2 col-md-2">
                                      <div className="form-group">
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          key={i}
                                          onClick={(event) => {
                                            this.removeEnquiryData(i);
                                          }}
                                        >
                                          <i className="fa fa-minus"></i>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={this.appendData}
                              style={{ marginRight: "20px" }}
                            >
                              <i className="fa fa-plus"></i>
                              <span>Add New</span>
                            </button>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <hr />
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Create Group
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={(event) => {
                                this.setState({
                                  collapse: !this.state.collapse,
                                });
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Groups</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {groups.length < 1 && <div>No groups found!!!</div>}
                      {groups.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th className="text-center">Group Name</th>
                              <th>Reg Start Date</th>
                              <th>Reg End Date</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groups.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">
                                    <img
                                      src={item.image}
                                      style={{ width: "auto", height: "30px" }}
                                      alt=""
                                    />
                                    <br />
                                    {item.group_name}
                                    <br />
                                    {item.group_name_ar}
                                  </td>
                                  <td>{item.reg_start_date_formatted}</td>
                                  <td>{item.reg_end_date_formatted}</td>
                                  <td>{item.start_date_formatted}</td>
                                  <td>{item.end_date_formatted}</td>
                                  <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_group/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      Edit
                                    </Link>
                                  </td>
                                  <td>
                                    {item.can_delete && (
                                      <button
                                        className="btn btn-danger btn-sm text-white m-1"
                                        onClick={(e) => {
                                          this.deleteRow(item.id);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>&nbsp;
                                        Delete
                                      </button>
                                    )}

                                    {!item.can_delete && (
                                      <small className="text text-danger">
                                        Linked to an auction. Can't delete.
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
