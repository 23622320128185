import React from "react";
import i18n from "../../i18n";
import logo from "../../logo-mod.png";
import { Routes, Route, Link, NavLink } from "react-router-dom";
//import { MarqueeComponent } from "../home/marquee";
//import { WhoWeAre } from "../about/WhoWeAre";
import { Homepage } from "../home/homepage";
import { Registration } from "../auth/registration";
import Login from "../auth/login";
import { LoginOtp } from "../auth/login_otp";
import { ForgotPassword } from "../auth/forgotPassword";
//import { CategoryAuctions } from "../categoryAuctions";
import { Contact } from "../contact";
import $ from "jquery";
import { ChairmansMessage } from "../about/chairmansMessage";
import { CeoMessage } from "../about/ceoMessage";
import { TeamMzadcom } from "../about/teamMzadcom";
import { Enroll } from "../enroll";
import { Dashboard } from "../dashboard/dashboard";
import { Payments } from "../dashboard/payments";
import { CreateAuction } from "../dashboard/create_auction";
import { AuctionsList } from "../dashboard/auctions_list";
import { Masks } from "../dashboard/masks";
import { EditMask } from "../dashboard/edit_mask";
import { EditAuction } from "../dashboard/edit_auction";
import { NotFound } from "../notfound";
import { PaymentCancelled } from "../paymentCancelled";
import { PaymentSuccess } from "../paymentSuccess";
import { TermsConditions } from "../terms_conditions";
import { TermsConditionsPayment } from "../terms_conditions_payment";
import { UsersList } from "../dashboard/users";
import { Groups } from "../dashboard/groups";
import { EditGroup } from "../dashboard/edit_group";
import { EnrolledAuctions } from "../dashboard/enrolled_auctions";
import { getLoggedUser } from "../../state/user";
import { ResetPassword } from "../auth/resetPassword";
import { Categories } from "../dashboard/categories";
import { Organizations } from "../dashboard/organizations";
import { AuctionDetails } from "../home/auctionDetails";
import { RentalYards } from "../services/rentalYards";
import { SegreggationsEvaluation } from "../services/segreggationsEvaluation";
import { Transportation } from "../services/transportation";
import { Recycling } from "../services/recycling";
import { Auctions } from "../auctions";
import { EditCategory } from "../dashboard/edit_category";
import { EditOrganization } from "../dashboard/edit_organization";
//import { TopCategories } from "./categories";
//import { OrganizationsLinks } from "./organizations";
import { EnrolledUsers } from "../dashboard/enrolled_users";
import { ImagePanel } from "../dashboard/image_panel";
import { EnquiriesDetails } from "../dashboard/enquiries_details";
import { Contracts } from "../dashboard/contracts";
import { EditProfile } from "../dashboard/edit_profile";

import { Reports } from "../dashboard/reports/reports";
import { Winners } from "../dashboard/reports/winners";
import { AuctionBiddingReport } from "../dashboard/reports/auction_bid_report";
import { SalesReport } from "../dashboard/reports/sales_report";
import { FinalReport } from "../dashboard/reports/final_report";
import { Banners } from "../dashboard/banners";
import { WhatIsMzadcom } from "../about/whatismzadcom";
import { RefundPayment } from "../dashboard/refund";
import { PaymentDetails } from "../dashboard/payment_details";
import { AuctionsListGrouped } from "../home/AuctionsListGrouped";
import { MyAuctions } from "../dashboard/my_auctions";
import { LiveActivity } from "../dashboard/live_activity";
import { BulkMail } from "../dashboard/bulk_mail";
import { ReportsPosition } from "../dashboard/reports/reports_position";
import { WinningBids } from "../dashboard/winning_bids";
import { WhoWeAre } from "../about/WhoWeAre";
import { Blacklist } from "../dashboard/blacklist";
import { StaffUsers } from "../dashboard/staff_users";
import { MainGroups } from "../dashboard/main_groups";
import { EditMainGroup } from "../dashboard/edit_main_group";

const user = getLoggedUser();

const changeLanguage = (ln) => {
  i18n.changeLanguage(ln);
  window.location.reload();
};

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}
export class Topbar extends React.Component {
  componentDidMount() {
    (function (factory) {
      factory($);
      $(".horizontal-main").sticky({
        topSpacing: 0,
      });
      $(".horizontal-header").sticky({
        topSpacing: 0,
      });
    })(function ($) {
      var slice = Array.prototype.slice;
      var splice = Array.prototype.splice;
      var defaults = {
          topSpacing: 0,
          bottomSpacing: 0,
          className: "is-sticky",
          wrapperClassName: "sticky-wrapper",
          center: !1,
          getWidthFrom: "",
          widthFromWrapper: !0,
          responsiveWidth: !1,
          zIndex: "inherit",
        },
        $window = $(window),
        $document = $(document),
        sticked = [],
        windowHeight = $window.height(),
        scroller = function () {
          var scrollTop = $window.scrollTop(),
            documentHeight = $document.height(),
            dwh = documentHeight - windowHeight,
            extra = scrollTop > dwh ? dwh - scrollTop : 0;
          for (var i = 0, l = sticked.length; i < l; i++) {
            var s = sticked[i],
              elementTop = s.stickyWrapper.offset().top,
              etse = elementTop - s.topSpacing - extra;
            s.stickyWrapper.css("height", s.stickyElement.outerHeight());
            if (scrollTop <= etse) {
              if (s.currentTop !== null) {
                s.stickyElement.css({
                  width: "",
                  position: "",
                  top: "",
                  "z-index": "",
                });
                s.stickyElement.parent().removeClass(s.className);
                s.stickyElement.trigger("sticky-end", [s]);
                s.currentTop = null;
              }
            } else {
              var newTop =
                documentHeight -
                s.stickyElement.outerHeight() -
                s.topSpacing -
                s.bottomSpacing -
                scrollTop -
                extra;
              if (newTop < 0) {
                newTop = newTop + s.topSpacing;
              } else {
                newTop = s.topSpacing;
              }
              if (s.currentTop !== newTop) {
                var newWidth;
                if (s.getWidthFrom) {
                  let padding =
                    s.stickyElement.innerWidth() - s.stickyElement.width();
                  newWidth = $(s.getWidthFrom).width() - padding || null;
                } else if (s.widthFromWrapper) {
                  newWidth = s.stickyWrapper.width();
                }
                if (newWidth == null) {
                  newWidth = s.stickyElement.width();
                }
                s.stickyElement
                  .css("width", newWidth)
                  .css("position", "fixed")
                  .css("top", newTop)
                  .css("z-index", s.zIndex);
                s.stickyElement.parent().addClass(s.className);
                if (s.currentTop === null) {
                  s.stickyElement.trigger("sticky-start", [s]);
                } else {
                  s.stickyElement.trigger("sticky-update", [s]);
                }
                if (
                  (s.currentTop === s.topSpacing && s.currentTop > newTop) ||
                  (s.currentTop === null && newTop < s.topSpacing)
                ) {
                  s.stickyElement.trigger("sticky-bottom-reached", [s]);
                } else if (
                  s.currentTop !== null &&
                  newTop === s.topSpacing &&
                  s.currentTop < newTop
                ) {
                  s.stickyElement.trigger("sticky-bottom-unreached", [s]);
                }
                s.currentTop = newTop;
              }
              var stickyWrapperContainer = s.stickyWrapper.parent();
              var unstick =
                s.stickyElement.offset().top + s.stickyElement.outerHeight() >=
                  stickyWrapperContainer.offset().top +
                    stickyWrapperContainer.outerHeight() &&
                s.stickyElement.offset().top <= s.topSpacing;
              if (unstick) {
                s.stickyElement
                  .css("position", "absolute")
                  .css("top", "")
                  .css("bottom", 0)
                  .css("z-index", "");
              } else {
                s.stickyElement
                  .css("position", "fixed")
                  .css("top", newTop)
                  .css("bottom", "")
                  .css("z-index", s.zIndex);
              }
            }
          }
        },
        resizer = function () {
          windowHeight = $window.height();
          for (var i = 0, l = sticked.length; i < l; i++) {
            var s = sticked[i];
            var newWidth = null;
            if (s.getWidthFrom) {
              if (s.responsiveWidth) {
                newWidth = $(s.getWidthFrom).width();
              }
            } else if (s.widthFromWrapper) {
              newWidth = s.stickyWrapper.width();
            }
            if (newWidth != null) {
              s.stickyElement.css("width", newWidth);
            }
          }
        },
        methods = {
          init: function (options) {
            return this.each(function () {
              var o = $.extend({}, defaults, options);
              var stickyElement = $(this);
              var stickyId = stickyElement.attr("id");
              var wrapperId = stickyId
                ? stickyId + "-" + defaults.wrapperClassName
                : defaults.wrapperClassName;
              var wrapper = $("<div></div>")
                .attr("id", wrapperId)
                .addClass(o.wrapperClassName);
              stickyElement.wrapAll(function () {
                if ($(this).parent("#" + wrapperId).length === 0) {
                  return wrapper;
                }
              });
              var stickyWrapper = stickyElement.parent();
              if (o.center) {
                stickyWrapper.css({
                  width: stickyElement.outerWidth(),
                  marginLeft: "auto",
                  marginRight: "auto",
                });
              }
              if (stickyElement.css("float") === "right") {
                stickyElement
                  .css({
                    float: "none",
                  })
                  .parent()
                  .css({
                    float: "right",
                  });
              }
              o.stickyElement = stickyElement;
              o.stickyWrapper = stickyWrapper;
              o.currentTop = null;
              sticked.push(o);
              methods.setWrapperHeight(this);
              methods.setupChangeListeners(this);
            });
          },
          setWrapperHeight: function (stickyElement) {
            var element = $(stickyElement);
            var stickyWrapper = element.parent();
            if (stickyWrapper) {
              stickyWrapper.css("height", element.outerHeight());
            }

            var i = sticked.length;
            if (i === 1) {
              stickyWrapper.addClass("sticky-wrapper-custom");
            } else {
              stickyWrapper.addClass("sticky-wrapper-custom-top");
            }
            $(".sticky-wrapper-custom").removeClass(
              "sticky-wrapper-custom-top"
            );
          },
          setupChangeListeners: function (stickyElement) {
            if (window.MutationObserver) {
              var mutationObserver = new window.MutationObserver(function (
                mutations
              ) {
                if (
                  mutations[0].addedNodes.length ||
                  mutations[0].removedNodes.length
                ) {
                  methods.setWrapperHeight(stickyElement);
                }
              });
              mutationObserver.observe(stickyElement, {
                subtree: !0,
                childList: !0,
              });
            } else {
              if (window.addEventListener) {
                stickyElement.addEventListener(
                  "DOMNodeInserted",
                  function () {
                    methods.setWrapperHeight(stickyElement);
                  },
                  !1
                );
                stickyElement.addEventListener(
                  "DOMNodeRemoved",
                  function () {
                    methods.setWrapperHeight(stickyElement);
                  },
                  !1
                );
              } else if (window.attachEvent) {
                stickyElement.attachEvent("onDOMNodeInserted", function () {
                  methods.setWrapperHeight(stickyElement);
                });
                stickyElement.attachEvent("onDOMNodeRemoved", function () {
                  methods.setWrapperHeight(stickyElement);
                });
              }
            }
          },
          update: scroller,
          unstick: function (options) {
            return this.each(function () {
              var that = this;
              var unstickyElement = $(that);
              var removeIdx = -1;
              var i = sticked.length;
              while (i-- > 0) {
                if (sticked[i].stickyElement.get(0) === that) {
                  splice.call(sticked, i, 1);
                  removeIdx = i;
                }
              }
              if (removeIdx !== -1) {
                unstickyElement.unwrap();
                unstickyElement.css({
                  width: "",
                  position: "",
                  top: "",
                  float: "",
                  "z-index": "",
                });
              }
            });
          },
        };
      if (window.addEventListener) {
        window.addEventListener("scroll", scroller, !1);
        window.addEventListener("resize", resizer, !1);
      } else if (window.attachEvent) {
        window.attachEvent("onscroll", scroller);
        window.attachEvent("onresize", resizer);
      }
      $.fn.sticky = function (method) {
        if (methods[method]) {
          return methods[method].apply(this, slice.call(arguments, 1));
        } else if (typeof method === "object" || !method) {
          return methods.init.apply(this, arguments);
        } else {
          $.error("Method " + method + " does not exist on jQuery.sticky");
        }
      };
      $.fn.unstick = function (method) {
        if (methods[method]) {
          return methods[method].apply(this, slice.call(arguments, 1));
        } else if (typeof method === "object" || !method) {
          return methods.unstick.apply(this, arguments);
        } else {
          $.error("Method " + method + " does not exist on jQuery.sticky");
        }
      };
      $(function () {
        setTimeout(scroller, 0);
      });
    });
  }

  hideSideBar() {
    document.body.classList.remove("active");
  }
  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let is_logged_in = this.props.is_logged;
    return (
      <React.Fragment>
        {/* <MarqueeComponent /> */}
        <div className="header-main" id="main-top-header">
          {/* <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-sm-3 col-3">
                  <div className="top-bar-left d-flex">
                    <div className="clearfix">
                      <ul className="socials">
                        <li>
                          <a
                            className="social-icon"
                            href="https://www.instagram.com/mzadcomom"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-instagram text-purple" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="social-icon"
                            href="https://twitter.com/mzadcom.om"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-twitter text-info" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="social-icon text-dark"
                            href="https://www.facebook.com/profile.php?id=100074132232182"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-facebook text-info" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="social-icon text-dark"
                            href="https://api.whatsapp.com/send?phone=0096892788822"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-whatsapp text-success" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="clearfix">
                      <ul className="contact border-start">
                        <li className="select-country">
                          {current_lng === "ar" && (
                            <button
                              className="btn btn-sm px-3 text-dark"
                              onClick={(e) => changeLanguage("en")}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/flags/en.svg"
                                }
                                height="15"
                                alt=""
                              />{" "}
                              English
                            </button>
                          )}
                          {current_lng === "en" && (
                            <button
                              className="btn btn-sm px-3 text-dark"
                              onClick={(e) => changeLanguage("ar")}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/flags/ar.svg"
                                }
                                height="15"
                                alt=""
                              />{" "}
                              عربي
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-6 col-6">
                  <div
                    className="text-info text-center mt-4 border-start border-end"
                    style={{ fontSize: "0.7rem" }}
                  >
                    <span>{i18n.t("welcome")}</span>&nbsp;
                    <span>
                      <strong>
                        {user && user.name ? user.name : i18n.t("guest")}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-3 col-3">
                  <div className="top-bar-right">
                    {is_logged_in ? (
                      <ul className="custom">
                        <li>
                          <Link
                            to="/dashboard"
                            className="text-dark"
                            style={{ fontSize: "0.7rem" }}
                          >
                            <i className="fa fa-home me-1" />
                            <span className="">{i18n.t("dashboard")}</span>
                          </Link>
                        </li>
                        <li>
                          <a
                            href={true}
                            className="text-dark"
                            onClick={logout}
                            style={{ cursor: "pointer", fontSize: "0.7rem" }}
                          >
                            <i className="fa fa-sign-out me-1" />
                            <span>{i18n.t("logout")}</span>
                          </a>
                        </li>
                      </ul>
                    ) : (
                      <ul className="custom">
                        <li>
                          <i className="fa fa-user me-1" />
                          <span>
                            <Link to="/registration" className="text-dark">
                              {i18n.t("registration")}
                            </Link>
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-sign-in me-1" />
                          <span>
                            <Link to="/login" className="text-dark">
                              {i18n.t("login")}
                            </Link>
                          </span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Horizontal Header */}
          <div className="horizontal-header clearfix">
            <div className="container">
              <a
                id="horizontal-navtoggle"
                className="animated-arrow"
                href={true}
              >
                <span />
              </a>
              <span className="smllogo">
                <Link to="/" className="logo-responsive">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "130px", height: "40px" }}
                  />
                </Link>
              </span>
            </div>
          </div>
          {/* /Horizontal Header */}
          {/* Horizontal Main */}
          <div className="horizontal-main bg-dark-transparent clearfix">
            {/*bb-1*/}
            <div className="horizontal-mainwrapper container clearfix">
              {/*Nav*/}
              <div className="flag-item">
                {current_lng === "ar" && (
                  <div onClick={(e) => changeLanguage("en")}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/flags/en.svg"
                      }
                      height="20"
                      alt=""
                    />
                    {/* English */}
                  </div>
                )}
                {current_lng === "en" && (
                  <div onClick={(e) => changeLanguage("ar")}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/flags/ar.svg"
                      }
                      height="20"
                      alt=""
                    />
                    {/* عربي */}
                  </div>
                )}
              </div>
              <nav className="horizontalMenu clearfix d-md-flex">
                <ul className="horizontalMenu-list">
                  <li className="show-mob">
                    <a
                      className="menu-close-icon"
                      href={true}
                      onClick={(event) => {
                        this.hideSideBar();
                      }}
                    >
                      <i className="fa fa-times" />
                    </a>
                  </li>
                  <li
                    style={{
                      padding: current_lng === "ar" ? "0.5rem 0" : "1.5rem 0",
                    }}
                  >
                    <Link to="/">
                      <img
                        src={logo}
                        alt=""
                        style={{ width: "130px", height: "40px" }}
                      />
                    </Link>
                  </li>
                  <li>
                    {is_logged_in && (
                      <NavLink
                        exact="true"
                        to={true}
                        className="border-end"
                        onClick={logout}
                      >
                        <span style={{ fontSize: "1.3rem" }}>
                          <i
                            className="fa fa-sign-out me-1"
                            style={{
                              fontSize: "1.3rem",
                              margin: "0",
                              padding: "0",
                            }}
                          />
                        </span>
                        <span style={{ padding: "0px 15px" }}>
                          {i18n.t("logout")}
                        </span>
                      </NavLink>
                    )}
                    {!is_logged_in && (
                      <NavLink
                        exact="true"
                        to="/login"
                        className="border-end"
                        style={{ borderRadius: "0" }}
                        onClick={(event) => {
                          this.hideSideBar();
                        }}
                      >
                        <span>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/icons/login.png"
                            }
                            style={{ width: "25px", height: "25px" }}
                            alt=""
                          />
                        </span>
                        <span style={{ padding: "0px 15px" }}>
                          {i18n.t("login")}
                        </span>
                      </NavLink>
                    )}
                  </li>
                  {is_logged_in && (
                    <li>
                      <NavLink
                        exact="true"
                        to="/dashboard"
                        className="border-end"
                      >
                        <span style={{ fontSize: "1.3rem" }}>
                          <i
                            className="fa fa-home me-1"
                            style={{
                              fontSize: "1.3rem",
                              margin: "0",
                              padding: "0",
                            }}
                          />
                        </span>
                        <span style={{ padding: "0px 15px" }}>
                          {i18n.t("dashboard")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      exact="true"
                      to="/"
                      activeClassName="active"
                      onClick={(event) => {
                        this.hideSideBar();
                      }}
                    >
                      {i18n.t("home")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/auctions"
                      activeClassName="active"
                      onClick={(event) => {
                        this.hideSideBar();
                      }}
                    >
                      {i18n.t("auctions")}{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      activeClassName="active"
                      onClick={(event) => {
                        this.hideSideBar();
                      }}
                    >
                      {i18n.t("contactus")}{" "}
                    </NavLink>
                  </li>

                  <li className="hide-mob" style={{ paddingTop: "1.8rem" }}>
                    <span className="welcome">
                      {i18n.t("welcome")}{" "}
                      {is_logged_in ? user.name : i18n.t("guest")}
                    </span>
                  </li>

                  <li className="show-mob">
                    {current_lng === "ar" && (
                      <a href={true} onClick={(e) => changeLanguage("en")}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/flags/en.svg"
                          }
                          height="20"
                          alt=""
                        />{" "}
                        English{" "}
                      </a>
                    )}
                    {current_lng === "en" && (
                      <a href={true} onClick={(e) => changeLanguage("ar")}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/flags/ar.svg"
                          }
                          height="20"
                          alt=""
                        />{" "}
                        عربي{" "}
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
              {/*Nav*/}
            </div>
          </div>
          {/* /Horizontal Main */}
        </div>
        {/* Topbar */}

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<WhoWeAre />} />
          {/* <Route path="/about/whoweare" element={<WhoWeAre />} /> */}
          <Route path="/about/what-mzadcom" element={<WhatIsMzadcom />} />
          <Route
            path="/about/message-chairman"
            element={<ChairmansMessage />}
          />
          <Route path="/about/message-ceo" element={<CeoMessage />} />
          <Route path="/about/team-mzadcom" element={<TeamMzadcom />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/terms_payment" element={<TermsConditionsPayment />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login_otp" element={<LoginOtp />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          {/* <Route path="/category-auctions" element={<CategoryAuctions />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/password/reset/:email/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/grouped-auctions/:id"
            element={<AuctionsListGrouped />}
          />
          <Route path="/auction-details/:id" element={<AuctionDetails />} />
          <Route exact path="/auctions" element={<Auctions />} />
          <Route path="/services" element={<RentalYards />} />
          <Route path="/services/rental-yards" element={<RentalYards />} />
          <Route
            path="/services/segreggations-evaluation"
            element={<SegreggationsEvaluation />}
          />
          <Route path="/services/transportation" element={<Transportation />} />
          <Route path="/services/recycling" element={<Recycling />} />

          {/** Protected Routes */}
          <Route
            path="/dashboard"
            element={
              localStorage.getItem("is_logged_in") ? (
                <Dashboard />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/profile"
            element={
              localStorage.getItem("is_logged_in") ? (
                <EditProfile />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/enroll/:id"
            element={
              localStorage.getItem("is_logged_in") ? <Enroll /> : <NotFound />
            }
          />
          <Route
            path="/payments"
            element={
              localStorage.getItem("is_logged_in") ? <Payments /> : <NotFound />
            }
          />
          <Route
            path="/create_auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <CreateAuction />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_auction/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditAuction />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auctions_list"
            element={
              localStorage.getItem("is_logged_in") ? (
                <AuctionsList />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/main_groups"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <MainGroups />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/groups"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Groups />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_main_group/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditMainGroup />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_group/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditGroup />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/masks"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Masks />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_mask/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditMask />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/categories"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Categories />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_category/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditCategory />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/organizations"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Organizations />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_organization/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditOrganization />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/payment-success/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <PaymentSuccess />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/banners"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Banners />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/payment-cancelled"
            element={
              localStorage.getItem("is_logged_in") ? (
                <PaymentCancelled />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/users"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <UsersList />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/staff_users"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <StaffUsers />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/enrolled_auctions"
            element={
              localStorage.getItem("is_logged_in") ? (
                <EnrolledAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/my_auctions"
            element={
              localStorage.getItem("is_logged_in") && user.role === "U" ? (
                <MyAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/winning_bids"
            element={
              localStorage.getItem("is_logged_in") && user.role === "U" ? (
                <WinningBids />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/live_activity"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <LiveActivity />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_bid_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <AuctionBiddingReport />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/sales_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "M") ? (
                <SalesReport />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/final_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "M") ? (
                <FinalReport />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/position_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "M") ? (
                <ReportsPosition />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/winners"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <Winners />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/reports"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <Reports />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/enrolled_users"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <EnrolledUsers />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/image_uploader/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <ImagePanel />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/enquiries_details/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EnquiriesDetails />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/contracts/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Contracts />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/refund"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <RefundPayment />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/composer"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <BulkMail />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/refund/:reference"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <PaymentDetails />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/blacklist"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <Blacklist />
              ) : (
                <NotFound />
              )
            }
          />

          <Route path="*" exact={true} element={<NotFound />} />
        </Routes>
      </React.Fragment>
    );
  }
}
