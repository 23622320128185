import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import i18n from "../i18n";
import { getCategories } from "../models/categories";
import { getAllAuctions } from "../models/auctions";
import { getOrganizations } from "../models/organization";
import { Spinner } from "react-bootstrap";
import { AuctionCardHorz } from "./home/helpers/auctionCardHorz";
import { AuctionCard } from "./home/helpers/auctionCard";

let current_lng = localStorage.getItem("I18N_LANGUAGE");
export class Auctions extends React.Component {
  constructor() {
    super();
    this.state = {
      catArrs: [],
      categories: [],
      orgArrs: [],
      organizations: [],
      auctions: [],
      is_loading: false,
      is_cat_loading: false,
      lightboxImages: [],
      is_active_light_box: false,
      grid: true,
      section: "",
      type: "",
      category: "",
      organization: "",
      expand_orgs: false,
      expand_cats: false,
      //section: new URLSearchParams(this.props.location.search).get("sec"),
    };
    this.getAllAuctions = this.getAllAuctions.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.loadAllCategories(params);
    this.loadAllOrganizations(params);
    this.getAllAuctions(params);
  }

  async loadAllCategories(params) {
    this.setState({ is_cat_loading: true });
    if (typeof params.category !== "undefined") {
      var category = params.category;
      this.setState({ catArrs: [parseInt(category)] });
    }
    const response = await getCategories();
    if (response) {
      let categories = response.data;
      this.setState({ categories });
      this.setState({ is_cat_loading: false });
    }
  }

  async loadAllOrganizations(params) {
    this.setState({ is_org_loading: true });
    if (typeof params.organization !== "undefined") {
      var org = params.organization;
      this.setState({ orgArrs: [parseInt(org)] });
    }
    const response = await getOrganizations();
    if (response) {
      let organizations = response.data;
      this.setState({ organizations });
      this.setState({ is_org_loading: false });
    }
  }

  async getAllAuctions(params) {
    this.setState({ is_loading: true });
    let q = "";
    if (typeof params.sec !== "undefined") {
      q += "section=" + params.sec;
    }
    if (typeof params.type !== "undefined") {
      q += "&type=" + params.type;
    }
    if (typeof params.category !== "undefined") {
      q += "&category=" + params.category;
    }
    if (typeof params.organization !== "undefined") {
      q += "&organization=" + params.organization;
    }

    this.setState({
      section: params.sec,
      type: params.type,
      category: params.category,
      organization: params.organization,
    });

    const response = await getAllAuctions(q);
    if (response) {
      let auctions = response.data;
      this.setState({ auctions, is_loading: false });
    }
  }

  createCategory(event) {
    var catArrs = this.state.catArrs;
    if (event.target.checked) {
      catArrs.push(parseInt(event.target.value));
    } else {
      const index = catArrs.indexOf(parseInt(event.target.value));
      if (index > -1) {
        catArrs.splice(index, 1);
      }
    }
    this.setState({ catArrs: catArrs });
  }

  createOrganization(event) {
    var orgArrs = this.state.orgArrs;
    if (event.target.checked) {
      orgArrs.push(parseInt(event.target.value));
    } else {
      const index = orgArrs.indexOf(parseInt(event.target.value));
      if (index > -1) {
        orgArrs.splice(index, 1);
      }
    }
    this.setState({ orgArrs: orgArrs });
  }

  applyFilters() {
    window.scrollTo(0, 0);
    var type = this.state.type;
    var section = this.state.section;

    var catArrs = this.state.catArrs;
    var cats = catArrs.join(",");
    if (catArrs.length < 1) {
      cats = "";
    }

    var orgArrs = this.state.orgArrs;
    var orgs = orgArrs.join(",");
    if (orgArrs.length < 1) {
      orgs = "";
    }

    this.setState({
      category: cats,
      type: type,
      section: section,
      organization: orgs,
    });

    this.getAllAuctions({
      sec: section,
      type: type,
      category: cats,
      organization: orgs,
    });
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
      this.setState({
        is_active_light_box: active,
        lightboxImages: lightboxImages,
      });
    }
  }

  sortByOrder(data) {
    let result = data;
    if (data.length) {
      result = data.sort((a, b) => a.order - b.order);
    }
    return result;
  }

  render() {
    let categories = this.state.categories;
    let organizations = this.state.organizations;
    let auctions = this.sortByOrder(this.state.auctions);
    let catArrs = this.state.catArrs;
    let orgArrs = this.state.orgArrs;
    return (
      <React.Fragment>
        <div>
          <div
            className="cover-image sptb-1 bg-background"
            data-image-src={
              process.env.PUBLIC_URL + "/assets/images/banners/banner1.jpg"
            }
          >
            <div className="header-text1 mb-0">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
                    <div className="text-center text-primary ">
                      <h1 className="auction-title-cust">
                        {this.state.type === "active" &&
                          i18n.t("active_auctions")}
                        {this.state.type === "upcoming" &&
                          i18n.t("upcoming_auctions")}
                        {this.state.type === "previous" &&
                          i18n.t("previous_auctions")}
                        {this.state.type === "featured" &&
                          i18n.t("featured_auctions")}
                        {typeof this.state.type === "undefined" &&
                          i18n.t("auctions")}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-white border-bottom">
          <div className="container">
            <div className="page-header">
              <h4 className="page-title">{i18n.t("auctions")}</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">{i18n.t("home")}/</li>
                <li className="breadcrumb-item active" aria-current="page">
                  {i18n.t("auctions")}
                </li>
              </ol>
            </div>
          </div>
        </div> */}

        <section className="sptb">
          <div className="container">
            <div className="row flex-direction-reverse">
              <div className="col-xl-9 col-lg-9 col-md-12">
                {/*Lists*/}
                <div className=" mb-0">
                  <div className>
                    <div className="item2-gl ">
                      {auctions.length > 0 && (
                        <div className=" mb-0">
                          <div>
                            <div className="bg-white p-1 item2-gl-nav d-flex">
                              <div className="col-xl-9 col-lg-9 col-md-9">
                                <h6
                                  className="mb-0 mt-3"
                                  style={{
                                    textAlign:
                                      current_lng === "ar" ? "right" : "left",
                                  }}
                                >
                                  {i18n.t("showing")} {auctions.length}{" "}
                                  {i18n.t("entries")}
                                </h6>
                              </div>
                              <div
                                className="col-xl-3 col-lg-3 col-md-3 d-mob-none"
                                style={{
                                  textAlign:
                                    current_lng === "ar" ? "left" : "right",
                                }}
                              >
                                <button
                                  type="button"
                                  className={
                                    !this.state.grid
                                      ? "btn btn-warning m-1"
                                      : "btn btn-gray m-1"
                                  }
                                  onClick={() => {
                                    this.setState({ grid: false });
                                  }}
                                >
                                  <i className="fa fa-list" />
                                </button>
                                <button
                                  type="button"
                                  className={
                                    this.state.grid
                                      ? "btn btn-warning"
                                      : "btn btn-gray"
                                  }
                                  onClick={() => {
                                    this.setState({ grid: true });
                                  }}
                                >
                                  <i className="fa fa-columns" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="tab-content auction-listing-pg">
                        <div className="tab-pane active" id="tab-11">
                          {this.state.is_loading && (
                            <div style={{ textAlign: "center" }}>
                              <Spinner animation="border" />
                            </div>
                          )}

                          {!this.state.is_loading &&
                            !this.state.grid &&
                            auctions.length > 0 &&
                            auctions.map((item, i) => {
                              let _data = {
                                auction: item,
                                auctionId: item.id,
                                total_items: auctions.length,
                                company: "Mzadcom",
                                price: item.start_amount,
                                title: item.title,
                                title_ar: item.title_ar,
                                description: "Diesel Generators",
                                curr_amount: item.current_amount,
                                end_date: item.end_date_formatted,
                                start_date: item.start_date_formatted,
                                images: item.images,
                                total_views: item.total_views,
                                type: this.state.type,
                                can_bid: false,
                                status: item.status,
                              };

                              return (
                                <AuctionCardHorz
                                  key={i}
                                  {..._data}
                                  getImagePopup={this.getImagePopup}
                                />
                              );
                            })}

                          <div className="row">
                            {!this.state.is_loading &&
                              this.state.grid &&
                              auctions.length > 0 &&
                              auctions.map((item, i) => {
                                let _data = {
                                  auction: item,
                                  auctionId: item.id,
                                  total_items: auctions.length,
                                  company: "Mzadcom",
                                  price: item.start_amount,
                                  title: item.title,
                                  title_ar: item.title_ar,
                                  description: "Diesel Generators",
                                  curr_amount: item.current_amount,
                                  end_date: item.end_date_formatted,
                                  start_date: item.start_date_formatted,
                                  images: item.images,
                                  total_views: item.total_views,
                                  type: this.state.type,
                                  can_bid: false,
                                  status: item.status,
                                };

                                return (
                                  <div className="col-md-4">
                                    <AuctionCard
                                      key={i}
                                      {..._data}
                                      getImagePopup={this.getImagePopup}
                                      isDetailsPage={true}
                                    />
                                  </div>
                                );
                              })}
                          </div>

                          {!this.state.is_loading && !auctions.length && (
                            <div className="help text-danger text-center">
                              {i18n.t("no_auctions_found")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="center-block text-center">
                      <ul className="pagination mb-3">
                        <li className="page-item page-prev disabled">
                          <a className="page-link" href={true} tabIndex={-1}>
                            Prev
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link" href={true}>
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href={true}>
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href={true}>
                            3
                          </a>
                        </li>
                        <li className="page-item page-next">
                          <a className="page-link" href={true}>
                            Next
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                {/*/Lists*/}
              </div>
              {/*Right Side Content*/}
              <div className="col-xl-3 col-lg-3 col-md-12">
                {/* <div className="card">
                  <div className="card-body">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control br-bs-3  br-be-3"
                        placeholder="Search"
                      />
                      <div className>
                        <button
                          type="button"
                          className="btn btn-primary br-ts-0  br-bs-0"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="card overflow-hidden">
                  <div className="px-4 py-3 border-bottom">
                    <h4
                      className="mb-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({ expand_cats: !this.state.expand_cats });
                      }}
                    >
                      {i18n.t("categories")}
                      <span className="toggle-up-down">
                        <i
                          className={
                            this.state.expand_cats
                              ? "fa fa-chevron-up"
                              : "fa fa-chevron-down"
                          }
                        />
                      </span>
                    </h4>
                  </div>
                  <div
                    className={
                      this.state.expand_cats ? "card-body" : "card-body d-none"
                    }
                  >
                    <div className id="container">
                      <div className="filter-product-checkboxs">
                        {this.state.is_cat_loading && (
                          <div style={{ textAlign: "center" }}>
                            <Spinner animation="border" />
                          </div>
                        )}

                        {!this.state.is_cat_loading &&
                          categories.map((item, i) => {
                            return (
                              <label className="custom-control custom-checkbox mb-3">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="checkbox1"
                                  defaultValue={item.id}
                                  value={item.id}
                                  onChange={this.createCategory}
                                  checked={catArrs.includes(item.id)}
                                />
                                <span className="custom-control-label">
                                  <a href={true} className="text-dark">
                                    {current_lng === "ar"
                                      ? item.category_name_ar
                                      : item.category_name}
                                    {/* <span className="label label-secondary float-end">
                                    14
                                  </span> */}
                                  </a>
                                </span>
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="divider" />

                <div className="card overflow-hidden">
                  <div className="px-4 py-3 border-bottom">
                    <h4
                      className="mb-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({ expand_orgs: !this.state.expand_orgs });
                      }}
                    >
                      <span>{i18n.t("organizations")}</span>
                      <span className="toggle-up-down">
                        <i
                          className={
                            this.state.expand_orgs
                              ? "fa fa-chevron-up"
                              : "fa fa-chevron-down"
                          }
                        />
                      </span>
                    </h4>
                  </div>
                  <div
                    className={
                      this.state.expand_orgs ? "card-body" : "card-body d-none"
                    }
                  >
                    <div className id="container">
                      <div className="filter-product-checkboxs">
                        {this.state.is_org_loading && (
                          <div style={{ textAlign: "center" }}>
                            <Spinner animation="border" />
                          </div>
                        )}

                        {!this.state.is_org_loading &&
                          organizations.map((item, i) => {
                            return (
                              <label className="custom-control custom-checkbox mb-3">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name="checkbox2"
                                  defaultValue={item.id}
                                  value={item.id}
                                  onChange={this.createOrganization}
                                  checked={orgArrs.includes(item.id)}
                                />
                                <span className="custom-control-label">
                                  <a href={true} className="text-dark">
                                    {current_lng === "ar"
                                      ? item.organization_name_ar
                                      : item.organization_name}
                                    {/* <span className="label label-secondary float-end">
                                    14
                                  </span> */}
                                  </a>
                                </span>
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card overflow-hidden">
                  {(!this.state.is_cat_loading ||
                    !this.state.is_org_loading) && (
                    <div className="card-footer">
                      <a
                        href={true}
                        className="btn btn-secondary btn-block"
                        onClick={() => {
                          this.applyFilters();
                        }}
                      >
                        {i18n.t("filter")}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {/*/Right Side Content*/}
            </div>
          </div>
        </section>

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
