import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAllCategories,
  createCategory,
  deleteCategory,
} from "../../models/categories";
export class Categories extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      categories: [],
      file_category_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllCategories();
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_category_image") {
      this.setState({ file_category_image: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();

    if (
      !this.state.input.category_name ||
      !this.state.input.category_name_ar ||
      !this.state.input.description ||
      !this.state.input.description_ar ||
      !this.state.file_category_image
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("category_name", this.state.input.category_name);
    formData.append("category_name_ar", this.state.input.category_name_ar);
    formData.append("description", this.state.input.description);
    formData.append("description_ar", this.state.input.description_ar);
    formData.append("file_category_image", this.state.file_category_image);

    const response = await createCategory(formData);
    if (response.success) {
      this.loadAllCategories();
    } else {
      swal("Failed", response.message, "error");
    }
  }

  deleteRow(id) {
    const response = deleteCategory(id);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllCategories();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    let categories = this.state.categories;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Categories
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">Create category</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Title</label>
                          <input
                            type="text"
                            name="category_name"
                            className="form-control"
                            placeholder="Name"
                            value={this.state.input.category_name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Title (Arabic)</label>
                          <input
                            type="text"
                            name="category_name_ar"
                            className="form-control rtl-dir"
                            placeholder="Name"
                            value={this.state.input.category_name_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            name="description"
                            className="form-control"
                            placeholder="Description"
                            value={this.state.input.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Description (Arabic)
                          </label>
                          <input
                            type="text"
                            name="description_ar"
                            className="form-control rtl-dir"
                            placeholder="Description"
                            value={this.state.input.description_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Upload category image
                          </label>
                          <input
                            className="form-control "
                            type="file"
                            placeholder=""
                            name="file_category_image"
                            onChange={this.onFileChange}
                          />
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >
                            (Image must be of size 168(w) X 84(h))
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button type="submit" className="btn btn-primary">
                            Create Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Categories</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {categories.length < 1 && (
                        <div>No categories found!!!</div>
                      )}
                      {categories.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th> Title</th>
                              <th> Title (Arabic)</th>
                              <th> Description</th>
                              <th> Description (Arabic)</th>
                              <th> Image</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.category_name}</td>
                                  <td>{item.category_name_ar}</td>
                                  <td>{item.description}</td>
                                  <td>{item.description_ar}</td>
                                  <td>
                                    {" "}
                                    <img
                                      src={item.file_category_image}
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_category/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      Edit
                                    </Link>
                                  </td>
                                  <td>
                                    {item.can_delete && (
                                      <button
                                        className="btn btn-danger btn-sm text-white m-1"
                                        onClick={(e) => {
                                          this.deleteRow(item.id);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>&nbsp;
                                        Delete
                                      </button>
                                    )}
                                    {!item.can_delete && (
                                      <small className="text text-muted">
                                        Linked to an auction. Can't delete.
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
