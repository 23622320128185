import React from "react";
import i18n from "../../../i18n";

export class AuctionsDetailTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: i18n.t("details"), tab: "details", icon: "fa fa-info-circle" },
        {
          name: i18n.t("description"),
          tab: "description",
          icon: "fa fa-file-text",
        },
        {
          name: i18n.t("enquiry"),
          tab: "enquiry",
          icon: "fa fa-question-circle",
        },
        { name: i18n.t("video"), tab: "video", icon: "fa fa-video-camera" },
        {
          name: i18n.t("contracts"),
          tab: "contracts",
          icon: "fa fa-files-o",
        },
        { name: i18n.t("terms_conditions"), tab: "terms", icon: "fa fa-gavel" },
        // { name: i18n.t("location"), tab: "location" },
      ],
    };
  }

  render() {
    let auction = this.props.auction;
    let lng = this.props.lng;

    let enquiries = auction.enquiry;
    let enquiry_length =
      (auction && auction.enquiry && auction.enquiry.length) || 0;

    let details = auction.details;
    let details_length =
      (auction && auction.details && auction.details.length) || 0;

    let downloads = auction.downloads;
    let downloads_length =
      (auction && auction.downloads && auction.downloads.length) || 0;

    let video = auction.video;
    return (
      <React.Fragment>
        <div className="">
          <div className="">
            <div className="border-0 mb-5">
              <div className="">
                <a
                  href={true}
                  className={
                    this.state.expand_details
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_details: !this.state.expand_details,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/details.png"}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">{i18n.t("details")}</span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_details
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_details && (
                  <div className="card active card-details">
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        <div className="table-responsive">
                          {details_length > 0 && (
                            <table className="table table-bordered border-top mb-0">
                              <tbody>
                                {details.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {lng === "ar" && item.label_ar
                                          ? item.label_ar
                                          : item.label}
                                      </td>
                                      <td>
                                        {lng === "ar" && item.value_ar
                                          ? item.value_ar
                                          : item.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <a
                  href={true}
                  className={
                    this.state.expand_description
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_description: !this.state.expand_description,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/description.png"
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">{i18n.t("description")}</span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_description
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_description && (
                  <div className="card active card-details">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="col-xl-12 col-md-12"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {lng === "ar"
                            ? auction.description_ar
                            : auction.description}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_enquiry
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_enquiry: !this.state.expand_enquiry,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/inquiries.png"
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">{i18n.t("enquiry")}</span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_enquiry
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_enquiry && (
                  <div className="card active card-details">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          {enquiry_length > 0 && (
                            <table className="table table-bordered border-top mb-0">
                              <tbody>
                                {enquiries.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {lng === "ar" && item.label_ar
                                          ? item.label_ar
                                          : item.label}
                                      </td>
                                      <td>
                                        {lng === "ar" && item.value_ar
                                          ? item.value_ar
                                          : item.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_video
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_video: !this.state.expand_video,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/video.png"}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">{i18n.t("video")}</span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_video
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_video && (
                  <div className="card active card-details">
                    {video && (
                      <video
                        controls
                        style={{
                          width: "100%",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_contracts
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_contracts: !this.state.expand_contracts,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/contract.png"
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">{i18n.t("contracts")}</span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_contracts
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_contracts && (
                  <div className="card active card-details">
                    {downloads_length > 0 && (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Attatchment</th>
                          </tr>
                        </thead>
                        {downloads.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {lng === "ar" && item.label_ar
                                  ? item.label_ar
                                  : item.label}
                              </td>
                              <td>
                                <a
                                  href={item.file}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {i18n.t("download")}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    )}
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_terms
                      ? "details-acc-head active"
                      : "details-acc-head mb-3"
                  }
                  onClick={() => {
                    this.setState({
                      expand_terms: !this.state.expand_terms,
                    });
                  }}
                >
                  <span className="details-icon">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/terms.png"}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt=""
                    />
                  </span>
                  <span className="details-title">
                    {i18n.t("terms_conditions")}
                  </span>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_terms
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                    />
                  </span>
                </a>

                {this.state.expand_terms && (
                  <div className="card active card-details">
                    <div
                      className="col-xl-12 col-md-12"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {lng === "ar" ? auction.terms_arabic : auction.terms}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
