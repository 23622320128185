import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import i18n from "../../i18n";
import Chart from "react-apexcharts";
import { getAuctionChartData, getSalesChartData } from "../../models/auctions";
import { getUserChartData } from "../../models/users";
import { getDashboardData } from "../../models/dashboard";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import { DashboardIcons } from "./helper/dashboard_icons";
import { DashboardCounts } from "./helper/dahboard_counts";

const user = getLoggedUser();

export class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      first_panel: true,
      second_panel: true,
      third_panel: false,
      forth_panel: false,
      input: {},
      is_loading_1: false,
      is_loading_2: false,
      is_loading_3: false,
      is_loading_4: false,
      main_auctions: [],
      charity_auctions: [],
      auctions: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: ["Active", "Upcoming", "Previous", "Featured", "Direct"],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      charity: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "100%",
              },
            },
          },
          labels: ["Active", "Upcoming", "Previous", "Featured", "Direct"],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      userData: "monthly",
      daily: {},
      monthly: {},
      salesData: "monthly",
      yearlySales: {},
      monthlySales: {},
      active_auctions: 0,
      active_bidders: 0,
      total_bidders: 0,
      total_bids: 0,
      maximum_bid_amount: 0,
      total_amount: 0,
      auction_in_bidding: 0,
      dd_loading: false,
      group: false,
      is_side_bar: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadDashboardData = this.loadDashboardData.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAuctionsData();
    this.loadUserChartData();
    this.loadSalesChartData();
    this.loadDashboardData();
    if (user.role === "A" || user.role === "M") {
      setInterval(this.loadDashboardData, 10000);
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadDashboardData(data = false) {
    this.setState({ dd_loading: true });
    let { group, auction } = data;
    let input = this.state.input;
    input.group = typeof group !== "undefined" ? group : input.group;
    input.auction = typeof auction !== "undefined" ? auction : input.auction;
    if (typeof input.group === "undefined") {
      input.group = "";
      this.setState({ dd_loading: false, input });
      //alert("undefined");
      //return;
      console.log(this.state.input);
    }
    let response = await getDashboardData(input);
    if (response.success) {
      let { data } = response;
      this.setState({
        active_auctions: data.active_auctions,
        active_bidders: data.active_bidders,
        total_bidders: data.total_bidders,
        maximum_bid_amount: data.maximum_bid_amount,
        total_amount: data.total_amount,
        auction_in_bidding: data.auction_in_bidding,
        total_bids: data.total_bids,
      });
      this.setState({ dd_loading: false });
    }
  }

  async loadAuctionsData() {
    this.setState({ is_loading_1: true });
    let auctions = this.state.auctions;
    const response = await getAuctionChartData("main");
    let data = response.data;
    if (response) {
      auctions.series = [
        data.active,
        data.upcoming,
        data.previous,
        data.featured,
      ];
      this.setState({
        is_loading_1: false,
        auctions,
        main_auctions: auctions.series,
      });
    }
  }

  async loadUserChartData() {
    this.setState({ is_loading_3: true });
    let daily = this.state.daily;
    let monthly = this.state.monthly;
    const response = await getUserChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_3: false });
      daily.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.dates,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      daily.series = [
        {
          name: "Total Users",
          data: data.values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Users",
          data: data.month_values,
        },
      ];

      this.setState({ daily, monthly });
    }
    //console.log(this.state.users);
  }

  async loadSalesChartData() {
    this.setState({ is_loading_4: true });
    let yearly = this.state.yearlySales;
    let monthly = this.state.monthlySales;
    const response = await getSalesChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_4: false });
      yearly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.years,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      yearly.series = [
        {
          name: "Total Sales",
          data: data.year_values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Sales",
          data: data.month_values,
        },
      ];

      this.setState({ yearlySales: yearly, monthlySales: monthly });
    }
    //console.log(this.state.users);
  }

  hideSideBar(is_side_bar) {
    this.setState({
      is_side_bar: is_side_bar,
    });
  }

  render() {
    let userDailyOptions = this.state.daily.options;
    let userDailySeries = this.state.daily.series;
    let userMonthlyOptions = this.state.monthly.options;
    let userMonthlySeries = this.state.monthly.series;

    let salesMonthlyOptions = this.state.monthlySales.options;
    let salesMonthlySeries = this.state.monthlySales.series;
    let salesYearlyOptions = this.state.yearlySales.options;
    let salesYearlySeries = this.state.yearlySales.series;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.is_side_bar && <SideBar />}
              </div>
              <div
                className={
                  this.state.is_side_bar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <DashboardCounts data={this.state.main_auctions} />

                {(user.role === "A" || user.role === "M") && (
                  <DashboardIcons
                    active_auctions={this.state.active_auctions}
                    active_bidders={this.state.active_bidders}
                    total_bidders={this.state.total_bidders}
                    maximum_bid_amount={this.state.maximum_bid_amount}
                    total_amount={this.state.total_amount}
                    auction_in_bidding={this.state.auction_in_bidding}
                    total_bids={this.state.total_bids}
                    dd_loading={this.state.dd_loading}
                    is_side_bar={this.state.is_side_bar}
                    loadByGroup={this.loadDashboardData}
                    hideSideBar={this.hideSideBar}
                  />
                )}

                <div className="row">
                  {/* <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div
                        className="card-header d-flex justify-content-between"
                        onClick={() => {
                          this.setState({
                            first_panel: !this.state.first_panel,
                          });
                        }}
                        role="button"
                      >
                        <h3 className="card-title">{i18n.t("auctions")}</h3>
                        <span>
                          <i
                            className={
                              !this.state.first_panel
                                ? "fa fa-chevron-down"
                                : "fa fa-chevron-up"
                            }
                          />
                        </span>
                      </div>
                      <div
                        className={
                          this.state.first_panel
                            ? "card-body text-center"
                            : "card-body text-center d-none"
                        }
                      >
                        {this.state.is_loading_1 && (
                          <Spinner animation="border" size="lg" />
                        )}
                        {!this.state.is_loading_1 && (
                          <div>
                            <Chart
                              options={this.state.auctions.options}
                              series={this.state.auctions.series}
                              type="donut"
                              height={320}
                            />

                            <div>
                              <a
                                href="auctions_list?type=active&section=main"
                                className="btn btn-sm"
                                style={{ background: "#008ffb", color: "#FFF" }}
                              >
                                {this.state.main_auctions[0]}
                              </a>
                              &nbsp;
                              <a
                                href="auctions_list?type=upcoming&section=main"
                                className="btn btn-sm"
                                style={{ background: "#00e396", color: "#FFF" }}
                              >
                                {this.state.main_auctions[1]}
                              </a>
                              &nbsp;
                              <a
                                href="auctions_list?type=previous&section=main"
                                className="btn btn-sm"
                                style={{ background: "#fdb01a", color: "#FFF" }}
                              >
                                {this.state.main_auctions[2]}
                              </a>
                              &nbsp;
                              <a
                                href="auctions_list?type=featured&section=main"
                                className="btn btn-sm"
                                style={{ background: "#ff4560", color: "#FFF" }}
                              >
                                {this.state.main_auctions[3]}
                              </a>
                              &nbsp;
                              <a
                                href="auctions_list?type=direct&section=main"
                                className="btn btn-sm"
                                style={{ background: "#765dcf", color: "#FFF" }}
                              >
                                {this.state.main_auctions[4]}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {(user.role === "A" || user.role === "M") && (
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="card">
                        <div
                          className="card-header d-flex justify-content-between"
                          onClick={() => {
                            this.setState({
                              third_panel: !this.state.third_panel,
                            });
                          }}
                          role="button"
                        >
                          <h3 className="card-title ">
                            Sales
                            {this.state.salesData === "monthly" && (
                              <span>(Last 12 months)</span>
                            )}
                            {this.state.salesData === "yearly" && (
                              <span>(Last 5 years)</span>
                            )}
                          </h3>
                          <span>
                            <i
                              className={
                                !this.state.third_panel
                                  ? "fa fa-chevron-down"
                                  : "fa fa-chevron-up"
                              }
                            />
                          </span>
                        </div>

                        <div
                          className={
                            this.state.third_panel
                              ? "card-body text-center"
                              : "card-body text-center d-none"
                          }
                        >
                          <div className="btn-group" role="group">
                            <button
                              type="button"
                              className={
                                this.state.salesData === "monthly"
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              onClick={() => {
                                this.setState({
                                  salesData: "monthly",
                                });
                              }}
                            >
                              Monthly
                            </button>
                            <button
                              type="button"
                              className={
                                this.state.salesData === "daily"
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              onClick={() => {
                                this.setState({
                                  salesData: "yearly",
                                });
                              }}
                            >
                              Yearly
                            </button>
                          </div>

                          {this.state.is_loading_4 && (
                            <div className="m-5">
                              <Spinner animation="border" size="lg" />
                            </div>
                          )}

                          {!this.state.is_loading_4 &&
                            this.state.salesData === "monthly" &&
                            typeof salesMonthlyOptions !== "undefined" &&
                            typeof salesMonthlySeries !== "undefined" && (
                              <Chart
                                options={salesMonthlyOptions}
                                series={salesMonthlySeries}
                                type="bar"
                                height={320}
                              />
                            )}

                          {!this.state.is_loading_4 &&
                            this.state.salesData === "yearly" &&
                            typeof salesYearlyOptions !== "undefined" &&
                            typeof salesYearlySeries !== "undefined" && (
                              <Chart
                                options={salesYearlyOptions}
                                series={salesYearlySeries}
                                type="bar"
                                height={320}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  {(user.role === "A" || user.role === "M") && (
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="card">
                        <div
                          className="card-header d-flex justify-content-between"
                          onClick={() => {
                            this.setState({
                              forth_panel: !this.state.forth_panel,
                            });
                          }}
                          role="button"
                        >
                          <h3 className="card-title">
                            Users Registered
                            {this.state.userData === "daily" && (
                              <span>(Last 30 days)</span>
                            )}
                            {this.state.userData === "monthly" && (
                              <span>(Last 12 months)</span>
                            )}
                          </h3>

                          <span>
                            <i
                              className={
                                !this.state.forth_panel
                                  ? "fa fa-chevron-down"
                                  : "fa fa-chevron-up"
                              }
                            />
                          </span>
                        </div>

                        <div
                          className={
                            this.state.forth_panel
                              ? "card-body text-center"
                              : "card-body text-center d-none"
                          }
                        >
                          <div className="btn-group" role="group">
                            <button
                              type="button"
                              className={
                                this.state.userData === "monthly"
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              onClick={() => {
                                this.setState({
                                  userData: "monthly",
                                });
                              }}
                            >
                              Monthly
                            </button>
                            <button
                              type="button"
                              className={
                                this.state.userData === "daily"
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              onClick={() => {
                                this.setState({
                                  userData: "daily",
                                });
                              }}
                            >
                              Daily
                            </button>
                          </div>

                          {this.state.is_loading_3 && (
                            <div className="m-5">
                              <Spinner animation="border" size="lg" />
                            </div>
                          )}

                          {!this.state.is_loading_3 &&
                            this.state.userData === "daily" &&
                            typeof userDailyOptions !== "undefined" &&
                            typeof userDailySeries !== "undefined" && (
                              <Chart
                                options={userDailyOptions}
                                series={userDailySeries}
                                type="bar"
                                height={320}
                              />
                            )}

                          {!this.state.is_loading_3 &&
                            this.state.userData === "monthly" &&
                            typeof userMonthlyOptions !== "undefined" &&
                            typeof userMonthlySeries !== "undefined" && (
                              <Chart
                                options={userMonthlyOptions}
                                series={userMonthlySeries}
                                type="bar"
                                height={320}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
