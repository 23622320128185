import React from "react";
import { Link } from "react-router-dom";
//import { SocialSignin } from "./socialSignin";
import i18n from "../../i18n";
import contryData from "../../constants/country.json";
import { otpLogin, validateOtp } from "../../models/login";
//import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

const codeData = [...contryData];
export class LoginOtp extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        country: "+968",
      },
      is_otp_sent: false,
      t_c_acept: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.validateOtp = this.validateOtp.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  async sendOtp() {
    const response = await otpLogin(this.state.input);
    if (response.success) {
      this.setState({ is_otp_sent: true });
    } else {
      swal(response.message, response.data, "error");
    }
  }

  async validateOtp() {
    const response = await validateOtp(this.state.input);
    if (!response.success) {
      swal(response.message, response.data, "error");
      return;
    }
    if ("token" in response.data) {
      swal("Success", i18n.t("user_signed_in"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal(response.message, response.data, "error");
    }
  }

  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1
                    className="auction-title-cust auction-title-cust-1"
                    style={{ width: "40%" }}
                  >
                    {i18n.t("login")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb background-custom">
          <div className="container customerpage">
            <div className="row">
              <div className="single-page">
                <div className="col-lg-5 col-xl-5 col-md-6 d-block mx-auto">
                  <div className="wrapper wrapper2">
                    <form id="login" className="card-body">
                      <h3>{i18n.t("login_using_otp")}</h3>

                      {!this.state.is_otp_sent && (
                        <div>
                          <div className="input-group input-group-sm mt-2">
                            {/* <select
                              className="form-control"
                              data-placeholder="Select Country"
                              style={{
                                height: "50px",
                                padding: "5px 15px",
                                border: "solid 1px #d8dde6",
                                zIndex: 0,
                              }}
                              name="country"
                              value={this.state.input.country}
                              onChange={this.handleChange}
                            >
                              <option value="">--Country--</option>
                              {codeData.map((item, i) => {
                                return (
                                  <option value={item.dial_code}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select> */}
                            <span className="form-control country-flag-field">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/flags/ar.svg"
                                }
                                alt=""
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control col-9"
                              style={{ zIndex: 0 }}
                              name="phone"
                              value={this.state.input.phone}
                              onChange={this.handleChange}
                            />
                            <label>{i18n.t("phone_number")}</label>
                            <div className="text-danger">
                              {this.state.errors.mobile}
                            </div>
                          </div>
                          <hr />
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={this.sendOtp}
                            >
                              {i18n.t("send")}
                            </button>
                          </div>
                        </div>
                      )}

                      {this.state.is_otp_sent && (
                        <div>
                          <div className="passwd">
                            <input
                              type="text"
                              name="otp"
                              value={this.state.input.otp}
                              onChange={this.handleChange}
                            />
                            <label>{i18n.t("enter_otp")}</label>
                          </div>
                          <hr />
                          <div className="submit">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={this.validateOtp}
                            >
                              {i18n.t("verify_login")}
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="submit">
                        <Link to="/login" className="text-primary ms-1">
                          {i18n.t("login_using_email_user")}
                        </Link>
                      </div>

                      <p className="mb-2">
                        <Link to="/forgotPassword" className="text-info ms-1">
                          {i18n.t("forget_password")}
                        </Link>
                      </p>
                      <p className="text-dark mb-0">
                        {i18n.t("donthaveaccount")}
                        <Link to="/registration" className="text-dark ms-1">
                          {i18n.t("registration")}
                        </Link>
                      </p>
                    </form>
                    {/* <SocialSignin /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
