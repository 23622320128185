import React from "react";
import i18n from "../../i18n";
import GoogleSignin from "./googleSignin";
import { FacebookSignin } from "./facebookSignin";
export class SocialSignin extends React.Component {
  googleResponseSuccess = (response) => {
    console.log("success", response);
  };

  googleResponseError = (response) => {
    console.log("error", response);
  };

  render() {
    return (
      <React.Fragment>
        {/* <hr className="divider" />
        <div className="card-body social-images">
          <p className="text-body text-left">
            {i18n.t("social_account_signin")}
          </p>
          <div className="row">
            <div className="col-6">
              <FacebookSignin buttonText={i18n.t("facebook_login")} />
            </div>
            <div className="col-6">
              <GoogleSignin buttonText={i18n.t("google_login")} />
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}
