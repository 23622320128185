import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  getAllMainGroups,
  getGroup,
  deleteGroup,
  updateGroup,
} from "../../models/groups";
export class EditGroup extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let groupId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      groupId: groupId,
      input: {
        is_grouped_enroll: false,
        is_auctions_grouped: false,
        is_extra_time: false,
        extra_time: 0,
        is_auction_update_needed: false,
      },
      errors: {},
      main_groups: [],
      groups: [],
      collapse: true,
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      file_group_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleGrouped = this.toggleGrouped.bind(this);
    this.toggleEnrollGrouped = this.toggleEnrollGrouped.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleAuctionUpdate = this.toggleAuctionUpdate.bind(this);
  }

  componentDidMount() {
    this.loadAllMainGroups();
    this.loadGroup();
  }

  async loadAllMainGroups() {
    const response = await getAllMainGroups();
    let main_groups = response.data;
    this.setState({ main_groups });
  }

  async loadGroup() {
    const response = await getGroup(this.state.groupId);
    let group = response.data;
    group.is_auction_update_needed = false;
    let enquiries =
      group && group.enquiry ? group.enquiry : this.state.enquiries;
    this.setState({ input: group, enquiries });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    console.log(event.target.name + ", " + event.target.value);
    this.setState({ input });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({ input });
  };

  toggleStaffAuction = () => {
    let input = this.state.input;
    input.is_staff_auction = !this.state.input.is_staff_auction;
    this.setState({
      input,
    });
  };

  toggleGrouped = () => {
    let input = this.state.input;
    input.is_auctions_grouped = !this.state.input.is_auctions_grouped;
    this.setState({
      input,
    });
  };

  toggleAuctionUpdate = () => {
    let input = this.state.input;
    input.is_auction_update_needed = !this.state.input.is_auction_update_needed;
    this.setState({
      input,
    });
  };

  toggleEnrollGrouped = () => {
    let input = this.state.input;
    input.is_grouped_enroll = !this.state.input.is_grouped_enroll;
    this.setState({
      input,
    });
  };

  onFileChange = (event) => {
    if (event.target.name === "file_group_image") {
      this.setState({ file_group_image: event.target.files[0] });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.input.main_group) {
      swal("Failed", "Please select main group", "error");
    }
    try {
      const formData = new FormData();

      formData.append("group_name", this.state.input.group_name);
      formData.append("group_name_ar", this.state.input.group_name_ar);
      formData.append("description", this.state.input.description);
      formData.append("description_ar", this.state.input.description_ar);
      formData.append("start_date", this.state.input.start_date);
      formData.append("end_date", this.state.input.end_date);
      formData.append("reg_start_date", this.state.input.reg_start_date);
      formData.append("reg_end_date", this.state.input.reg_end_date);
      formData.append(
        "is_auctions_grouped",
        this.state.input.is_auctions_grouped
      );
      formData.append("is_staff_auction", this.state.input.is_staff_auction);
      formData.append("is_grouped_enroll", this.state.input.is_grouped_enroll);
      formData.append("terms", this.state.input.terms);
      formData.append("terms_arabic", this.state.input.terms_arabic);
      formData.append("amount", this.state.input.amount);
      formData.append("vat", this.state.input.vat);
      formData.append("service_charge", this.state.input.service_charge);
      formData.append("payment_type", this.state.input.payment_type);
      formData.append("enquiries", JSON.stringify(this.state.enquiries));
      formData.append("is_extra_time", this.state.input.is_extra_time);
      formData.append("extra_time", this.state.input.extra_time);
      formData.append(
        "is_auction_update_needed",
        this.state.input.is_auction_update_needed
      );
      formData.append("main_group", this.state.input.main_group);
      if (this.state.file_group_image) {
        formData.append("file_group_image", this.state.file_group_image);
      }
      formData.append("_method", "patch");
      const response = await updateGroup(formData, this.state.groupId);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });

        if (response.data) {
          let input = response.data;
          console.log(input);
          this.setState({ input });
        }
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow() {
    const response = deleteGroup(this.state.groupId);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        window.location.href = "/groups";
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries;
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let main_groups = this.state.main_groups;
    let enquiries = this.state.enquiries;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Groups
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="">
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">
                        {i18n.t("edit")} {i18n.t("group")}
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("main_group")}{" "}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="main_group"
                              value={this.state.input.main_group}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              {main_groups.length > 0 &&
                                main_groups.map((item, i) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("name")}
                            </label>
                            <input
                              type="text"
                              name="group_name"
                              className="form-control"
                              placeholder="Group Name"
                              value={this.state.input.group_name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("name")} (Ar)
                            </label>
                            <input
                              type="text"
                              style={{ direction: "rtl" }}
                              name="group_name_ar"
                              className="form-control"
                              placeholder="Group Name"
                              value={this.state.input.group_name_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("start_date")} (
                              {this.state.input.start_date})
                            </label>
                            <input
                              type="datetime-local"
                              name="start_date"
                              className="form-control"
                              placeholder="Start Date"
                              value={this.state.input.start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("end_date")} ({this.state.input.end_date})
                            </label>
                            <input
                              type="datetime-local"
                              name="end_date"
                              className="form-control"
                              placeholder="End Date"
                              value={this.state.input.end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("reg_start_date")}
                            </label>
                            <input
                              type="datetime-local"
                              name="reg_start_date"
                              className="form-control"
                              placeholder="Reg Start Date"
                              value={this.state.input.reg_start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("reg_end_date")}
                            </label>
                            <input
                              type="datetime-local"
                              name="reg_end_date"
                              className="form-control"
                              placeholder="Reg. End Date"
                              value={this.state.input.reg_end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_auctions_grouped"
                              type="checkbox"
                              checked={this.state.input.is_auctions_grouped}
                              onChange={this.toggleGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Auctions Grouped.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_grouped_enroll"
                              type="checkbox"
                              checked={this.state.input.is_grouped_enroll}
                              onChange={this.toggleEnrollGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Grouped Enroll.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_staff_auction"
                              type="checkbox"
                              checked={this.state.input.is_staff_auction}
                              onChange={this.toggleStaffAuction}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Staff Auction
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-3 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              <input
                                name="is_extra_time"
                                type="checkbox"
                                checked={this.state.input.is_extra_time}
                                value={this.state.input.is_extra_time}
                                onChange={this.toggleChange}
                              />
                              &nbsp;
                              {i18n.t("extra_time")}
                            </label>

                            <input
                              name="extra_time"
                              type="number"
                              className="form-control text-center"
                              onChange={this.handleChange}
                              placeholder={i18n.t("in_minutes")}
                              value={this.state.input.extra_time}
                              disabled={!this.state.input.is_extra_time}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              {i18n.t("vat")}
                            </label>
                            <input
                              type="text"
                              name="vat"
                              className="form-control"
                              placeholder={i18n.t("vat")}
                              value={this.state.input.vat}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              {i18n.t("Service Charge")}
                            </label>
                            <input
                              type="text"
                              name="service_charge"
                              className="form-control"
                              placeholder={i18n.t("service_charge")}
                              value={this.state.input.service_charge}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              {i18n.t("enroll_amount")}
                            </label>
                            <input
                              type="text"
                              name="amount"
                              className="form-control"
                              placeholder={i18n.t("enroll_amount")}
                              value={this.state.input.amount}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("pay_type")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="payment_type"
                              value={this.state.input.payment_type}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Online">Online</option>
                              <option value="Offline">Offline</option>
                              <option value="Both">
                                Supports both Online & Offline
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-3 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              Upload new image
                            </label>
                            <input
                              className="form-control "
                              type="file"
                              placeholder=""
                              name="file_group_image"
                              onChange={this.onFileChange}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              {/* (Image must be of size 168(w) X 84(h)) */}
                            </small>
                          </div>
                        </div>

                        <div className="col-sm-1 col-md-1 text-center">
                          <div className="form-group">
                            <img
                              src={this.state.input.image}
                              alt=""
                              className="mt-5"
                              style={{ height: "35px", width: "auto" }}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")}
                            </label>
                            <textarea
                              className="form-control"
                              name="description"
                              value={this.state.input.description}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")} ({i18n.t("arabic")})
                            </label>
                            <textarea
                              className="form-control"
                              name="description_ar"
                              value={this.state.input.description_ar}
                              onChange={this.handleChange}
                              rows={5}
                              style={{ direction: "rtl" }}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")}
                            </label>
                            <textarea
                              className="form-control"
                              name="terms"
                              value={this.state.input.terms}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")} (Arabic)
                            </label>
                            <textarea
                              style={{ direction: "rtl" }}
                              className="form-control"
                              name="terms_arabic"
                              value={this.state.input.terms_arabic}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <h5>{i18n.t("enquiry")}</h5>
                          {enquiries.length > 0 &&
                            enquiries.map((item, i) => {
                              return (
                                <div className="row" key={i}>
                                  <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Label
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonLabel"
                                        className="form-control"
                                        placeholder="Label"
                                        value={item.label || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "label",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Value
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonValue"
                                        className="form-control"
                                        placeholder="Value"
                                        value={item.value || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "value",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Label(Arabic)
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonLabel"
                                        className="form-control"
                                        style={{ direction: "rtl" }}
                                        placeholder="Label(Arabic)"
                                        value={item.label_ar || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "label_ar",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Value(Arabic)
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonValue"
                                        className="form-control"
                                        style={{ direction: "rtl" }}
                                        placeholder="Value"
                                        value={item.value_ar || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "value_ar",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {i > 0 && (
                                    <div className="col-sm-2 col-md-2">
                                      <div className="form-group">
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          key={i}
                                          onClick={(event) => {
                                            this.removeEnquiryData(i);
                                          }}
                                        >
                                          <i className="fa fa-minus"></i>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={this.appendData}
                              style={{ marginRight: "20px" }}
                            >
                              <i className="fa fa-plus"></i>
                              <span>Add New</span>
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <input
                                name="is_auction_update_needed"
                                type="checkbox"
                                checked={
                                  this.state.input.is_auction_update_needed
                                }
                                onChange={this.toggleAuctionUpdate}
                                style={{ float: "left", margin: "4px" }}
                              />
                              <label
                                className="form-label"
                                style={{ float: "left", marginRight: "1rem" }}
                              >
                                Is Auctions needs to update.?
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <button type="submit" className="btn btn-primary">
                                Update Group
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
