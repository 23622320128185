import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { forgotPassword } from "../../models/login";
import swal from "sweetalert";
export class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      sending: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendForgetPasswordEmail = this.sendForgetPasswordEmail.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async sendForgetPasswordEmail() {
    this.setState({ sending: true });
    const response = await forgotPassword({ userId: this.state.input.userId });
    if (response.success) {
      this.setState({ sending: false });
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 10000,
      })
        .then((value) => {})
        .catch((response) => {
          this.setState({ sending: false });
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ sending: false });
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1
                    className="auction-title-cust auction-title-cust-1"
                    style={{ width: "40%" }}
                  >
                    {i18n.t("forget_password")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb background-custom">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div className="single-page w-100 p-0">
                  <div className="wrapper wrapper2">
                    <form id="forgotpsd" className="card-body">
                      <h3 className="pb-2">{i18n.t("forget_password")}</h3>
                      <div className="mail">
                        <input
                          type="email"
                          name="userId"
                          value={this.state.input.userId}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("username_or_email")}</label>
                      </div>
                      <div className="submit">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          disabled={!this.state.input.userId}
                          onClick={this.sendForgetPasswordEmail}
                        >
                          {this.state.sending && i18n.t("sending")}
                          {!this.state.sending && i18n.t("send")}
                        </button>
                      </div>
                      <div className="text-center text-dark mb-0">
                        <Link to="/login">{i18n.t("forgetit")}</Link>.
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
