import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import { getAllData, createData, deleteData } from "../../models/blacklist";
import i18n from "../../i18n";
export class Blacklist extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      blacklisted_items: [],
      file_category_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllData();
  }

  async loadAllData() {
    const response = await getAllData();
    let blacklisted_items = response.data;
    this.setState({ blacklisted_items });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_category_image") {
      this.setState({ file_category_image: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.state.input.phone) {
      swal("Failed", "Please enter a phone number.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("phone", this.state.input.phone);
    const response = await createData(formData);
    if (response.success) {
      this.loadAllData();
    } else {
      swal("Failed", response.message, "error");
    }
  }

  async deleteRow(id) {
    const response = await deleteData(id);
    if (response.success) {
      swal("Success", "Item deleted", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          this.loadAllData();
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  render() {
    let blacklisted_items = this.state.blacklisted_items;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("blacklist")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">Add to blacklist</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}
                          </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder={i18n.t("phone_number")}
                            value={this.state.input.phone}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm btn-block p-2"
                          >
                            Add to blacklist
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Black listed Numbers</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {blacklisted_items.length < 1 && (
                        <div>No data found!!!</div>
                      )}
                      {blacklisted_items.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{i18n.t("phone_number")}</th>
                              {/* <th>Edit</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {blacklisted_items.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{item.phone}</td>
                                  {/* <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_blacklist/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      Edit
                                    </Link>
                                  </td> */}
                                  <td>
                                    <button
                                      className="btn btn-danger btn-sm text-white m-1"
                                      onClick={(e) => {
                                        this.deleteRow(item.id);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>&nbsp;
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
