import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";
//import { getLoggedUser } from "../../../state/user";
import { toggleLike, toggleWishlist } from "../../../models/auctions";
import { AuctionShare } from "./auctionShare";

//const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
export class AuctionCardHorz extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      wishlist: false,
      like: false,
      is_active_light_box: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.addWishList = this.addWishList.bind(this);
    this.addLike = this.addLike.bind(this);
  }

  componentDidMount() {
    this.setState({ like: this.props.auction.auction_liked });
    this.setState({ wishlist: this.props.auction.auction_wishlisted });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }
  async addWishList(wishlist) {
    if (is_logged) {
      const response = await toggleWishlist({
        auction_id: this.props.auctionId,
        wishlist,
      });
      if (response) {
        this.setState({ wishlist: wishlist });
      }
    }
  }

  async addLike(like) {
    if (is_logged) {
      const response = await toggleLike({
        auction_id: this.props.auctionId,
        like,
      });
      if (response) {
        this.setState({ like: like });
      }
    }
  }

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let auction = this.props.auction;
    let images = this.props.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    if (images.length > 0) {
      img_src = images[0].image;
    }
    let key = this.props.key;

    let amount_label = "";
    if (this.props.type === "upcoming") {
      amount_label = i18n.t("start_bid_amount");
    } else if (this.props.type === "previous") {
      amount_label = i18n.t("last_bid_amount");
    } else {
      amount_label = i18n.t("current_amount");
    }

    return (
      <React.Fragment key={this.props.key}>
        <div className="card overflow-hidden" key={this.props.key}>
          <div className="d-md-flex">
            <div className="item-card9-img">
              <div className="item-card9-imgs">
                <img
                  src={img_src}
                  alt="img"
                  className="cover-image"
                  onClick={() => {
                    this.props.getImagePopup(true, images);
                  }}
                />
              </div>
              <div className="item-overly-trans">
                <span>
                  <a href={true} className="bg-primary 2minute">
                    {amount_label} : {current_lng === "ar" ? "" : i18n.t("omr")}{" "}
                    {this.props.curr_amount}{" "}
                    {current_lng === "ar" ? i18n.t("omr") : ""}
                  </a>
                </span>
              </div>
            </div>
            <div className="card border-0 mb-0">
              <div className="card-body ">
                <div className="item-card9">
                  <Link
                    to={"/auction-details/" + this.props.auctionId}
                    className="text-dark"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {current_lng === "ar" ? auction.title_ar : auction.title}
                    </h4>
                  </Link>
                  <div className="item-card9-desc mb-2">
                    <a href={true} className="me-4">
                      <span className>
                        <i className="fa fa-map-marker text-muted me-1" /> OMAN
                      </span>
                    </a>
                    <a href={true} className="me-4 text-muted">
                      {i18n.t("bid_counr")}
                      {"#:"}
                      {auction.bid_count}
                    </a>

                    <a
                      href={true}
                      className="me-3 like-counter"
                      onClick={(event) => {
                        this.addWishList(!this.state.wishlist);
                      }}
                    >
                      <i
                        className={
                          this.state.wishlist ? "fa fa-heart" : "fa fa-heart-o"
                        }
                      ></i>
                    </a>
                    <a
                      href={true}
                      className="me-3 like-counter"
                      onClick={(event) => {
                        this.addLike(!this.state.like);
                      }}
                    >
                      <i
                        className={
                          this.state.like
                            ? "fa fa-thumbs-up"
                            : "fa fa-thumbs-o-up"
                        }
                      ></i>
                    </a>
                    <a href={true} className="like-counter me-3 fs-14">
                      <i className="fa fa-eye me-1" />
                      <span className="counter fs-14 mt-0">
                        {this.props.total_views}
                      </span>
                    </a>
                  </div>
                  <p className="mb-0 leading-tight">
                    <span
                      className={
                        this.props.status === "A" ? "text-red" : "text-muted"
                      }
                    >
                      <i className="fa fa-calendar-o me-1" />
                      {i18n.t("end_date")} : {this.props.end_date}
                    </span>
                    &nbsp;
                    <span
                      className={
                        this.props.status === "A" ? "text-green" : "text-muted"
                      }
                    >
                      <i className="fa fa-calendar-o me-1" />
                      {i18n.t("start_date")} : {this.props.start_date}
                    </span>
                  </p>
                </div>
              </div>
              <div className="card-footer pt-4 pb-4 pe-4 ps-4">
                <div className="item-card9-footer d-sm-flex">
                  <div className="item-card9-cost">
                    <h4 className="text-dark font-weight-bold mb-0 mt-0">
                      {current_lng === "ar" ? "" : i18n.t("omr") + " "}{" "}
                      {this.props.price + " "}
                      {current_lng === "ar" ? i18n.t("omr") + " " : ""}{" "}
                    </h4>
                  </div>
                  <div className="ms-auto d-flex align-items-baseline">
                    <Link
                      to={"/auction-details/" + this.props.auctionId}
                      className="btn btn-primary m-1 btn-block"
                    >
                      <i className="fa fa-info-circle"></i>&nbsp;
                      {i18n.t("view_details")}
                    </Link>
                    <div>
                      <AuctionShare auction={auction} />
                    </div>
                    {/* <a href={true} className="me-2" title="Kilometrs">
                      <i className="fa fa-road text-muted me-1 " />
                      4000Kms
                    </a>
                    <a href={true} className title="FuealType">
                      <i className="fa fa-tachometer text-muted me-1" />
                      Electric
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={current_lng}
          key={key}
        />
      </React.Fragment>
    );
  }
}
