import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import profilePic from "../../profilePic.png";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;

function logout() {
  localStorage.clear();
  window.location.href = "/";
}

export class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      report_menu_expand: true,
    };
  }

  render() {
    let lang = localStorage.getItem("I18N_LANGUAGE");
    return (
      <React.Fragment>
        {is_logged && (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{i18n.t("dashboard")}</h3>
            </div>
            <div className="card-body text-center item-user border-bottom">
              <div className="profile-pic">
                <div className="profile-pic-img">
                  <span
                    className="bg-success dots"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="online"
                  ></span>
                  {user.avatar && (
                    <img
                      src={user.avatar}
                      className="brround"
                      alt="user"
                      referrerPolicy="no-referrer"
                    />
                  )}

                  {!user.avatar && (
                    <img src={profilePic} className="brround" alt="user" />
                  )}
                </div>
                <a href="/profile" className="text-dark">
                  <h4 className="mt-3 mb-0 font-weight-semibold">
                    {user.name}
                  </h4>

                  <span class="text-muted">
                    {user.role === "A" && `(${i18n.t("administrator")})`}
                  </span>
                </a>
              </div>
            </div>
            <div className="item1-links  mb-0">
              <NavLink
                activeClassName="active"
                to="/dashboard"
                className="d-flex border-bottom"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-chart"></i>
                </span>{" "}
                {i18n.t("dashboard")}
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/profile"
                className="d-flex border-bottom"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-user"></i>
                </span>{" "}
                {i18n.t("edit_profile")}
              </NavLink>
              {user.role === "A" && (
                <div>
                  <NavLink
                    to="/live_activity"
                    activeClassName="active"
                    className="d-flex  border-bottom"
                    target="_blank"
                  >
                    <span className="icon1 me-3">
                      <i className="fa fa-desktop"></i>
                    </span>{" "}
                    {i18n.t("live_activity")}
                  </NavLink>

                  <NavLink
                    to="/users"
                    activeClassName="active"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="fa fa-users"></i>
                    </span>{" "}
                    {i18n.t("users")}
                  </NavLink>
                </div>
              )}

              {(user.role === "A" ||
                user.role === "C" ||
                user.role === "M") && (
                <div>
                  <NavLink
                    to="/staff_users"
                    activeClassName="active"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="fa fa-address-card"></i>
                    </span>{" "}
                    {i18n.t("staff_users")}
                  </NavLink>
                </div>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/main_groups"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-slack"></i>
                  </span>{" "}
                  {i18n.t("main_groups")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/groups"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-object-group"></i>
                  </span>{" "}
                  {i18n.t("groups")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/masks"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-layers"></i>
                  </span>{" "}
                  {i18n.t("masks")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/categories"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-list-alt"></i>
                  </span>{" "}
                  {i18n.t("categories")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/organizations"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-building"></i>
                  </span>{" "}
                  {i18n.t("organizations")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/banners"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-image"></i>
                  </span>{" "}
                  {i18n.t("banners")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/create_auction"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-plus"></i>
                  </span>{" "}
                  {i18n.t("create_auction")}
                </NavLink>
              )}
              {(user.role === "A" ||
                user.role === "C" ||
                user.role === "M") && (
                <NavLink
                  to="/auctions_list"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-diamond"></i>
                  </span>{" "}
                  {i18n.t("my_auctions")}
                </NavLink>
              )}
              {user.role === "U" && (
                <NavLink
                  activeClassName="active"
                  to="/my_auctions"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-folder-alt"></i>
                  </span>{" "}
                  {i18n.t("my_bids")}
                </NavLink>
              )}
              {user.role === "U" && (
                <NavLink
                  activeClassName="active"
                  to="/enrolled_auctions"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-folder-alt"></i>
                  </span>{" "}
                  {i18n.t("enrolled_auctions")}
                </NavLink>
              )}
              {user.role === "U" && (
                <NavLink
                  activeClassName="active"
                  to="/winning_bids"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-folder-alt"></i>
                  </span>{" "}
                  {i18n.t("winning_bids")}
                </NavLink>
              )}
              <NavLink
                activeClassName="active"
                to="/payments"
                className="d-flex  border-bottom"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-credit-card"></i>
                </span>{" "}
                {i18n.t("payment")}
              </NavLink>

              {/* {user.role === "A" && (
                <NavLink
                  activeClassName="active"
                  to="/refund"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-money"></i>
                  </span>{" "}
                  {i18n.t("Refund")}
                </NavLink>
              )} */}

              {(user.role === "A" ||
                user.role === "C" ||
                user.role === "M") && (
                <div>
                  <NavLink
                    activeClassName="active"
                    to="/enrolled_users"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="icon icon-credit-card"></i>
                    </span>{" "}
                    {i18n.t("enrolled_users")}
                  </NavLink>
                  <a
                    href={true}
                    className="d-flex  border-bottom"
                    onClick={() => {
                      this.setState({
                        report_menu_expand: !this.state.report_menu_expand,
                      });
                    }}
                  >
                    <span className="icon1 me-3">
                      <i
                        className={
                          this.state.report_menu_expand
                            ? "fa fa-chevron-up"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </span>
                    <span>{i18n.t("reports")}</span>
                    <span
                      className="icon1 me-3"
                      style={{
                        position: "absolute",
                        right: lang === "en" ? 0 : "auto",
                        left: lang === "ar" ? 0 : "auto",
                      }}
                    >
                      {/* <i
                        className={
                          this.state.report_menu_expand
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                      ></i> */}
                    </span>
                  </a>
                  <div
                    className={
                      this.state.report_menu_expand ? "m-3" : "d-none m-3"
                    }
                  >
                    {/* {
                      this.state.report_menu_expand ? "m-3" : "d-none m-3"
                    } */}
                    {/* <NavLink
                      activeClassName="active"
                      to="/auction_bid_report"
                      className="d-flex border-bottom text-danger"
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-file-o"></i>
                      </span>{" "}
                      {i18n.t("auction_bid_report")}
                    </NavLink> */}
                    <NavLink
                      to="/position_report"
                      activeClassName="active"
                      className="d-flex border-bottom text-danger"
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-tasks"></i>
                      </span>{" "}
                      {i18n.t("position_report")}
                    </NavLink>
                    <NavLink
                      activeClassName="active"
                      to="/winners"
                      className="d-flex border-bottom text-danger"
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-flag-o"></i>
                      </span>{" "}
                      {i18n.t("auction_winners")}
                    </NavLink>
                    {(user.role === "A" || user.role === "M") && (
                      <div>
                        <NavLink
                          to="/sales_report"
                          activeClassName="active"
                          className="d-flex border-bottom text-danger"
                        >
                          <span className="icon1 me-3">
                            <i className="fa fa-money"></i>
                          </span>{" "}
                          {i18n.t("sales_report")}
                        </NavLink>
                        <NavLink
                          to="/final_report"
                          activeClassName="active"
                          className="d-flex border-bottom text-danger"
                        >
                          <span className="icon1 me-3">
                            <i className="fa fa-hourglass-end"></i>
                          </span>{" "}
                          {i18n.t("final_report")}
                        </NavLink>
                      </div>
                    )}
                    <NavLink
                      to="/reports"
                      activeClassName="active"
                      className="d-flex border-bottom text-danger"
                    >
                      <span className="icon1 me-3">
                        <i className="icon icon-chart"></i>
                      </span>{" "}
                      {i18n.t("reports")}
                    </NavLink>
                  </div>
                </div>
              )}

              {(user.role === "A" || user.role === "M") && (
                <NavLink
                  to="/composer"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-bell-o"></i>
                  </span>{" "}
                  {i18n.t("composer")}
                </NavLink>
              )}

              {(user.role === "A" ||
                user.role === "C" ||
                user.role === "M") && (
                <NavLink
                  to="/blacklist"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-ban"></i>
                  </span>{" "}
                  {i18n.t("blacklist")}
                </NavLink>
              )}

              <a
                href={true}
                onClick={logout}
                style={{ cursor: "pointer" }}
                className="d-flex"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-power"></i>
                </span>{" "}
                {i18n.t("logout")}
              </a>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
