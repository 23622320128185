import React, { useEffect } from "react"; //useState
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import { googleLogin } from "../../models/users";
import swal from "sweetalert";
import i18n from "../../i18n";

//import i18n from "../../i18n";
const clientId =
  "476511993809-amon7vbi4ct6680ocgm04hbu856a5prc.apps.googleusercontent.com";

function GoogleSignin(props) {
  //const [profile, setProfile] = useState([]);
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: [
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
          "openid",
        ],
      });
    };
    gapi.load("client:auth2", initClient);
  });
  const onSuccess = async (credentialResponse) => {
    let { profileObj } = credentialResponse;
    let response = await googleLogin(profileObj);

    if ("token" in response.data) {
      swal("Success", i18n.t("user_signed_in"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  const onError = async (credentialResponse) => {
    console.log(credentialResponse);
  };

  let buttonText = "Google Login";
  if (props.buttonText) {
    buttonText = props.buttonText;
  }

  return (
    <React.Fragment>
      <GoogleLogin
        className="btn btn-white me-2 border px-2 btn-md btn-block text-left shadow-none"
        clientId={clientId}
        buttonText={buttonText}
        onSuccess={onSuccess}
        onFailure={onError}
        cookiePolicy={"single_host_origin"}
      />
    </React.Fragment>
  );
}
export default GoogleSignin;
