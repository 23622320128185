import React from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";
import { getLoggedUser } from "../../../state/user";
import swal from "sweetalert";

const is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
export class AuctionRowGrouped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_popup: false,
      bid_count: 0,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.updateRow = this.updateRow.bind(this);
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return this.props.auction.end_date_formatted;
    } else {
      let today = new Date();
      let start_date = new Date(this.createDate(this.props.auction.start_date));
      if (start_date.getTime() > today.getTime()) {
        return (
          <span
            style={{
              padding: "0rem 0.2rem",
              borderRadius: "0.5rem",
            }}
            className="text-light font_05_rem"
          >
            00 : 00 : 00 : 00
          </span>
        );
      }

      let warning =
        days === 0 && hours === 0 && minutes === 0 && seconds < 10
          ? true
          : false;
      return (
        <span
          style={{
            padding: "0rem 0.2rem",
            borderRadius: "0.5rem",
          }}
          className={
            warning ? "text-danger font_05_rem" : "text-light font_05_rem"
          }
        >
          {days}
          {i18n.t("D")} : {hours}
          {i18n.t("H")} : {minutes}
          {i18n.t("M")} : {seconds}
          {i18n.t("S")}
        </span>
      );
    }
  };

  componentDidMount() {
    this.setState({ bid_count: this.props.auction.bid_count });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }

  updateRow(data) {
    this.setState({ bid_count: data });
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let key = this.props.keys;
    let auction = this.props.auction;
    let images = auction.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    if (images.length > 0) {
      img_src = images[0].image;
    }

    let is_vehicle = false;
    if (auction && auction.categoryDetails) {
      let cat = auction.categoryDetails;
      is_vehicle = cat.category_name === "Numbers" ? true : false;
    }

    let now = this.state.now;
    let aucEndDate = auction.end_date;
    let auctionEndTime = new Date(this.createDate(aucEndDate)).getTime();

    let slno = key;
    if (lng === "ar") {
      slno = key.toLocaleString("ar-u-nu-arab");
    }
    return (
      <React.Fragment>
        <tr>
          <td className="text-center font-75rem text-center">{slno}</td>
          <td className="font-75rem text-center group--list-image-cont">
            <div className="p-1 bg-grey">
              <a
                href={true}
                onClick={() => {
                  if (is_logged && !user.is_staff && auction.is_staff_auction) {
                    swal(
                      i18n.t("un_authorized"),
                      i18n.t("non_staff_popup"),
                      "warning"
                    );
                    return;
                  }
                  window.location.href = "/auction-details/" + auction.id;
                }}
              >
                <picture>
                  <img
                    src={img_src}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </picture>
              </a>
              <p className="p-0 m-0 bg-dark" style={{ color: "#FFF" }}>
                <div
                  className={
                    is_vehicle
                      ? "registration-ui group--list-image-cont"
                      : "group--list-image-cont"
                  }
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {lng === "ar" ? auction.title_ar : auction.title}
                </div>
              </p>

              <div className="m-0 p-0">
                <p className="nowrap p-1 m-0 text-center bg-primary">
                  {auctionEndTime && (
                    <Countdown
                      date={auctionEndTime}
                      renderer={this.renderer}
                      now={now}
                      onComplete={() => {
                        this.setState({ show_end_time: true });
                      }}
                    />
                  )}
                </p>

                <div
                  className="d-flex flex-column p-0 bg-light"
                  style={{ fontSize: "0.8rem" }}
                >
                  <span className="font-weight-bold bg-white p-2">
                    {i18n.t("start_bid_amount")} :{" "}
                    {lng === "en" ? i18n.t("omr") : ""} {auction.start_amount}{" "}
                    {lng === "ar" ? i18n.t("omr") : ""}{" "}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td className="font-75rem font-weight-bold">
            {lng === "ar" && auction.location_ar
              ? auction.location_ar
              : auction.location}
          </td>
          <td className="font-75rem font-weight-bold">
            {lng === "en" ? i18n.t("omr") : ""} {auction.current_amount}{" "}
            {lng === "ar" ? i18n.t("omr") : ""}{" "}
          </td>
          <td className="font-75rem">{this.state.bid_count}</td>
          <td className="text-center nowrap">
            <button
              className="btn btn-warning m-1 btn-sm font-75rem"
              onClick={() => {
                if (is_logged && !user.is_staff && auction.is_staff_auction) {
                  swal(
                    i18n.t("un_authorized"),
                    i18n.t("non_staff_popup"),
                    "warning"
                  );
                  return;
                }
                window.location.href = "/auction-details/" + auction.id;
              }}
            >
              <i className="fa fa-eye"></i>
              &nbsp;
              <span className="d-none-mob">{i18n.t("details")}</span>
            </button>

            {auction.status === "A" && (
              <button
                className="btn btn-warning m-1 btn-sm font-75rem"
                onClick={() => {
                  if (is_logged && !user.is_staff && auction.is_staff_auction) {
                    swal(
                      i18n.t("un_authorized"),
                      i18n.t("non_staff_popup"),
                      "warning"
                    );
                    return;
                  }
                  this.setState({
                    open_popup: true,
                  });
                }}
              >
                <i className="fa fa-gavel"></i>
                &nbsp;
                <span className="d-none-mob">{i18n.t("bid_now")}</span>
              </button>
            )}
          </td>
        </tr>

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={lng}
          key={key}
          updateRow={this.updateRow}
        />
      </React.Fragment>
    );
  }
}
