import {apiUrl} from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();
async function updateUser(payload){
    return fetch(apiUrl+'profile', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' :'Bearer '+user.token
      },
      body: JSON.stringify(payload)
    })
    .then(data => data.json())
  }

  export default updateUser;