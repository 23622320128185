import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import {
  getAllOrganization,
  createOrganization,
  deleteOrganization,
} from "../../models/organization";

export class Organizations extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        is_client: true,
      },
      errors: {},
      organization: [],
      is_loading: false,
      file_organization_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.toggleClient = this.toggleClient.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllOrganization();
  }

  async getAllOrganization() {
    this.setState({ is_loading: true });
    const response = await getAllOrganization();
    let organization = response.data;
    if (organization) {
      this.setState({ organization });
      this.setState({ is_loading: false });
    }
  }

  toggleClient = () => {
    let input = this.state.input;
    input.is_client = !this.state.input.is_client;
    this.setState({
      input,
    });
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_organization_image") {
      this.setState({ file_organization_image: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    let { input } = this.state;
    console.log(input);
    if (
      !this.state.input.organization_name ||
      !this.state.input.organization_name_ar ||
      !this.state.input.description ||
      !this.state.input.description_ar ||
      !this.state.file_organization_image
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("organization_name", this.state.input.organization_name);
    formData.append(
      "organization_name_ar",
      this.state.input.organization_name_ar
    );
    formData.append("description", this.state.input.description);
    formData.append("description_ar", this.state.input.description_ar);
    let client = this.state.input.is_client ? 1 : 0;
    formData.append("is_client", client);
    if (
      client === 1 &&
      (!this.state.input.username ||
        !this.state.input.password ||
        !this.state.input.email ||
        !this.state.input.mobile)
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }
    formData.append("username", this.state.input.username);
    formData.append("password", this.state.input.password);
    formData.append("email", this.state.input.email);
    formData.append("mobile", this.state.input.mobile);

    formData.append(
      "file_organization_image",
      this.state.file_organization_image
    );

    this.setState({ is_payment_loading: true });
    const response = await createOrganization(formData);

    if (response.success) {
      swal("Success", "Organization added", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          //this.setState({ input: {} });
          this.getAllOrganization();
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  deleteRow(id) {
    const response = deleteOrganization(id);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.getAllOrganization();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    let organization = this.state.organization;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Organizations
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">Create organization</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Title</label>
                          <input
                            type="text"
                            name="organization_name"
                            className="form-control"
                            placeholder="Name"
                            value={this.state.input.organization_name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Title (Arabic)</label>
                          <input
                            type="text"
                            name="organization_name_ar"
                            className="form-control rtl-dir"
                            placeholder="Name"
                            value={this.state.input.organization_name_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            name="description"
                            className="form-control"
                            placeholder="Description"
                            value={this.state.input.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Description (Arabic)
                          </label>
                          <input
                            type="text"
                            name="description_ar"
                            className="form-control rtl-dir"
                            placeholder="Description"
                            value={this.state.input.description_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Upload organization image
                          </label>
                          <input
                            className="form-control "
                            type="file"
                            placeholder=""
                            name="file_organization_image"
                            onChange={this.onFileChange}
                          />
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >
                            (Image must be of size 200(w) X 200(h))
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <label
                            className="form-label"
                            style={{ float: "left", marginRight: "1rem" }}
                          >
                            Is Client? &nbsp;
                            <input
                              name="is_client"
                              type="checkbox"
                              checked={this.state.input.is_client}
                              onChange={this.toggleClient}
                              style={{ float: "left", margin: "4px" }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12 bg-light">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Username</label>
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="Username"
                                value={this.state.input.username}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Password</label>
                              <input
                                type="text"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                value={this.state.input.password}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                value={this.state.input.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Mobile</label>
                              <input
                                type="text"
                                name="mobile"
                                className="form-control"
                                placeholder="Mobile"
                                value={this.state.input.mobile}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button type="submit" className="btn btn-primary">
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Organizations</h3>
                  </div>
                  <div className="card-body">
                    <div
                      className="table-responsive"
                      style={{ overflow: "scroll", width: "100%" }}
                    >
                      {this.state.is_loading && (
                        <div
                          className="text-center"
                          style={{ textAlign: "center", padding: "5rem" }}
                        >
                          <Spinner animation="border" />
                        </div>
                      )}

                      {!this.state.is_loading && organization.length < 1 && (
                        <div>No organizations found!!!</div>
                      )}

                      {!this.state.is_loading && organization.length > 0 && (
                        <table
                          className="table table-bordered table-hover text-nowrap"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Title (Arabic)</th>
                              <th>Client</th>
                              <th>Description</th>
                              <th>Description (Arabic)</th>
                              <th>Image</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {organization.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.organization_name}</td>
                                  <td>{item.organization_name_ar}</td>
                                  <td>
                                    {item.is_client ? (
                                      <strong className="text-green">
                                        Yes
                                      </strong>
                                    ) : (
                                      <strong className="text-red">No</strong>
                                    )}
                                  </td>
                                  <td>{item.description}</td>
                                  <td>{item.description_ar}</td>
                                  <td>
                                    <img
                                      src={item.file_organization_image}
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_organization/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      Edit
                                    </Link>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-danger btn-sm text-white m-1"
                                      onClick={(e) => {
                                        this.deleteRow(item.id);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>&nbsp;
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
