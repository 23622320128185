import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  updateAuction,
  getAuction,
  uploadTempDownloads,
} from "../../models/auctions";
import swal from "sweetalert";
import i18n from "../../i18n";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";

export class Contracts extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);

    this.state = {
      auctionId: auctionId,
      input: {},
      errors: {},
      contracts: [{ label: "", label_ar: "", file: "" }],
      jsonLabel: "",
      jsonValue: "",
      is_loading: false,
      contractFile: null,
    };
    this.handleContractSubmit = this.handleContractSubmit.bind(this);

    this.appendData = this.appendData.bind(this);
    this.removeContractData = this.removeContractData.bind(this);
    this.setContractJson = this.setContractJson.bind(this);

    this.uploadTempDownloads = this.uploadTempDownloads.bind(this);
  }

  componentDidMount() {
    this.loadAuction();
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    let id = this.state.auctionId;
    const response = await getAuction(id);
    let auction = response.data;
    if (auction) {
      let downloads = auction.downloads;
      if (downloads && downloads.length) {
        this.setState({ contracts: downloads });
      }
      this.setState({ is_loading: false });
    }
  }

  onFileChange = (event, index) => {
    console.log(event);
    console.log(index);
    if (event.target.name === "file") {
      this.uploadTempDownloads(event.target.files[0], index);
    }
  };

  async uploadTempDownloads(file, index) {
    const formData = new FormData();
    formData.append("contractFile", file, file.name);
    console.log("formData", formData);
    const response = await uploadTempDownloads(formData);
    if (response.success) {
      let { contracts } = this.state;
      contracts[index].file = response.data;
      this.setState({ contracts });
    } else {
      swal(
        "Failed",
        "Some error occured!! Please make sure that you are uploading valid file.",
        "error"
      );
    }
  }

  handleContractSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.is_downloads_update = true;
      payload.downloads = this.state.contracts;
      let id = this.state.auctionId;
      const response = await updateAuction(payload, id);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = "Not found!!!";
        let error_messages = response.message;
        for (const key in error_messages) {
          if (Object.hasOwnProperty.call(error_messages, key)) {
            let element = error_messages[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let contracts = this.state.contracts || [];
    contracts.push({ label: "", value: "" });
    this.setState({ contracts: contracts });
  }

  removeContractData(key) {
    let contracts = this.state.contracts;
    delete contracts[key];
    this.setState({ contracts: contracts });
  }

  setContractJson(key, event, arrKey) {
    let contracts = this.state.contracts;
    contracts[arrKey][key] = event.target.value;
    this.setState({ contracts: contracts });
  }

  render() {
    let contracts = this.state.contracts;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleContractSubmit}
                >
                  <AuctionTabs activeTab="contracts" />

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <h5>{i18n.t("contracts")}</h5>

                        {this.state.is_loading && (
                          <div
                            style={{
                              width: "90%",
                              height: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                              textAlign: "center",
                              padding: "1rem",
                            }}
                          >
                            <Spinner animation="border" />
                          </div>
                        )}

                        {contracts &&
                          contracts.length > 0 &&
                          contracts.map((item, i) => {
                            return (
                              <div className="row" key={i}>
                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">Label</label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      placeholder="Label"
                                      value={item.label || ""}
                                      onChange={(event) => {
                                        this.setContractJson("label", event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Label(Arabic)
                                    </label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      style={{ direction: "rtl" }}
                                      placeholder="Label(Arabic)"
                                      value={item.label_ar || ""}
                                      onChange={(event) => {
                                        this.setContractJson(
                                          "label_ar",
                                          event,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-5 col-md-5">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Upload a file(Image/PDF/Doc)
                                    </label>

                                    <div className="d-flex">
                                      <div className="upload-btn-wrapper">
                                        <button className="upload-btn">
                                          Upload
                                        </button>
                                        <input
                                          type="file"
                                          name="file"
                                          className="form-control"
                                          placeholder="Value"
                                          onChange={(event) => {
                                            this.onFileChange(event, i);
                                          }}
                                        />
                                      </div>

                                      {item.file && (
                                        <a
                                          href={item.file}
                                          className="contract-download"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <i className="fa fa-download"></i>{" "}
                                          &nbsp; Download
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div className="col-sm-1 col-md-1">
                                    <div className="form-group">
                                      <label className="form-label">
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        key={i}
                                        onClick={(event) => {
                                          this.removeContractData(i);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div
                          className="form-group"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={this.appendData}
                            style={{ marginRight: "20px" }}
                          >
                            <i className="fa fa-plus"></i>
                            <span>Add New</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Contracts
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
