import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  getCategory,
  deleteCategory,
  updateCategory,
} from "../../models/categories";
export class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let categoryId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      categoryId: categoryId,
      input: {},
      errors: {},
      groups: [],
      collapse: true,
      file_category_image: null,
      category_image_new: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadSelectedCategory();
  }

  async loadSelectedCategory() {
    const response = await getCategory(this.state.categoryId);
    let category = response.data;
    this.setState({ input: category });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_category_image") {
      this.setState({ category_image_new: event.target.files[0] });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();

      formData.append("category_name", this.state.input.category_name);
      formData.append("category_name_ar", this.state.input.category_name_ar);
      formData.append("description", this.state.input.description);
      formData.append("description_ar", this.state.input.description_ar);
      if (this.state.category_image_new) {
        formData.append("file_category_image", this.state.category_image_new);
      }
      formData.append("_method", "patch");
      //let payload = this.state.input;
      const response = await updateCategory(formData, this.state.categoryId);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });

        if (response.data) {
          let input = response.data;
          console.log(input);
          this.setState({ input });
        }
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow() {
    const response = deleteCategory(this.state.categoryId);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        window.location.href = "/categories";
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Categories
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="">
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">Edit Categories</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label"> Title</label>
                            <input
                              type="text"
                              name="category_name"
                              className="form-control"
                              placeholder="Name"
                              value={this.state.input.category_name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Title (Arabic)</label>
                            <input
                              type="text"
                              name="category_name_ar"
                              className="form-control rtl-dir"
                              placeholder="Name"
                              value={this.state.input.category_name_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              name="description"
                              className="form-control"
                              placeholder="Description"
                              value={this.state.input.description}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Description (Arabic)
                            </label>
                            <input
                              type="text"
                              name="description_ar"
                              className="form-control rtl-dir"
                              placeholder="Description"
                              value={this.state.input.description_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Category Image</label>

                            <input
                              className="form-control "
                              type="file"
                              placeholder=""
                              name="file_category_image"
                              onChange={this.onFileChange}
                              value={this.state.file_category_image}
                            />
                            <img
                              src={this.state.input.file_category_image}
                              width="168"
                              height="84"
                              alt=""
                              style={{ marginTop: "1rem" }}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <hr />
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Update Category
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
