import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { resetPassword, checkValidResetUrl } from "../../models/login";
import swal from "sweetalert";
export class ResetPassword extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let params = path.split("/");
    let email = params[params.length - 2];
    let token = params[params.length - 1];

    this.state = {
      input: {},
      errors: {},
      email: email,
      token: token,
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.checkValidResetUrl = this.checkValidResetUrl.bind(this);
  }
  componentDidMount() {
    this.checkValidResetUrl();
  }

  async checkValidResetUrl() {
    const response = await checkValidResetUrl({
      email: this.state.email,
      token: this.state.token,
    });
    if (!response.success) {
      window.location = "/notfound";
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async resetPassword() {
    if (this.state.input.password !== this.state.input.c_password) {
      swal("Failed", "Password mismatch", "error");
      return;
    }

    const response = await resetPassword({
      password: this.state.input.password,
      email: this.state.email,
      token: this.state.token,
    });
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          window.location.href = "/login";
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1
                    className="auction-title-cust auction-title-cust-1"
                    style={{ width: "40%" }}
                  >
                    {i18n.t("reset_password")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb mb-8 background-custom">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div className="single-page w-100 p-0">
                  <div className="wrapper wrapper2">
                    <form id="forgotpsd" className="card-body">
                      <h3 className="pb-2">{i18n.t("forget_password")}</h3>
                      <div className="mail">
                        <input
                          type="text"
                          name="password"
                          value={this.state.input.password}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("password")}</label>
                      </div>
                      <div className="mail">
                        <input
                          type="text"
                          name="c_password"
                          value={this.state.input.c_password}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("confirm_password")}</label>
                      </div>
                      <div className="submit">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          disabled={
                            !this.state.input.password ||
                            !this.state.input.c_password
                          }
                          onClick={this.resetPassword}
                        >
                          {i18n.t("send")}
                        </button>
                      </div>
                      <div className="text-center text-dark mb-0">
                        Forget it, <Link to="/login">send me back </Link> to the{" "}
                        <Link to="/login">sign in</Link>.
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
