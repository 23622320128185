import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  getAuctions,
  deleteAuction,
  getAllBidders,
  statuses,
} from "../../models/auctions";
import { getAllCategories } from "../../models/categories";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
const user = getLoggedUser();
export class AuctionsList extends React.Component {
  constructor() {
    super();
    this.state = {
      is_loading: false,
      auctions: {},
      open_popup: false,
      categories: {},
      groups: {},
      input: {
        category: "",
        type: "",
        section: "main",
      },
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e60000",
            color: "#FFFFFF",
          },
        },
      },
      columns: [
        {
          id: 1,
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "50px",
          cell: (row) =>
            user.role === "A" ? (
              <a href={"/edit_auction/" + row.id}>{row.id}</a>
            ) : (
              <span>{row.id}</span>
            ),
        },
        {
          id: 2,
          name: "Edit",
          button: true,
          cell: (row) =>
            user.role === "A" ? (
              <a href={"/edit_auction/" + row.id} className="btn btn-info">
                Edit
              </a>
            ) : (
              <span>NA</span>
            ),
        },
        {
          id: 3,
          name: "Bid Count",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-success"
              disabled={row.bid_count > 0 ? false : true}
              onClick={(event) => {
                this.state.open_popup = true;
                this.openBidPopup(row.id);
              }}
            >
              {row.bid_count}
            </button>
          ),
        },
        {
          id: 4,
          name: i18n.t("title"),
          selector: "title",
          sortable: true,
        },
        {
          id: 5,
          name: "Status",
          selector: "status",
          sortable: true,
          cell: (row) => statuses[row.status],
        },
        {
          id: 6,
          name: i18n.t("price"),
          selector: "start_amount",
        },
        {
          id: 7,
          name: i18n.t("incr_amount"),
          selector: "bid_increment",
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("start_date"),
          selector: "start_date",
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("end_date"),
          selector: "end_date",
          wrap: true,
        },
        {
          id: 10,
          name: "Invoice(Ar)",
          button: true,
          cell: (row) =>
            row.winner_file && (
              <div>
                <a
                  className="btn btn-link btn-sm"
                  href={row.winner_file.link_ar}
                  target="_new"
                >
                  Arabic
                </a>
              </div>
            ),
        },
        {
          id: 11,
          name: "Invoice(En)",
          button: true,
          cell: (row) =>
            row.winner_file && (
              <div>
                <a
                  className="btn btn-link btn-sm"
                  href={row.winner_file.link_en}
                  target="_new"
                >
                  English
                </a>
              </div>
            ),
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.loadAllCategories();
    this.loadAllGroups();
    this.loadAllAuctions(params);

    if (user.role === "U") {
      let columns = this.state.columns;
      delete columns[1];
      delete columns[2];
      this.setState({ columns });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  deleteRow(id) {
    const response = deleteAuction(id);
    if (response.success) {
      swal("Success", "Auction deleted", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          window.location.reload();
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  async openBidPopup(auction) {
    const response = await getAllBidders({ auction });
    let bidders = response.data;
    if (bidders && bidders.length > 0) {
      this.setState({ open_popup: true, bidders });
    }
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllAuctions(params) {
    this.setState({ is_loading: true });
    let input = this.state.input;
    const queryString = new URLSearchParams(input).toString();
    let q = "";
    if (queryString !== "") {
      q = "?" + queryString;
    }
    if (params.type !== "click") {
      var type = params.type;
      var section = params.section;
      let input = this.state.input;
      input.type = type;
      input.section = section;
      q = "?type=" + type + "&section=" + section;
      this.setState({ input });
    }
    const response = await getAuctions(q);
    if (response) {
      let auctions = response.data;
      this.setState({ auctions: auctions, is_loading: false });
    }
  }

  render() {
    let auctions = this.state.auctions;
    let categories = this.state.categories;
    let groups = this.state.groups;
    return (
      <React.Fragment>
        <Modal
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Top Bidders
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.bidders && this.state.bidders.length > 0 && (
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th>
                      {i18n.t("name")} & {i18n.t("details")}
                    </th>
                    <th>{i18n.t("rank")}</th>
                    <th>{i18n.t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bidders.map((item, i) => {
                    return (
                      <tr>
                        <td>
                          {item.name}(
                          <small className="text text-dark">
                            USER ID : {item.user_id}
                          </small>
                          )<br />
                          <small className="text text-dark">
                            {i18n.t("bidder_id")} : {item.id}
                          </small>
                          {(user.role === "A" || user.role === "M") && (
                            <span>
                              <br />
                              <small className="text text-dark">
                                {item.email}
                              </small>
                              <br />
                              <small className="text text-dark">
                                {item.mobile}
                              </small>
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="badge badge-pill badge-info me-2">
                            {i + 1}
                          </span>
                        </td>
                        <td>{item.bid_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Modal.Body>
        </Modal>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <span
                      style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                      onClick={() => {
                        this.setState({ hideSidebar: !this.state.hideSidebar });
                      }}
                    >
                      {this.state.hideSidebar && (
                        <i className="fa fa-chevron-right"></i>
                      )}
                      {!this.state.hideSidebar && (
                        <i className="fa fa-chevron-left"></i>
                      )}
                    </span>

                    <h3 className="card-title">{i18n.t("auctions")}</h3>
                  </div>

                  <div className="search-bar">
                    <div className="row m-3">
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Section</label>
                          <select
                            className="form-control"
                            data-placeholder="Select section"
                            name="section"
                            value={this.state.input.section}
                            onChange={this.handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="main">Main</option>
                            <option value="charity">Charity</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Type</label>
                          <select
                            className="form-control"
                            data-placeholder="Select type"
                            name="type"
                            value={this.state.input.type}
                            onChange={this.handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="active">
                              {i18n.t("active_auctions")}
                            </option>
                            <option value="previous">
                              {i18n.t("previous_auctions")}
                            </option>
                            <option value="featured">
                              {i18n.t("featured_auctions")}
                            </option>
                            <option value="upcoming">
                              {i18n.t("upcoming_auctions")}
                            </option>
                            <option value="direct">
                              {i18n.t("direct_sale")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Category</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Category"
                            name="category"
                            value={this.state.input.category}
                            onChange={this.handleChange}
                          >
                            <option value="">--Category--</option>
                            {categories.length > 0 &&
                              categories.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.category_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Group</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--Group--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.loadAllAuctions}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.is_loading && (
                    <div className="m-3 text-center p-3">
                      <Spinner animation="border" />
                    </div>
                  )}

                  {!this.state.is_loading && (
                    <div className="card-body">
                      <div className="table-responsive ">
                        {auctions.length > 0 && (
                          <DataTable
                            title=""
                            columns={this.state.columns}
                            data={auctions}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Auctions per page:",
                              rangeSeparatorText: "out of",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {!this.state.is_loading && auctions.length === 0 && (
                    <div className="m-3 text-center p-3 text-red">
                      {i18n.t("no_auctions_found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
