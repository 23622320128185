import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getBanners,
  createBanner,
  updateBanner,
  deleteBanner,
} from "../../models/banners";
export class Banners extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      banners: [],
      banner: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.updateAuction = this.updateAuction.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllbanners();
  }

  async loadAllbanners() {
    const response = await getBanners();
    let banners = response.data;
    this.setState({ banners });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async updateAuction(event, id, arrKey) {
    let banners = this.state.banners;
    banners[arrKey]["auction_id"] = event.target.value;
    this.setState({ banners: banners });

    var payload = {
      auction_id: event.target.value,
    };
    const response = await updateBanner(payload, id);
    if (response.success) {
    }
  }

  onFileChange = (event) => {
    if (event.target.name === "banner") {
      this.setState({ banner: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.state.banner) {
      swal("Failed", "Must upload an image.", "error");
      return;
    }

    const formData = new FormData();
    if (this.state.input.auction_id) {
      formData.append("auction_id", this.state.input.auction_id);
    }
    formData.append("banner", this.state.banner);

    const response = await createBanner(formData);
    if (response.success) {
      this.loadAllbanners();
    } else {
      swal("Failed", response.message, "error");
    }
  }

  deleteRow(id) {
    const response = deleteBanner(id);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllbanners();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    let banners = this.state.banners;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Banners
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                {this.state.is_create_section && (
                  <div>
                    <form
                      id="UpdateProfile"
                      className="card mb-0"
                      autoComplete="off"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="card-header link">
                        <h3 className="card-title" style={{ width: "95%" }}>
                          Create Banner
                        </h3>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            this.setState({
                              is_create_section: false,
                            });
                          }}
                        >
                          <span>Hide</span>
                        </button>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Auction ID(for linking banner to an auction)
                              </label>
                              <input
                                type="text"
                                name="auction_id"
                                className="form-control"
                                placeholder="Auction ID"
                                value={this.state.input.auction_id}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Upload banner image
                              </label>
                              <input
                                className=""
                                type="file"
                                placeholder=""
                                name="banner"
                                onChange={this.onFileChange}
                              />
                              <small
                                id="emailHelp"
                                className="form-text text-muted"
                              >
                                (Image dimension : 1000(width) X 286(height))
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            <div className="form-group">
                              <label className="form-label">&nbsp;</label>
                              <button type="submit" className="btn btn-primary">
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <hr />
                  </div>
                )}

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title" style={{ width: "90%" }}>
                      Banners
                    </h3>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        this.setState({
                          is_create_section: !this.state.is_create_section,
                        });
                      }}
                    >
                      {!this.state.is_create_section && (
                        <span>Create Banner</span>
                      )}
                      {this.state.is_create_section && (
                        <span>Hide create section</span>
                      )}
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {banners.length < 1 && <div>No banners found!!!</div>}
                      {banners.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th> ID</th>
                              <th> Auction Linked</th>
                              <th> Image</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {banners.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.id}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      style={{ width: "75px" }}
                                      name="auction_id"
                                      value={item.auction_id || ""}
                                      onChange={(event) => {
                                        this.updateAuction(event, item.id, i);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <img src={item.banner} alt="" />
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-danger btn-sm text-white m-1"
                                      onClick={(e) => {
                                        this.deleteRow(item.id);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>&nbsp;
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
