import React from "react";
import { Link } from "react-router-dom";

export class AuctionTabs extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auctionId: auctionId,
    };
  }
  render() {
    let id = this.state.auctionId;
    let activeTab = this.props.activeTab;
    return (
      <React.Fragment>
        <ul className="nav nav-tabs">
          <li className={activeTab === "details" ? "active" : ""}>
            <Link to={"/edit_auction/" + id}>Details</Link>
          </li>
          <li className={activeTab === "uploader" ? "active" : ""}>
            <Link to={"/image_uploader/" + id}>Media</Link>
          </li>
          <li className={activeTab === "enquiry" ? "active" : ""}>
            <Link to={"/enquiries_details/" + id}>Enquiry & Details</Link>
          </li>
          <li className={activeTab === "contracts" ? "active" : ""}>
            <Link to={"/contracts/" + id}>Contracts</Link>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
