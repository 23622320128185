import React from "react";
//import { Link } from "react-router-dom";
import { enrollUser, getAuction } from "../models/auctions";
import { getLoggedUser } from "../state/user";
import i18n from "../i18n";
import swal from "sweetalert";
import { createThawaniCustomer, createThawaniSession } from "../models/thawani";
import { Spinner } from "react-bootstrap";
import {
  thawaniPaymentUrl,
  thawaniConfig,
  paymentSuccessUrl,
  paymentCancelledUrl,
} from "../constants/global";
import { Link } from "react-router-dom";
import logoThawani from "../logoThawani.svg";
import { Modal } from "react-bootstrap";

//let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
export class Enroll extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      input: {
        name: user.name,
        civil_id_number: user.civil_id_number,
        identity_type: i18n.t("civil_card"),
        cr_number: "",
        account_number: "",
      },
      is_company: false,
      t_c_acept: false,
      payment: {},
      auction: auctionId,
      is_payment: false,
      civilCard: null,
      crFile: null,
      bankGuaranteeFile: null,
      bankAccountFile: null,
      signature: null,
      is_payment_loading: false,
      is_loading: false,
      show_success_message: false,
      terms: "",
      is_online_payment_needed: false,
      is_online_payment_mandatory: false,
      need_online_payment: false,
      thawani_data_id: "",
      enrollData: [],
      selected_price: 20000,
      show_payment_details: false,
      auction_details: {},
      open_popup: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.saveEnroll = this.saveEnroll.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.gotoPayment = this.gotoPayment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAuction();
  }

  async loadAuction() {
    let id = this.state.auction;
    const response = await getAuction(id);
    if (response) {
      let auction = response.data;
      this.setState({ auction_details: auction });
      let payment_needed = false;
      if (
        auction.payment_type === "Online" ||
        auction.payment_type === "Both"
      ) {
        payment_needed = true;
        if (auction.payment_type === "Online") {
          this.setState({
            is_online_payment_mandatory: true,
            show_payment_details: true,
            need_online_payment: true,
          });
        }
        if (auction.payment_amount) {
          let paymentAmount = auction.payment_amount * 1000;
          this.setState({
            selected_price: paymentAmount,
          });
        }
      }
      this.setState({
        terms: auction.terms,
        terms_ar: auction.terms_arabic,
        is_online_payment_needed: payment_needed,
      });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handlePayment(event) {
    let payment = this.state.payment;
    payment[event.target.name] = event.target.value;
    this.setState({ payment });
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 2MB", "error");
      return;
    }

    if (event.target.name === "civilCard") {
      this.setState({ civilCard: event.target.files[0] });
    }
    if (event.target.name === "signature") {
      this.setState({ signature: event.target.files[0] });
    }
    if (event.target.name === "crFile") {
      this.setState({ crFile: event.target.files[0] });
    }
    if (event.target.name === "bankGuaranteeFile") {
      this.setState({ bankGuaranteeFile: event.target.files[0] });
    }
    if (event.target.name === "bankAccountFile") {
      this.setState({ bankAccountFile: event.target.files[0] });
    }
  };

  validateFileSize(file) {
    const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  validationCheck(event) {
    event.preventDefault();
    if (
      !this.state.auction ||
      !this.state.input.name ||
      !this.state.input.identity_type ||
      !this.state.input.civil_id_number ||
      !this.state.civilCard ||
      !this.state.signature
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }
    if (
      this.state.need_online_payment === false &&
      !this.state.bankGuaranteeFile
    ) {
      swal(
        "Failed",
        "Some mandatory fields are missing. Please fill them.",
        "error"
      );
      return;
    }

    if (
      this.state.is_company &&
      (!this.state.crFile ||
        !this.state.input.cr_number ||
        !this.state.bankAccountFile)
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }
    return true;
  }

  async saveEnroll(event) {
    event.preventDefault();

    if (
      !this.state.auction ||
      !this.state.input.name ||
      !this.state.input.identity_type ||
      !this.state.input.civil_id_number ||
      !this.state.civilCard ||
      !this.state.signature
    ) {
      swal(
        "Failed",
        "Some mandatory fields are missing. Please fill them.",
        "error"
      );
      return;
    }
    // if (
    //   this.state.need_online_payment === false &&
    //   !this.state.bankGuaranteeFile
    // ) {
    //   swal("Failed", "All fields are mandatory. Please fill them.", "error");
    //   return;
    // }
    if (
      this.state.is_company &&
      (!this.state.crFile ||
        !this.state.input.cr_number ||
        !this.state.bankAccountFile)
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    this.setState({ is_loading: true });

    const formData = new FormData();
    formData.append("auction", this.state.auction);
    formData.append("enroll_name", this.state.input.name);
    formData.append("identity_type", this.state.input.identity_type);
    formData.append("civil_id_number", this.state.input.civil_id_number);
    formData.append("is_company", this.state.is_company ? "1" : "0");
    formData.append("cr_number", this.state.input.cr_number);
    formData.append("account_number", this.state.input.account_number);
    formData.append("is_offline", !this.state.need_online_payment);

    if (this.state.civilCard) {
      formData.append(
        "civilCard",
        this.state.civilCard,
        this.state.civilCard.name
      );
    }

    if (this.state.crFile) {
      formData.append("crFile", this.state.crFile, this.state.crFile.name);
    }
    if (this.state.bankGuaranteeFile) {
      formData.append(
        "bankGuaranteeFile",
        this.state.bankGuaranteeFile,
        this.state.bankGuaranteeFile.name
      );
    }
    if (this.state.bankAccountFile) {
      formData.append(
        "bankAccountFile",
        this.state.bankAccountFile,
        this.state.bankAccountFile.name
      );
    }

    if (this.state.signature) {
      formData.append(
        "signature",
        this.state.signature,
        this.state.signature.name
      );
    }

    this.setState({ is_payment_loading: true });
    const response = await enrollUser(formData);
    if (response.success) {
      this.setState({ show_success_message: true });
      this.setState({ is_loading: false });
      this.setState({ is_payment_loading: false });
      if (this.state.need_online_payment) {
        this.setState({ show_success_message: false });
        this.createCustomer(response.data);
        this.setState({ is_payment: true, enrollData: response.data });
      }
    } else {
      this.setState({ is_loading: false });
      this.setState({ is_payment_loading: false });
      swal("Failed", response.message, "error");
      return;
    }
  }

  async gotoPayment() {
    let enroll = this.state.enrollData;
    let customer_id = this.state.thawani_data_id;
    let queryParams =
      "?gid=" +
      enroll.group_id +
      "&eid=" +
      enroll.id +
      "&auc=" +
      this.state.auction +
      "&amt=" +
      this.state.selected_price;

    let payload = {
      client_reference_id: user.email,
      mode: "payment",
      products: [
        {
          name: "Mzadcom Auction",
          quantity: 1,
          unit_amount: parseInt(this.state.selected_price),
        },
      ],
      success_url: paymentSuccessUrl + queryParams,
      cancel_url: paymentCancelledUrl,
      customer_id: customer_id,
      metadata: {
        "Customer name": user.name,
        "Order id": enroll.id,
        user: user.id,
        auction: this.state.auction,
      },
    };

    let thw = await createThawaniSession(payload);
    this.setState({ is_payment_loading: false });

    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
    }
  }

  async createCustomer(enroll) {
    window.scrollTo(0, 0);
    if (user && (user.email || user.username)) {
      let thw = await createThawaniCustomer({
        client_customer_id: user.email ? user.email : user.username,
      });
      if (thw && thw.data) {
        localStorage.setItem("thawani_customer_id", thw.data.id);
        this.setState({ thawani_data_id: thw.data.id });
        this.gotoPayment();
      }
    }
  }

  closeModal() {
    this.setState({ open_popup: false });
  }

  openModal() {
    this.setState({ open_popup: true });
  }

  render() {
    let two_percent =
      (parseFloat(this.state.auction_details.payment_amount) * 2) / 100;
    let credit_amount =
      parseFloat(this.state.auction_details.payment_amount) +
      parseFloat(two_percent);

    let one_five_percent =
      (parseFloat(this.state.auction_details.payment_amount) * 1.5) / 100;
    let debit_amount =
      parseFloat(this.state.auction_details.payment_amount) +
      parseFloat(one_five_percent);
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary mt-5">
                  <h1 className="auction-title-cust-1">{i18n.t("enroll")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <a href={true}>{i18n.t("home")}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href={true}>{i18n.t("auctions")}</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("enroll")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                <div className="card mb-xl-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("enroll")}</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="enrollForm"
                      method="get"
                      className="form-horizontal mb-0"
                    >
                      <div
                        id="rootwizard"
                        className="border pt-0"
                        style={{ overflow: "hidden" }}
                      >
                        <div className="card-body mt-3 mb-0 b-0">
                          {/** Enroll Section */}
                          <div className="" id="first">
                            <div class="container">
                              <div class="row">
                                <div className="col-sm">
                                  <fieldset class="scheduler-border">
                                    <legend class="scheduler-border">
                                      {i18n.t("reg_type")}
                                    </legend>
                                    <div className="btn-group d-flex">
                                      <button
                                        type="button"
                                        className={
                                          !this.state.is_company
                                            ? "btn btn-primary w-100"
                                            : "btn btn-outline-primary w-100"
                                        }
                                        onClick={(event) => {
                                          this.setState({
                                            is_company: false,
                                          });
                                        }}
                                      >
                                        {i18n.t("individual")}
                                      </button>
                                      &nbsp;
                                      <button
                                        type="button"
                                        className={
                                          this.state.is_company
                                            ? "btn btn-primary w-100"
                                            : "btn btn-outline-primary w-100"
                                        }
                                        onClick={(event) => {
                                          this.setState({
                                            is_company: true,
                                          });
                                        }}
                                      >
                                        {i18n.t("company")}
                                      </button>
                                    </div>
                                  </fieldset>
                                </div>

                                {this.state.is_online_payment_needed &&
                                  !this.state.is_online_payment_mandatory && (
                                    <div className="col-sm">
                                      <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">
                                          {i18n.t("pay_type")}
                                        </legend>
                                        <div className="btn-group d-flex">
                                          <button
                                            type="button"
                                            className={
                                              !this.state.need_online_payment
                                                ? "btn btn-dark w-100"
                                                : "btn btn-outline-dark w-100"
                                            }
                                            onClick={(event) => {
                                              this.setState({
                                                need_online_payment: false,
                                              });
                                              this.setState({
                                                show_payment_details: false,
                                              });
                                            }}
                                          >
                                            {i18n.t("offline")}
                                          </button>
                                          &nbsp;
                                          <button
                                            type="button"
                                            className={
                                              this.state.need_online_payment
                                                ? "btn btn-dark w-100"
                                                : "btn btn-outline-dark w-100"
                                            }
                                            onClick={(event) => {
                                              this.setState({
                                                need_online_payment: true,
                                              });
                                              this.setState({
                                                show_payment_details: true,
                                              });
                                            }}
                                          >
                                            {i18n.t("online")}
                                          </button>
                                        </div>
                                      </fieldset>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="control-group form-group">
                              <div className="form-group">
                                <label
                                  className="form-label text-dark"
                                  style={{ textAlign: "left" }}
                                >
                                  {this.state.is_company
                                    ? i18n.t("company_name")
                                    : i18n.t("name")}
                                  <span className="text text-danger">(*)</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control required Title"
                                  placeholder=""
                                  value={this.state.input.name}
                                  name="name"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            {this.state.is_company && (
                              <div>
                                <div className="form-group">
                                  <label
                                    className="form-label text-dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("cr_number")}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control required Title"
                                    placeholder=""
                                    value={this.state.input.cr_number}
                                    name="cr_number"
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <div className="form-group">
                                    <label
                                      for="form-label"
                                      className="form-label"
                                      style={{ textAlign: "left" }}
                                    >
                                      {i18n.t("attacth_cr_copy")}{" "}
                                      <span className="text text-danger">
                                        (*)
                                      </span>
                                    </label>
                                    <input
                                      className=""
                                      type="file"
                                      placeholder=""
                                      name="crFile"
                                      onChange={this.onFileChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="form-group">
                                    <label
                                      for="form-label"
                                      className="form-label"
                                      style={{ textAlign: "left" }}
                                    >
                                      {i18n.t("attach_account_details")}{" "}
                                      <span className="text text-danger">
                                        (*)
                                      </span>
                                    </label>
                                    <input
                                      className=""
                                      type="file"
                                      placeholder=""
                                      name="bankAccountFile"
                                      onChange={this.onFileChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {!this.state.need_online_payment && (
                              <div>
                                <div className="form-group">
                                  <div className="form-group">
                                    <label
                                      for="form-label"
                                      className="form-label"
                                      style={{ textAlign: "left" }}
                                    >
                                      {i18n.t("attach_bank_guarantee")}{" "}
                                      <span className="text text-danger">
                                        (*)
                                      </span>
                                    </label>
                                    <input
                                      className=""
                                      type="file"
                                      placeholder=""
                                      name="bankGuaranteeFile"
                                      onChange={this.onFileChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    className="form-label text-dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("account_number")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control required Title"
                                    placeholder={i18n.t("account_number")}
                                    value={this.state.input.account_number}
                                    name="account_number"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            )}

                            {/* <div className="control-group form-group">
                              <div className="form-group">
                                <label
                                  className="form-label text-dark"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("identity_type")}{" "}
                                  <span className="text text-danger">(*)</span>
                                </label>
                                <select
                                  className="form-control form-select required category"
                                  value={this.state.input.identity_type}
                                  name="identity_type"
                                  onChange={this.handleChange}
                                >
                                  <option value={i18n.t("civil_card")}>
                                    {i18n.t("civil_card")}
                                  </option>
                                </select>
                              </div>
                            </div> */}

                            <div className="control-group form-group">
                              <div className="form-group">
                                <label
                                  className="form-label text-dark"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("civil_id_number")}
                                  <span className="text text-danger">(*)</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control required Title"
                                  placeholder={i18n.t("civil_id_number")}
                                  value={this.state.input.civil_id_number}
                                  name="civil_id_number"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-group">
                                <label
                                  for="form-label"
                                  className="form-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("attacth_civil_card")}{" "}
                                  <span className="text text-danger">(*)</span>
                                </label>
                                <input
                                  className=""
                                  type="file"
                                  placeholder=""
                                  name="civilCard"
                                  onChange={this.onFileChange}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-group">
                                <label
                                  for="form-label"
                                  className="form-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("signature")}{" "}
                                  <span className="text text-danger">(*)</span>
                                </label>
                                <input
                                  className=""
                                  type="file"
                                  placeholder=""
                                  name="signature"
                                  onChange={this.onFileChange}
                                />
                              </div>
                            </div>

                            {this.state.auction_details.payment_amount && (
                              <div className="form-group">
                                <div className="form-group mb-5 mt-5">
                                  {i18n.t("enroll_amount")} : {i18n.t("omr")}{" "}
                                  <b>
                                    {this.state.auction_details.payment_amount}
                                  </b>
                                </div>
                              </div>
                            )}

                            <div className="form-group">
                              <div className="form-group">
                                <input
                                  className=""
                                  type="checkbox"
                                  placeholder=""
                                  name="t_c"
                                  checked={this.state.t_c_acept}
                                  onChange={(event) => {
                                    this.setState({
                                      t_c_acept: !this.state.t_c_acept,
                                    });
                                  }}
                                />{" "}
                                <span>
                                  {i18n.t("please_accept")}{" "}
                                  <a href={true}>
                                    {i18n.t("terms_conditions")}
                                  </a>
                                  <span className="text text-danger">(*)</span>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row clearfix">
                              {this.state.show_payment_details && (
                                <div className="text-center mb-3">
                                  <div className="m-3">
                                    <img src={logoThawani} alt="" height={30} />
                                  </div>
                                  <div className="m-3">
                                    Submitting this form will redirect to
                                    Thawani payment services
                                  </div>
                                </div>
                              )}

                              <div className="text-center mb-3">
                                <button
                                  type="button"
                                  className="btn btn-info  mb-0 me-2 text-white"
                                  onClick={(event) => {
                                    if (this.state.need_online_payment) {
                                      if (this.validationCheck(event)) {
                                        this.setState({ open_popup: true });
                                      }
                                    } else {
                                      this.saveEnroll(event);
                                    }
                                  }}
                                  disabled={
                                    !this.state.t_c_acept ||
                                    this.state.is_payment
                                  }
                                >
                                  {!this.state.is_payment && (
                                    <span>{i18n.t("save_continue")}</span>
                                  )}

                                  {this.state.is_payment && (
                                    <span>Loading...</span>
                                  )}
                                </button>
                              </div>

                              {(this.state.is_loading ||
                                this.state.is_payment_loading) && (
                                <div
                                  className="text-center mt-3"
                                  style={{ textAlign: "center" }}
                                >
                                  <Spinner animation="border" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.show_success_message && (
                                  <div>
                                    <div className="text text-success text-center">
                                      <Link
                                        className="text text-danger"
                                        to={
                                          "/auction-details/" +
                                          this.state.auction
                                        }
                                      >
                                        {i18n.t("enroll_complete_message")}
                                      </Link>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          {/** Enroll section ends */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card mb-xl-0">
                  <div className="card-body">
                    <h2 className="mb-4">{i18n.t("terms_conditions")}</h2>
                    <div
                      className="leading-Automatic"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {lng === "ar" ? this.state.terms_ar : this.state.terms}
                    </div>
                  </div>
                </div>

                <div className="card mb-xl-0 mt-5">
                  <div className="card-body">
                    <h2 className="mb-4">{i18n.t("bank_details")}</h2>
                    <div
                      className="leading-Automatic"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <p className="pb-0">
                        {i18n.t("account_name")} : Mzadcom Smart Auction
                        Solutions LLC
                      </p>
                      <p className="pb-0">
                        {i18n.t("account_number_n")} : 0440061839220015
                      </p>
                      <p className="pb-0">{i18n.t("swift_code")} : BMUSOMRX</p>
                      <p className="pb-0">{i18n.t("currency")} : OMANI RIYAL</p>
                      <p className="pb-0">{i18n.t("bank_branch")} : Gala.</p>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.need_online_payment && this.state.open_popup && (
                <Modal
                  size="lg"
                  show={this.openModal}
                  onHide={this.closeModal}
                  animation={true}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      {lng === "ar"
                        ? "مبلغ الضمان وسياسة الاسترداد"
                        : "Warranty amount and refund policy"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="col-xl-12">
                      <div className="card mb-xl-0 mt-5">
                        {lng === "ar" && (
                          <div className="card-body">
                            <h2 className="mb-4 rtl">
                              مبلغ الضمان وسياسة الاسترداد
                            </h2>
                            <div
                              className="leading-Automatic rtl"
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              <p className="rtl">
                                1) على الراغبين بالمشاركة في المزاد الإلكتروني
                                إيداع مبلغ الضمان (
                                <b>
                                  {this.state.auction_details.payment_amount}
                                </b>{" "}
                                ريال عماني).
                              </p>
                              <p className="rtl">
                                2) مبلغ التأمين قابل للاسترداد. ومع ذلك ، فإن
                                مبلغ التحويل غير قابل للاسترداد وهو 1.5٪ لبطاقات
                                الخصم العمانية و 2٪ لبطاقات الائتمان.
                              </p>
                              <p className="rtl">
                                3) يكون مبلغ الضمان منفصلاً عن قيمة الصنف الذي
                                يجب دفعه بعد الفوز بالمزاد.
                              </p>
                              <p className="rtl">
                                5) سيتم إرجاع مبلغ الضمان إلى مقدم العطاء بعد
                                انتهاء المزاد ، بالنسبة للفائزين ، سيتم استرداد
                                هذا المبلغ بعد الدفعة النهائية واستكمال العملية.
                              </p>
                              <p className="rtl">
                                6) يتم رد المبالغ المستردة في غضون 1 إلى 5 أيام
                                عمل لعملاء بنك مسقط ، ولكن قد تستغرق العملية
                                وقتًا أطول لعملاء البنوك الأخرى.
                              </p>
                              <p className="rtl">
                                7) عند إعادة مبلغ الضمان إلى حسابك ، قد لا تتلقى
                                رسالة نصية قصيرة. يرجى التحقق من حسابك من خلال
                                تطبيق الهاتف الذكي الخاص بالبنك أو كشف الحساب.
                              </p>
                            </div>
                          </div>
                        )}

                        {lng !== "ar" && (
                          <div className="card-body">
                            <h2 className="mb-4">
                              Warranty amount and refund policy
                            </h2>
                            <div
                              className="leading-Automatic"
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              <p className="">
                                1) Those wishing to participate in the online
                                auction must deposit the guarantee amount (
                                <b>
                                  {this.state.auction_details.payment_amount}
                                </b>
                                &nbsp; Omani Riyals).
                              </p>
                              <p className="">
                                2) The security deposit is refundable. However,
                                the transfer amount is non-refundable and is
                                <b>1.5%</b> for Omani debit cards and <b>2%</b>{" "}
                                for credit cards.
                              </p>
                              <p className="">
                                3) The amount of the security is separate from
                                the value of the item, which must be paid after
                                winning the auction.
                              </p>
                              <p className="">
                                5) The security amount will be returned to the
                                bidder after the completion of the auction, for
                                the winners, this amount will be refunded after
                                the final payment and completion of the process.
                              </p>
                              <p className="">
                                6) Refunds are made within 1 to 5 working days
                                for bank muscat customers, but the process may
                                take longer for customers of other banks.
                              </p>
                              <p className="">
                                7) When the guarantee amount is refunded to your
                                account, you may not receive an SMS. Please
                                verify your account through the bank's
                                smartphone application or statement of account.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="text-center mb-3 mt-3">
                        <button
                          className="btn btn-info  mb-0 me-2 text-white"
                          onClick={(e) => {
                            this.setState({
                              selected_price: debit_amount * 1000,
                            });
                            this.saveEnroll(e);
                          }}
                          disabled={
                            !this.state.t_c_acept || this.state.is_payment
                          }
                        >
                          {!this.state.is_payment && (
                            <span>
                              {i18n.t("debit_card_1")}
                              <br /> {debit_amount}
                            </span>
                          )}

                          {this.state.is_payment && <span>Loading...</span>}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-info  mb-0 me-2 text-white"
                          onClick={(e) => {
                            this.setState({
                              selected_price: credit_amount * 1000,
                            });
                            this.saveEnroll(e);
                          }}
                          disabled={
                            !this.state.t_c_acept || this.state.is_payment
                          }
                        >
                          {!this.state.is_payment && (
                            <span>
                              {i18n.t("credit_card_2")}
                              <br /> {credit_amount}
                            </span>
                          )}

                          {this.state.is_payment && <span>Loading...</span>}
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
