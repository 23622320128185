import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import i18n from "../../i18n";
//import { CustomTitle } from "../helpers/customTitle";
import { ActiveAuctions } from "./auctionsCategory/active";
import { PreviousAuctions } from "./auctionsCategory/previous";
import { FeaturedAuctions } from "./auctionsCategory/featured";
import { UpcomingAuctions } from "./auctionsCategory/upcoming";
import { AllAuctions } from "./auctionsCategory/all";
export class CategoryAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedTab: "all",
      active_count: 0,
      upcoming_count: 0,
      firstload: true,
      secondLoad: false,
      tabs: [
        {
          name: i18n.t("all_auctions"),
          tab: "all",
          icon: process.env.PUBLIC_URL + "/assets/icons/all_auctions.png",
        },
        {
          name: i18n.t("active_auctions"),
          tab: "active",
          icon: process.env.PUBLIC_URL + "/assets/icons/active_auction.png",
        },
        {
          name: i18n.t("upcoming_auctions"),
          tab: "upcoming",
          icon: process.env.PUBLIC_URL + "/assets/icons/upcoming_auction.png",
        },
        {
          name: i18n.t("featured_auctions"),
          tab: "featured",
          icon: process.env.PUBLIC_URL + "/assets/icons/featured_auction.png",
        },
        {
          name: i18n.t("previous_auctions"),
          tab: "previous",
          icon: process.env.PUBLIC_URL + "/assets/icons/expired_auction.png",
        },
      ],
      lightboxImages: [],
      is_active_light_box: false,
    };
    this.handleTabs = this.handleTabs.bind(this);
    this.getActiveCount = this.getActiveCount.bind(this);
    this.getUpcomingCount = this.getUpcomingCount.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
  }

  handleTabs(tabname) {
    console.log(tabname);
    this.setState({ selectedTab: tabname });
  }

  getActiveCount(count) {
    if (count === 0 && this.state.firstload) {
      this.setState({
        selectedTab: "upcoming",
        firstload: false,
        secondLoad: true,
      });
    }
  }

  getUpcomingCount(count) {
    console.log(count);
    if (count === 0 && this.state.secondLoad) {
      console.log("prev selected");
      this.setState({ selectedTab: "previous", secondLoad: false });
    }
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
    }
    this.setState({
      is_active_light_box: active,
      lightboxImages: lightboxImages,
    });
  }

  render() {
    let selectedTab = this.state.selectedTab;
    let tabs = this.state.tabs;
    let currTabObj = tabs.find((obj) => {
      return obj.tab === selectedTab;
    });
    let tabName = currTabObj.name;
    return (
      <React.Fragment>
        <section
          className="sptb bg-white"
          style={{ padding: "1rem 1rem 5rem 1rem" }}
        >
          <div className="container home-main-container">
            {/* <div className="section-title center-block text-center">
              <CustomTitle title={i18n.t("auctions")} />
            </div> */}
            <div className="col-md-12">
              <div className="items-gallery">
                <div className="items-blog-tab text-center">
                  <div className="items-blog-tab-heading row">
                    <div className="col-12">
                      <ul className="nav items-blog-tab-menu detail-tab-md">
                        {tabs.map((item, i) => {
                          return (
                            <li
                              className={
                                selectedTab === item.tab ? "active" : ""
                              }
                              key={i}
                            >
                              <a
                                href={true}
                                className={
                                  selectedTab === item.tab ? "active show" : ""
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleTabs(item.tab);
                                }}
                              >
                                <img
                                  src={item.icon}
                                  alt=""
                                  style={{ width: "18px", height: "18px" }}
                                />{" "}
                                &nbsp;
                                {item.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>

                      <Dropdown className="d-inline mx-2 detail-tab-mob">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true"
                          className="btn-block btn-dd-list"
                          variant=""
                        >
                          {tabName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {tabs.map((item, i) => {
                            return (
                              <Dropdown.Item
                                key={i}
                                href="#"
                                onClick={() => {
                                  this.handleTabs(item.tab);
                                }}
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="tab-content">
                    {this.state.selectedTab === "active" && (
                      <div className="tab-pane active">
                        <ActiveAuctions
                          type={this.state.selectedTab}
                          getActiveCount={this.getActiveCount}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "upcoming" && (
                      <div className="tab-pane active">
                        <UpcomingAuctions
                          type={this.state.selectedTab}
                          getUpcomingCount={this.getUpcomingCount}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "featured" && (
                      <div className="tab-pane active">
                        <FeaturedAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "previous" && (
                      <div className="tab-pane active">
                        <PreviousAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "all" && (
                      <div className="tab-pane active">
                        <AllAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
