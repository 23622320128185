import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { deleteUser, getUsers } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";

const columns = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
    wrap: true,
    width: "50px",
  },
  {
    name: i18n.t("name"),
    selector: "name",
    sortable: true,
  },
  {
    name: "username",
    selector: "username",
    sortable: true,
  },
  {
    name: i18n.t("email"),
    selector: "email",
  },
  {
    name: i18n.t("mobile"),
    selector: "mobile",
    wrap: true,
    cell: (row) => row.country_code + " " + row.mobile,
  },
  {
    name: i18n.t("Civil Card"),
    selector: "files5",
    cell: (row) =>
      row.file_civil_card ? (
        <a href={row.file_civil_card} target="_blank" rel="noreferrer">
          Download Civil ID
        </a>
      ) : (
        ""
      ),
  },
  {
    name: "Delete",
    button: true,
    cell: (row) => (
      <button
        className="btn btn-danger"
        onClick={(e) => {
          deleteRow(row.id);
        }}
      >
        Delete
      </button>
    ),
  },
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#088f94",
      color: "#FFFFFF",
    },
  },
};

function deleteRow(id) {
  const response = deleteUser(id);
  console.log(response);
  swal("Success", "User deleted", "success", {
    buttons: false,
    timer: 2000,
  })
    .then((response) => {
      window.location.reload();
    })
    .catch((response) => {
      swal("Failed", "Something went wrong", "error");
    });
}
export class UsersList extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: {},
      hideSidebar: false,
    };
  }

  componentDidMount() {
    this.loadAllAuctions();
  }

  async loadAllAuctions() {
    const response = await getUsers();
    let auctions = response.data;
    this.setState({ auctions: auctions });
  }

  render() {
    let auctions = this.state.auctions;
    console.log(auctions);
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <span
                      style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                      onClick={() => {
                        this.setState({ hideSidebar: !this.state.hideSidebar });
                      }}
                    >
                      {this.state.hideSidebar && (
                        <i className="fa fa-chevron-right"></i>
                      )}
                      {!this.state.hideSidebar && (
                        <i className="fa fa-chevron-left"></i>
                      )}
                    </span>
                    <h3 className="card-title">{i18n.t("users")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {auctions.length > 0 && (
                        <DataTable
                          title=""
                          columns={columns}
                          data={auctions}
                          customStyles={customStyles}
                          highlightOnHover
                          pagination
                          paginationPerPage={15}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Users per page:",
                            rangeSeparatorText: "out of",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
