import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";
const user = getLoggedUser();

async function createOrganization(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "organizations",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getClients() {
  return fetch(apiUrl + "clients", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getOrganizations() {
  return fetch(apiUrl + "active_organizations", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getAllOrganization() {
  return fetch(apiUrl + "organizations", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getOganization(id) {
  return fetch(apiUrl + "organizations/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateOrganization(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "organizations/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteOrganization(id) {
  return fetch(apiUrl + "organizations/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "applications/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createOrganization,
  getAllOrganization,
  getOrganizations,
  getOganization,
  updateOrganization,
  deleteOrganization,
  getClients,
};
