import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  getMainGroup,
  deleteMainGroup,
  updateMainGroup,
} from "../../models/groups";
export class EditMainGroup extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let groupId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      groupId: groupId,
      input: {
        has_single_enrollment: false,
      },
      errors: {},
      groups: [],
      collapse: true,
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      file_group_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  async loadGroup() {
    const response = await getMainGroup(this.state.groupId);
    let group = response.data;
    group.is_auction_update_needed = false;
    let enquiries =
      group && group.enquiry ? group.enquiry : this.state.enquiries;
    this.setState({ input: group, enquiries });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.has_single_enrollment = !this.state.input.has_single_enrollment;
    this.setState({ input });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateMainGroup(
        {
          id: this.state.groupId,
          name: this.state.input.name,
          has_single_enrollment: this.state.input.has_single_enrollment,
        },
        this.state.groupId
      );
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow() {
    const response = deleteMainGroup(this.state.groupId);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        window.location.href = "/groups";
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">Dashboard</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      Groups
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="">
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">
                        {i18n.t("edit")} {i18n.t("group")}
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("name")}
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Group Name"
                              value={this.state.input.name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="has_single_enrollment"
                              type="checkbox"
                              checked={this.state.input.has_single_enrollment}
                              onChange={this.toggleChange}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Has Single Enrollment.?
                            </label>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <button type="submit" className="btn btn-primary">
                                Update Group
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
