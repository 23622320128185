import React from "react";
import i18n from "../../../i18n";
import { getAllGroups } from "../../../models/groups";
import { getAllAuctionsByGroup } from "../../../models/auctions";

export class DashboardCounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      dash_panel: true,
      groups: {},
      auctions: {},
      auction_loader: false,
    };
    this.loadAllGroups = this.loadAllGroups.bind(this);
    this.loadByGroup = this.loadByGroup.bind(this);
    this.loadAuctions = this.loadAuctions.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadAuctions(this.state.input.group);
    }
    this.loadByGroup();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctions(group) {
    if (group) {
      this.setState({ auction_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ auction_loader: false });
        this.setState({ auctions: response.data });
      }
    }
  }

  loadByGroup() {
    this.props.loadByGroup(this.state.input);
  }

  hideSideBar() {
    this.props.hideSideBar(!this.props.is_side_bar);
  }

  render() {
    let data = this.props.data;
    return (
      <React.Fragment>
        <div className="card">
          <div className={this.state.dash_panel ? "row m-3" : "row m-3 d-none"}>
            <div className="col-xl-3 col-lg-3">
              <a
                className="btn btn-info btn-block font_1_5rem"
                href="auctions_list?type=active&section=main"
              >
                {i18n.t("active")} : {data[0]}
              </a>
            </div>

            <div className="col-xl-3 col-lg-3">
              <a
                className="btn btn-success btn-block font_1_5rem"
                href="auctions_list?type=upcoming&section=main"
              >
                {i18n.t("upcoming")} : {data[1]}
              </a>
            </div>

            <div className="col-xl-3 col-lg-3">
              <a
                className="btn btn-warning btn-block font_1_5rem"
                href="auctions_list?type=previous&section=main"
              >
                {i18n.t("expired")} : {data[2]}
              </a>
            </div>

            <div className="col-xl-3 col-lg-3">
              <a
                className="btn btn-danger btn-block font_1_5rem"
                href="auctions_list?type=featured&section=main"
              >
                {i18n.t("featured")} : {data[3]}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
