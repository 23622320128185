import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
  S: "Silver",
};

const statuses = {
  A: "Approved",
  O: "Open",
  E: "Expired",
  C: "Cancel",
  N: "Not Approved",
};

const user = getLoggedUser();
var all_auc_url = "all/auctions?";
var active_url = "active/auctions?";
var featured_url = "featured/auctions?";
var previous_url = "previous/auctions?";
var upcoming_url = "upcoming/auctions?";
var direct_url = "direct/auctions?";
var zakath_url = "zakath/auctions?";
var auction_view_url = "auctions/";
var grouped_auctions = "grouped_auctions?";
var check_user_validity = "check_user_validity_gen";
var header = {
  "Content-Type": "application/json",
};

if (user != null && user.token) {
  header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.token,
  };

  all_auc_url = "logged_all/auctions?";
  active_url = "logged_active/auctions?";
  featured_url = "logged_featured/auctions?";
  previous_url = "logged_previous/auctions?";
  upcoming_url = "logged_upcoming/auctions?";
  direct_url = "logged_direct/auctions?";
  zakath_url = "logged_zakath/auctions?";
  auction_view_url = "logged_auctions/";
  grouped_auctions = "logged_grouped_auctions?";
  check_user_validity = "check_user_validity";
}

async function getAllAuctionsGrouped(q) {
  return fetch(apiUrl + all_auc_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAllAuctions(q) {
  return fetch(apiUrl + "all_auctions?" + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAuctions(q) {
  return fetch(apiUrl + "auctions" + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAllBidders(payload) {
  return fetch(apiUrl + "auctions/bidders", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getActiveAuctions(q) {
  return fetch(apiUrl + active_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}
async function getFeaturedAuctions(q) {
  return fetch(apiUrl + featured_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}
async function getPreviousAuctions(q) {
  return fetch(apiUrl + previous_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}
async function getUpcomingAuctions(q) {
  return fetch(apiUrl + upcoming_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}
async function getDirectAuctions(q) {
  return fetch(apiUrl + direct_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}
async function getZakathAuctions(q) {
  return fetch(apiUrl + zakath_url + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getEnrolledAuctions(q) {
  return fetch(apiUrl + "enrolled/auctions?" + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAuction(id) {
  return fetch(apiUrl + auction_view_url + id, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function createAuction(payload) {
  return fetch(apiUrl + "auctions", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function createBulkAuction(payload) {
  return fetch(apiUrl + "auctions/bulk", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateAuction(payload, id) {
  return fetch(apiUrl + "auctions/" + id, {
    method: "PUT",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteAuction(id) {
  return fetch(apiUrl + "auctions/" + id, {
    method: "DELETE",
    headers: header,
  }).then((data) => data.json());
}

async function checkUserValidity(payload) {
  return fetch(apiUrl + check_user_validity, {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function checkUserValidityGn(payload) {
  return fetch(apiUrl + "check_user_validity_gen", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function bidNow(payload) {
  return fetch(apiUrl + "bid_now", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getTopBidders(payload) {
  if (user != null && user.token) {
    return fetch(apiUrl + "get_top_bidders", {
      method: "POST",
      headers: header,
      body: JSON.stringify(payload),
    }).then((data) => data.json());
  }
}

async function updatePayment(payload) {
  return fetch(apiUrl + "update_payment", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function enrollUser(payload) {
  let config = {
    headers: header,
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "enroll_user",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllAuctionsByGroup(group) {
  return fetch(apiUrl + "auctions_by_group/" + group, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAllAuctionsByOrg(org) {
  return fetch(apiUrl + "auctions_by_organization/" + org, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAllBiddersByGroup(payload) {
  return fetch(apiUrl + "bidder_report", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function toggleLike(payload) {
  return fetch(apiUrl + "toggle_like", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function toggleWishlist(payload) {
  return fetch(apiUrl + "toggle_wishlist", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function addViewCount(payload) {
  if (user != null && user.token) {
    return fetch(apiUrl + "add_view_count", {
      method: "POST",
      headers: header,
      body: JSON.stringify(payload),
    }).then((data) => data.json());
  }
}

async function sendNotification(payload) {
  return fetch(apiUrl + "send_notification", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getEnrolledUsers(q) {
  return fetch(apiUrl + "enrolled/users?" + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function activateUserEnroll(payload) {
  return fetch(apiUrl + "enroll/activate", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function deActivateUserEnroll(payload) {
  return fetch(apiUrl + "enroll/deactivate", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function extraTimeCheck(payload) {
  return fetch(apiUrl + "extra_time_check", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAuctionImages(id) {
  return fetch(apiUrl + "auction_images/" + id, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAuctionVideo(id) {
  return fetch(apiUrl + "auction_video/" + id, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function uploadImage(payload) {
  let config = {
    headers: header,
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "upload_image",
      payload,
      config
    );
    return response;
  } catch (response) {
    return response;
  }
}

async function uploadVideo(payload) {
  let config = {
    headers: header,
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "upload_video",
      payload,
      config
    );
    return response;
  } catch (response) {
    return response;
  }
}

async function removeImage(payload) {
  return fetch(apiUrl + "remove_image", {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function makeCover(payload) {
  return fetch(apiUrl + "make_cover", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function uploadTempDownloads(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "upload_temp_downloads",
      payload,
      config
    );
    return response;
  } catch (response) {
    return response;
  }
}

async function getAuctionCords() {
  return fetch(apiUrl + "get_cordinates", {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getAllWinners(payload) {
  return fetch(apiUrl + "winners", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAuctionChartData(type) {
  return fetch(apiUrl + "auctions_chart_data/" + type, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAuctionBidReport(payload) {
  return fetch(apiUrl + "auction_bid_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getFinalReport(payload) {
  return fetch(apiUrl + "final_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getSalesReport(payload) {
  return fetch(apiUrl + "sales_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getSalesChartData() {
  return fetch(apiUrl + "sales_chart_data", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function sendFinalReport(payload) {
  return fetch(apiUrl + "send_final_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getGroupedAuctions(q) {
  return fetch(apiUrl + grouped_auctions + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function getMyAuctions(q) {
  return fetch(apiUrl + "my/auctions?" + q, {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

async function activateAllUserEnroll(payload) {
  return fetch(apiUrl + "enroll/activate_all", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendWinnerNotification(payload) {
  return fetch(apiUrl + "winner_notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllBidderPositions(payload) {
  return fetch(apiUrl + "position_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getMyWinningList(payload) {
  return fetch(apiUrl + "winning_list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export {
  createAuction,
  getAuctions,
  getActiveAuctions,
  getFeaturedAuctions,
  getPreviousAuctions,
  getUpcomingAuctions,
  getDirectAuctions,
  getZakathAuctions,
  getEnrolledAuctions,
  deleteAuction,
  getAuction,
  updateAuction,
  checkUserValidity,
  checkUserValidityGn,
  bidNow,
  getTopBidders,
  enrollUser,
  updatePayment,
  getAllBidders,
  createBulkAuction,
  getAllAuctionsByGroup,
  getAllBiddersByGroup,
  toggleLike,
  toggleWishlist,
  addViewCount,
  sendNotification,
  getEnrolledUsers,
  activateUserEnroll,
  deActivateUserEnroll,
  extraTimeCheck,
  getAuctionImages,
  getAuctionVideo,
  uploadImage,
  uploadVideo,
  removeImage,
  makeCover,
  uploadTempDownloads,
  getAuctionCords,
  getAllAuctions,
  getAllWinners,
  getAuctionChartData,
  getAuctionBidReport,
  getAllAuctionsByOrg,
  getFinalReport,
  getSalesReport,
  getSalesChartData,
  sendFinalReport,
  getGroupedAuctions,
  getMyAuctions,
  activateAllUserEnroll,
  sendWinnerNotification,
  getAllBidderPositions,
  getMyWinningList,
  getAllAuctionsGrouped,
  maskClasses,
  statuses,
};
