import React from "react";
import Lightbox from "react-awesome-lightbox";
import Countdown from "react-countdown";
import i18n from "../../i18n";
import { getGroupedAuctions } from "../../models/auctions";
import { AuctionListGrouped } from "../helpers/skeletons/AuctionListGrouped";
import { AuctionRowGrouped } from "./helpers/auctionRowGrouped";
//import { getLoggedUser } from "../../state/user";

const FinishBid = () => (
  <span className="text-danger">{i18n.t("time_ends")}</span>
);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    let warning =
      days === 0 && hours === 0 && minutes === 0 && seconds < 10 ? true : false;
    console.log(warning);
    return (
      <span
        className={
          warning
            ? "text-danger countdown-listing-pg countdwn-warning"
            : "text-info countdown-listing-pg"
        }
      >
        {days}
        {i18n.t("D")} : {hours}
        {i18n.t("H")} : {minutes}
        {i18n.t("M")} : {seconds}
        {i18n.t("S")}
      </span>
    );
  }
};

//const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
let lng = localStorage.getItem("I18N_LANGUAGE");
let group_limit = localStorage.getItem("group_limit");
export class AuctionsListGrouped extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let groupID = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      input: {
        auction: "",
      },
      auctions: {},
      limit: 30,
      meta: {},
      loading: false,
      group: groupID,
      gpData: {},
      orgData: {},
      enrolledUsers: 0,
      lightboxImages: [],
      is_active_light_box: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadGroupedAuctions = this.loadGroupedAuctions.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
    this.searchAuctions = this.searchAuctions.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let lt = group_limit || 30;
    this.loadGroupedAuctions(lt);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadGroupedAuctions(limit = false) {
    localStorage.setItem("group_limit", limit);
    this.setState({ limit: limit });
    let { group } = this.state;
    let q = "";
    if (group) {
      q += "group=" + group;
    }
    if (!isNaN(limit)) {
      q += "&limit=" + limit;
    }

    this.setState({ loading: true });
    const response = await getGroupedAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ auctions: auctions, meta, loading: false });
    }
    if (meta.group) {
      this.setState({ gpData: meta.group });
    }
    if (meta.organization) {
      this.setState({ orgData: meta.organization });
    }
    if (meta.enrolledUsers) {
      this.setState({ enrolledUsers: meta.enrolledUsers });
    }
  }

  async searchAuctions() {
    let { group } = this.state;
    let q = "";
    if (group) {
      q += "group=" + group;
    }
    if (this.state.input.auction) {
      q += "&auction=" + this.state.input.auction;
    }
    q += "&lang=" + lng;

    this.setState({ loading: true });
    const response = await getGroupedAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ auctions: auctions, meta, loading: false });
    }
    if (meta.group) {
      this.setState({ gpData: meta.group });
    }
    if (meta.organization) {
      this.setState({ orgData: meta.organization });
    }
    if (meta.enrolledUsers) {
      this.setState({ enrolledUsers: meta.enrolledUsers });
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
      this.setState({
        is_active_light_box: active,
        lightboxImages: lightboxImages,
      });
    }
  }

  sortByOrder(data) {
    let result = data;
    if (data.length) {
      result = data.sort((a, b) => a.order - b.order);
    }
    return result;
  }

  render() {
    let auctions = this.sortByOrder(this.state.auctions);
    let total_auctions = this.state.meta.total;
    let limit =
      isNaN(this.state.limit) || !this.state.limit
        ? false
        : Number(this.state.limit);
    console.log(typeof limit);
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw pag-btn-width"
        : "btn btn-light btn-sm font-1vw pag-btn-width";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw pag-btn-width"
        : "btn btn-light btn-sm font-1vw pag-btn-width";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw pag-btn-width"
        : "btn btn-light btn-sm font-1vw pag-btn-width";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw pag-btn-width"
        : "btn btn-light btn-sm font-1vw pag-btn-width";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw pag-btn-width"
        : "btn btn-light btn-sm font-1vw pag-btn-width";

    // let user_role = user ? user.role : null;
    // let is_enrolled = this.props.is_enrolled;
    let now = this.state.now;
    let endDate = this.state.gpData.reg_end_date;
    let endTime = new Date(this.createDate(endDate)).getTime();
    return (
      <React.Fragment>
        {this.state.loading && <AuctionListGrouped />}

        {!this.state.loading && (
          <div className="container">
            <div className="row mob-padd-9">
              <div
                className={
                  is_logged
                    ? "section-title center-block text-center active-auc-title"
                    : "section-title center-block text-center"
                }
              >
                <h2 className="text-primary position-relative">
                  {i18n.t("active_auctions")}
                </h2>
                <p className="mt-5">
                  <button
                    className="btn btn-outline-info"
                    style={{ borderRadius: "50%" }}
                    onClick={(e) => {
                      this.loadGroupedAuctions(this.state.limit);
                    }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </p>
                {/* <p className="text-primary-50 position-relative">
              {i18n.t("message_auction_start")}
            </p> */}
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div className="">
                    <div className="item2-gl ">
                      <div className=" mb-0">
                        <div className="">
                          <div className="bg-white p-2 item2-gl-nav">
                            {total_auctions <= 30 && (
                              <div class="container">
                                <div className="row">
                                  <div className="d-flex justify-content-between">
                                    {/* <img
                                      src={
                                        this.state.orgData
                                          .file_organization_image
                                      }
                                      alt=""
                                      style={{
                                        width: "5rem",
                                        height: "auto",
                                      }}
                                    /> */}
                                    <div className="mt-5">
                                      <label
                                        className="font-1vw"
                                        style={{
                                          float: "left",
                                          width: "auto",
                                          margin: "auto",
                                        }}
                                      >
                                        <div className="table-top-text">
                                          {i18n.t("showing")} {total_auctions}{" "}
                                          {i18n.t("auctions")}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {total_auctions > 30 && (
                              <div class="container">
                                <div class="row">
                                  <div class="col-9">
                                    <div className="d-sm-flex mt-5">
                                      <label
                                        className="font-1vw d-none-mob"
                                        style={{
                                          float: "left",
                                          width: "auto",
                                          margin: "10px 0px",
                                        }}
                                      >
                                        {!this.state.limit && (
                                          <div
                                            className="table-top-text"
                                            style={{ marginRight: "5px" }}
                                          >
                                            {i18n.t("showing")} {total_auctions}{" "}
                                            {i18n.t("auctions")}{" "}
                                          </div>
                                        )}
                                        {this.state.limit && (
                                          <div
                                            className="table-top-text"
                                            style={{ marginRight: "5px" }}
                                          >
                                            {i18n.t("showing")} 1 {i18n.t("to")}{" "}
                                            {this.state.limit} {i18n.t("of")}{" "}
                                            {total_auctions} {i18n.t("entries")}{" "}
                                          </div>
                                        )}
                                      </label>
                                      <button
                                        className={lt_30_cls}
                                        onClick={() => {
                                          this.loadGroupedAuctions(30);
                                        }}
                                      >
                                        30
                                      </button>
                                      <button
                                        className={lt_50_cls}
                                        onClick={() => {
                                          this.loadGroupedAuctions(50);
                                        }}
                                      >
                                        50
                                      </button>
                                      <button
                                        className={lt_100_cls}
                                        onClick={() => {
                                          this.loadGroupedAuctions(100);
                                        }}
                                      >
                                        100
                                      </button>
                                      <button
                                        className={lt_200_cls}
                                        onClick={() => {
                                          this.loadGroupedAuctions(200);
                                        }}
                                      >
                                        200
                                      </button>
                                      <button
                                        className={lt_all_cls}
                                        onClick={() => {
                                          this.loadGroupedAuctions();
                                        }}
                                      >
                                        {i18n.t("all")}
                                      </button>
                                    </div>
                                  </div>
                                  <div class="col-1"></div>
                                  <div
                                    class="col-2"
                                    style={{
                                      textAlign:
                                        lng === "ar" ? "left" : "right",
                                    }}
                                  >
                                    <img
                                      src={
                                        this.state.orgData
                                          .file_organization_image
                                      }
                                      alt=""
                                      style={{
                                        width: "5rem",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="container">
                              <div
                                className="d-flex justify-content-between pt-2"
                                style={{ borderTop: "1px solid #ccc" }}
                              >
                                <div
                                  class="d-none-mob"
                                  style={{
                                    textAlign: lng === "ar" ? "right" : "left",
                                  }}
                                >
                                  <p className="mt-3 mb-0">
                                    <span className="text-primary">
                                      {i18n.t("reg_start")}
                                      {" : "}
                                    </span>
                                    {this.state.gpData.reg_start_date_formatted}
                                    &nbsp;
                                    {/* {"to"}
                                    &nbsp;
                                    {this.state.gpData.reg_end_date} */}
                                  </p>
                                </div>
                                <div class="">
                                  <p
                                    className="mt-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="d-none-mob">
                                      {i18n.t("reg_end")} {":"}
                                    </span>
                                    {endDate && (
                                      <Countdown
                                        date={endTime}
                                        renderer={renderer}
                                        now={now}
                                      />
                                    )}
                                  </p>
                                </div>
                                {/* <div class="d-none-mob">
                                  <p
                                    className="mt-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    {i18n.t("Auction End Time : ")}
                                    {endDate && (
                                      <Countdown
                                        date={auctionEndTime}
                                        renderer={renderer}
                                        now={now}
                                      />
                                    )}
                                  </p>
                                </div> */}
                                <div class="">
                                  <p className="mt-1 text-info">
                                    {i18n.t("enrolled_users")} {":"}{" "}
                                    {this.state.enrolledUsers}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="container">
                        <div className="row mt-3">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="auction"
                                className="form-control"
                                placeholder={i18n.t("auctions")}
                                value={this.state.input.auction}
                                onChange={this.handleChange}
                              />
                              {/* <select
                                className="form-control"
                                data-placeholder={i18n.t("auctions")}
                                name="auction"
                                value={this.state.input.auction}
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  --{i18n.t("auctions")}--
                                </option>
                                {auctions.length > 0 &&
                                  auctions.map((item) => {
                                    return (
                                      <option value={item.id} key={item.id}>
                                        {lng === "ar"
                                          ? item.title_ar
                                          : item.title}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <button
                                className="btn btn-primary btn-sm btn-block"
                                type="button"
                                onClick={this.searchAuctions}
                              >
                                {i18n.t("search")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive auctions-list">
                        <div className="bg-white p-1 mb-9">
                          <div className="table-responsive-sm table-condensed">
                            {auctions.length < 1 && (
                              <div className="alert alert-danger text-center">
                                {i18n.t("no_auctions_found")}
                              </div>
                            )}
                            {auctions.length > 0 && (
                              <table className="table table-sm table-striped table-primary font-1vw">
                                <thead>
                                  <tr>
                                    <th
                                      className="text-center font-75rem text-center"
                                      style={{ width: "50px" }}
                                    >
                                      {i18n.t("#")}
                                    </th>
                                    <th className="font-75rem">
                                      {i18n.t("title")}
                                    </th>
                                    <th className="font-75rem px-5">
                                      {i18n.t("location")}
                                    </th>
                                    <th className="font-75rem px-5">
                                      {i18n.t("current_amount")}
                                    </th>
                                    <th className="font-75rem px-5">
                                      {i18n.t("bid_count")}
                                    </th>
                                    <th style={{ width: "15rem" }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {auctions.map((item, i) => {
                                    let key = parseInt(i) + 1;
                                    return (
                                      <AuctionRowGrouped
                                        keys={key}
                                        auction={item}
                                        getImagePopup={this.getImagePopup}
                                        screen="groupList"
                                      />
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
