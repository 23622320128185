import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getPaymentsFromThawani, createRefund } from "../../models/thawani";
import { updatePaymentRefund } from "../../models/payments";
import { getLoggedUser } from "../../state/user";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";

const user = getLoggedUser();
export class PaymentDetails extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let reference = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      is_loading: false,
      details: {},
      reference: reference,
    };

    this.createRefund = this.createRefund.bind(this);
    this.updatePaymentRefund = this.updatePaymentRefund.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadPayments();
  }

  async loadPayments() {
    this.setState({ is_loading: true });
    let reference = this.state.reference;
    let response = await getPaymentsFromThawani(reference);
    if (response.success) {
      this.setState({
        details: response && response.data ? response.data[0] : null,
      });
      this.setState({ is_loading: false });
    }
  }

  async createRefund() {
    let details = this.state.details;
    let payload = {
      payment_id: details.payment_id,
      reason: "Refund requested from user",
      metadata: {
        customer: user.name,
      },
    };
    let response = await createRefund(payload);
    console.log(response);
    this.updatePaymentRefund(response);
  }

  async updatePaymentRefund(res) {
    let reference = this.state.reference;
    let data = res.data;
    let payload = {
      reference: reference,
      payment_id: data.payment_id,
      refund_id: data.refund_id,
    };

    if (res.success && data.refund_id) {
      let response = await updatePaymentRefund(payload);
      console.log(response);
      if (response.success) {
        swal(
          "Success",
          i18n.t("omr") + " " + data.amount + " is refunded to user",
          "success",
          {
            buttons: false,
            timer: 2000,
          }
        ).catch((response) => {
          swal("Failed", "Some errors occured", "error");
        });
        this.loadPayments();
      } else {
        swal("Failed", "Some errors occured", "error");
      }
    }
  }
  render() {
    let details = this.state.details;

    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("Payment Details")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Payment Details</h3>
                  </div>
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div
                        className="text-center"
                        style={{ textAlign: "center", padding: "5rem" }}
                      >
                        <Spinner animation="border" />
                      </div>
                    )}

                    {!this.state.is_loading && (
                      <div className="table-responsive ">
                        {details && (
                          <div>
                            <table className="table table-bordered table-hover text-nowrap">
                              <tr>
                                <td>Payment ID</td>
                                <td> : {details.payment_id}</td>
                              </tr>
                              <tr>
                                <td>Amount</td>
                                <td>
                                  {" "}
                                  : {i18n.t("omr")} {details.amount / 1000}
                                </td>
                              </tr>
                              <tr>
                                <td>Invoice Number</td>
                                <td> : {details.checkout_invoice}</td>
                              </tr>
                              <tr>
                                <td>Card Type</td>
                                <td> : {details.card_type}</td>
                              </tr>
                              <tr>
                                <td>Card(Masked)</td>
                                <td> : {details.masked_card}</td>
                              </tr>
                              {details.refunded && (
                                <tr>
                                  <td>Refunded</td>
                                  <td> : Yes</td>
                                </tr>
                              )}
                              {details.refunded && (
                                <tr>
                                  <td>Refund ID</td>
                                  <td> : {details.refunds[0].refund_id}</td>
                                </tr>
                              )}
                            </table>

                            <div className="row">
                              <div className="col-12 d-flex justify-content-between">
                                <a
                                  className="btn btn-danger btn-sm"
                                  href="/refund"
                                >
                                  Back to listing
                                </a>

                                {!details.refunded && (
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={this.createRefund}
                                  >
                                    Refund
                                  </button>
                                )}

                                {details.refunded && (
                                  <a
                                    className="btn btn-outline-danger btn-sm"
                                    href="/refund"
                                  >
                                    Refund completed. Click to back
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
