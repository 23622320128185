import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
let lang = localStorage.getItem("I18N_LANGUAGE");
export class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/*Footer Section*/}
        <section className={lang === "ar" ? "footer-ar" : ""}>
          <footer className="bg-light-blue text-dark">
            <div className="footer-main">
              <div style={{ marginLeft: "3rem", marginRight: "3rem" }}>
                <div className="row">
                  <div className="col-lg-2 col-md-12 text-center mt-8">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/mzadcom_white.png"
                      }
                      alt=""
                      style={{ width: "30%", border: "none" }}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/omanvision_white.png"
                      }
                      alt=""
                      style={{ width: "70%", border: "none" }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-12">
                    <h6 className="h_w_u">{i18n.t("aboutus")}</h6>
                    <hr className="deep-purple text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled mb-0">
                      <li>
                        <Link to="about">{i18n.t("aboutus")}</Link>
                      </li>
                      {/* <li>
                        <Link to="services">{i18n.t("services")}</Link>
                      </li> */}
                      <li>
                        <Link to="contact">{i18n.t("contactus")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <h6 className="h_w_u">{i18n.t("office_location")}</h6>
                    <iframe
                      title="Mzadcom"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.790892471086!2d58.409820999999994!3d23.5759525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91ffb71995cfc3%3A0x2af865eda725aadc!2sWorld%20Web%20IT%20Solutions%20L.L.C.%20(Webware)!5e0!3m2!1sen!2sin!4v1673105140852!5m2!1sen!2sin"
                      style={{ border: "0", height: "30vh", width: "100%" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <h6 className="h_w_u">{i18n.t("address")}</h6>
                    <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href={true}>
                          <i className="fa fa-address-book me-3 text-white" />
                          {i18n.t("mzad_address_line1")}
                          {i18n.t("mzad_address_line2")}
                          {i18n.t("mzad_address_line3")}
                        </a>
                      </li>
                      <li style={{ direction: "ltr" }}>
                        <a href="mailto:info@mzadcom.om">
                          <i className="fa fa-envelope me-3 text-white" />{" "}
                          info@mzadcom.om
                        </a>
                      </li>
                      <li style={{ direction: "ltr" }}>
                        <a href="tel:+96892788822">
                          <i className="fa fa-phone me-3 text-white" /> +968 92
                          788 822
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-md-12">
                    {/* <h6>Subscribe</h6>
                      <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                      <div className="clearfix" />
                      <div className="input-group w-100">
                        <input
                          type="text"
                          className="form-control br-bs-3  br-be-3"
                          placeholder="Email"
                        />
                        <div className>
                          <button
                            type="button"
                            className="btn btn-primary br-ts-0  br-bs-0"
                          >
                            {i18n.t("subscribe")}
                          </button>
                        </div>
                      </div> */}
                    <h6 className="h_w_u">{i18n.t("follow_us")}</h6>
                    <hr className="deep-purple  accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a
                          href="https://www.facebook.com/profile.php?id=100074132232182"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-facebook bg-facebook" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://twitter.com/mzadcom.om"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-twitter bg-linkedin" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://www.instagram.com/mzadcomom"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-instagram bg-primary" />
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a
                          href={true}
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-linkedin bg-linkedin" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-dark-purple text-white p-0">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-12 col-sm-12 mt-3 mb-3 text-center">
                    {i18n.t("copyrights")} © {i18n.t("defence_ministry")}{" "}
                    {i18n.t("powered_by")}{" "}
                    <a href="https://www.mzadcom.om" className="text-primary">
                      mzadcom.om
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </React.Fragment>
    );
  }
}
