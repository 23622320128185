import React from "react";
import { Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import { getLoggedUser } from "../../state/user";
const user = getLoggedUser();

let lang = localStorage.getItem("I18N_LANGUAGE");
let refreshStyle =
  lang === "ar"
    ? { position: "absolute", left: "7px" }
    : { position: "absolute", right: "7px" };

export class BiddersPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: false,
    };
  }

  render() {
    let top_bidders = this.props.top_bidders;
    //let total_enrolls = this.props.total_enrolls;
    let user = this.props.user;
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{i18n.t("top_3_bidders")}</h3>
            <button
              className="btn btn-primary"
              style={refreshStyle}
              onClick={this.props.loadTopBidders}
            >
              {this.props.refresh_loading ? (
                <span>
                  <Spinner animation="border" size="sm" /> {i18n.t("loading")}
                </span>
              ) : (
                <span>
                  <i className="fa fa-refresh"></i> {i18n.t("refresh")}
                </span>
              )}
            </button>
          </div>
          <div className="card-body">
            {top_bidders && top_bidders.length > 0 && (
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>
                      {user.role === "A" || user.role === "C"
                        ? i18n.t("username")
                        : i18n.t("bidder_id")}
                    </th>
                    <th style={{ textAlign: "center" }}>{i18n.t("rank")}</th>
                    <th style={{ textAlign: "center" }}>{i18n.t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {top_bidders.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className={
                          i > 2 && !this.state.loadMore ? "d-none" : ""
                        }
                      >
                        <td style={{ textAlign: "center" }}>
                          <div>
                            {user.role === "A" || user.role === "C"
                              ? item.username
                              : item.enroll_number}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={
                              item.user_id === user.id
                                ? "badge badge-warning me-2"
                                : "badge badge-pill badge-info me-2"
                            }
                          >
                            {item.user_id === user.id &&
                              i18n.t("my_rank") + " : "}

                            {i + 1}
                          </span>
                        </td>
                        <td>{item.bid_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {top_bidders && top_bidders.length > 3 && !this.state.loadMore && (
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                  color: "#ed2e32",
                }}
              >
                <a
                  style={{ cursor: "pointer" }}
                  href={true}
                  onClick={() => {
                    this.setState({ loadMore: true });
                  }}
                >
                  {i18n.t("expand")}
                </a>
              </div>
            )}

            {this.state.loadMore && (
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                  color: "#ed2e32",
                }}
              >
                <a
                  style={{ cursor: "pointer" }}
                  href={true}
                  onClick={() => {
                    this.setState({ loadMore: false });
                  }}
                >
                  {i18n.t("collapse")}
                </a>
              </div>
            )}

            {top_bidders && top_bidders.length < 1 && (
              <div>{i18n.t("no_bidders_found")}</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
