import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { updateAuction, getAuction } from "../../models/auctions";
import swal from "sweetalert";
import i18n from "../../i18n";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";

export class EnquiriesDetails extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);

    this.state = {
      auctionId: auctionId,
      input: {},
      errors: {},
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      details: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      jsonLabel: "",
      jsonValue: "",
      is_loading: false,
    };
    this.handleEnquirySubmit = this.handleEnquirySubmit.bind(this);
    this.handleDetailsSubmit = this.handleDetailsSubmit.bind(this);

    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);

    this.appendDetailsData = this.appendDetailsData.bind(this);
    this.removeDetailsData = this.removeDetailsData.bind(this);
    this.setDetailsJson = this.setDetailsJson.bind(this);
  }

  componentDidMount() {
    this.loadAuction();
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    let id = this.state.auctionId;
    const response = await getAuction(id);
    let auction = response.data;
    if (auction) {
      let enquiry = auction.enquiry;
      if (enquiry && enquiry.length) {
        this.setState({ enquiries: enquiry });
      }

      let details = auction.details;
      if (details && details.length) {
        this.setState({ details: details });
      }
      this.setState({ is_loading: false });
    }
  }

  handleEnquirySubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.is_enquiry_update = true;
      payload.enquiries = this.state.enquiries;
      let id = this.state.auctionId;
      const response = await updateAuction(payload, id);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = "Not found!!!";
        let error_messages = response.message;
        for (const key in error_messages) {
          if (Object.hasOwnProperty.call(error_messages, key)) {
            let element = error_messages[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleDetailsSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.is_details_update = true;
      payload.details = this.state.details;
      let id = this.state.auctionId;
      const response = await updateAuction(payload, id);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = "Not found!!!";
        let error_messages = response.message;
        for (const key in error_messages) {
          if (Object.hasOwnProperty.call(error_messages, key)) {
            let element = error_messages[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries || [];
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  appendDetailsData(e) {
    e.preventDefault();
    let details = this.state.details || [];
    details.push({ label: "", value: "" });
    this.setState({ details: details });
  }

  removeDetailsData(key) {
    let details = this.state.details;
    delete details[key];
    this.setState({ details: details });
  }

  setDetailsJson(key, event, arrKey) {
    let details = this.state.details;
    details[arrKey][key] = event.target.value;
    this.setState({ details: details });
  }

  render() {
    let enquiries = this.state.enquiries;
    let details = this.state.details;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleEnquirySubmit}
                >
                  <AuctionTabs activeTab="enquiry" />

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <h5>{i18n.t("enquiry")}</h5>

                        {this.state.is_loading && (
                          <div
                            style={{
                              width: "90%",
                              height: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                              textAlign: "center",
                              padding: "1rem",
                            }}
                          >
                            <Spinner animation="border" />
                          </div>
                        )}

                        {enquiries &&
                          enquiries.length > 0 &&
                          enquiries.map((item, i) => {
                            return (
                              <div className="row" key={i}>
                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">Label</label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      placeholder="Label"
                                      value={item.label || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson("label", event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">Value</label>
                                    <input
                                      type="text"
                                      name="jsonValue"
                                      className="form-control"
                                      placeholder="Value"
                                      value={item.value || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson("value", event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Label(Arabic)
                                    </label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      style={{ direction: "rtl" }}
                                      placeholder="Label(Arabic)"
                                      value={item.label_ar || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson(
                                          "label_ar",
                                          event,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-2 col-md-2">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Value(Arabic)
                                    </label>
                                    <input
                                      type="text"
                                      name="jsonValue"
                                      className="form-control"
                                      style={{ direction: "rtl" }}
                                      placeholder="Value"
                                      value={item.value_ar || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson(
                                          "value_ar",
                                          event,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div className="col-sm-1 col-md-1">
                                    <div className="form-group">
                                      <label className="form-label">
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        key={i}
                                        onClick={(event) => {
                                          this.removeEnquiryData(i);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div
                          className="form-group"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={this.appendData}
                            style={{ marginRight: "20px" }}
                          >
                            <i className="fa fa-plus"></i>
                            <span>Add New</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Enquiries
                    </button>
                  </div>
                </form>

                <hr />

                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleDetailsSubmit}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <h5>{i18n.t("details")}</h5>

                        {this.state.is_loading && (
                          <div
                            style={{
                              width: "90%",
                              height: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                              textAlign: "center",
                              padding: "1rem",
                            }}
                          >
                            <Spinner animation="border" />
                          </div>
                        )}

                        {details &&
                          details.length > 0 &&
                          details.map((item, i) => {
                            return (
                              <div className="row" key={i}>
                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">Label</label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      placeholder="Label"
                                      value={item.label || ""}
                                      onChange={(event) => {
                                        this.setDetailsJson("label", event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">Value</label>
                                    <input
                                      type="text"
                                      name="jsonValue"
                                      className="form-control"
                                      placeholder="Value"
                                      value={item.value || ""}
                                      onChange={(event) => {
                                        this.setDetailsJson("value", event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-3 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Label(Arabic)
                                    </label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      style={{ direction: "rtl" }}
                                      placeholder="Label(Arabic)"
                                      value={item.label_ar || ""}
                                      onChange={(event) => {
                                        this.setDetailsJson(
                                          "label_ar",
                                          event,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-2 col-md-2">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Value(Arabic)
                                    </label>
                                    <input
                                      type="text"
                                      name="jsonValue"
                                      className="form-control"
                                      style={{ direction: "rtl" }}
                                      placeholder="Value"
                                      value={item.value_ar || ""}
                                      onChange={(event) => {
                                        this.setDetailsJson(
                                          "value_ar",
                                          event,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div className="col-sm-1 col-md-1">
                                    <div className="form-group">
                                      <label className="form-label">
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        key={i}
                                        onClick={(event) => {
                                          this.removeDetailsData(i);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div
                          className="form-group"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={this.appendDetailsData}
                            style={{ marginRight: "20px" }}
                          >
                            <i className="fa fa-plus"></i>
                            <span>Add New</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
