import React from "react";
import { Link } from "react-router-dom";
import { updatePayment } from "../models/auctions";

export class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());

    let groupId = params.gid;
    let auctionId = params.auc;
    let amount = params.amt;
    let enrollId = params.eid;

    console.log(groupId, auctionId, amount, enrollId);
    this.state = {
      group_id: groupId,
      auction_id: auctionId,
      amount: amount,
      enroll: enrollId,
    };
  }

  async componentDidMount() {
    const response = await updatePayment({
      group_id: this.state.group_id,
      auction_id: this.state.auction_id,
      enroll_id: this.state.enroll,
      amount: this.state.amount / 1000,
      invoice: localStorage.getItem("invoice") || null,
    });
    console.log(response);
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary ">
                  <h1 className="">Payment Success!!!</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="container text-center">
              <div className="display-1 mb-8"></div>
              <h1 className="h2 mb-7 text-success">Payment Success</h1>
              <p className="h4 font-weight-Automatic text-success mb-8 leading-Automatic">
                Success!!!. Now you can do bidding with available auctions of
                same group.
              </p>
              <Link className="btn btn-warning" to="/">
                Back To Home
              </Link>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
