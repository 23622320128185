import React from "react";
import { Link } from "react-router-dom";

export class NotFound extends React.Component {
  componentDidMount() {
    window.location.href = "/";
  }
  render() {
    return (
      <React.Fragment>
        <section className="sptb">
          <div className="construction-image">
            <div className="page page-h">
              <div className="page-content z-index-10">
                <div className="container text-center">
                  <div className="display-1 text-primary mb-8 mt-5">404</div>
                  <h1 className="h2 text-primary  mb-3">Page Not Found</h1>
                  <p className="h4 font-weight-Automatic mb-8 leading-Automatic text-white">
                    Oops!!!! you tried to access a page which is not available.
                    go back to home
                  </p>
                  <Link className="btn btn-danger mb-7" to="/">
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
