import React from "react";
import { AuctionRow } from "../helpers/auctionRow";
import i18n from "../../i18n";
import { getEnrolledAuctions } from "../../models/auctions";
import { SideBar } from "./sidebar";
import { Link } from "react-router-dom";

let lng = localStorage.getItem("I18N_LANGUAGE");
export class EnrolledAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: {},
      limit: 30,
    };
  }

  componentDidMount() {
    this.loadAllAuctions(this.state.limit);
  }

  async loadAllAuctions(limit) {
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    const response = await getEnrolledAuctions(q);
    let auctions = response.data;
    this.setState({ auctions: auctions });
  }

  render() {
    let auctions = this.state.auctions;
    let limit = this.state.limit;
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("auctions")}</h3>
                  </div>

                  {auctions.length === 0 && (
                    <div className="card-body">
                      <div className="alert alert-danger">
                        No auctions found!!!
                      </div>
                    </div>
                  )}
                  {auctions.length > 0 && (
                    <div className="card-body">
                      <div className="">
                        <div className="bg-white p-5 item2-gl-nav">
                          <div className="d-sm-flex">
                            <label
                              className="font-1vw"
                              style={{
                                float: "left",
                                width: "auto",
                                margin: "10px 0px",
                              }}
                            >
                              {!this.state.limit && (
                                <div style={{ marginRight: "5px" }}>
                                  Showing all entries{" "}
                                </div>
                              )}
                              {this.state.limit && (
                                <div style={{ marginRight: "5px" }}>
                                  Showing 1 to {this.state.limit} of 30 entries{" "}
                                </div>
                              )}
                            </label>
                            <button
                              className={lt_30_cls}
                              onClick={() => {
                                this.setState({ limit: 30 });
                                this.loadAllAuctions(30);
                              }}
                            >
                              30
                            </button>
                            <button
                              className={lt_50_cls}
                              onClick={() => {
                                this.setState({ limit: 50 });
                                this.loadAllAuctions(50);
                              }}
                            >
                              50
                            </button>
                            <button
                              className={lt_100_cls}
                              onClick={() => {
                                this.setState({ limit: 100 });
                                this.loadAllAuctions(100);
                              }}
                            >
                              100
                            </button>
                            <button
                              className={lt_200_cls}
                              onClick={() => {
                                this.setState({ limit: 200 });
                                this.loadAllAuctions(200);
                              }}
                            >
                              200
                            </button>
                            <button
                              className={lt_all_cls}
                              onClick={() => {
                                this.setState({ limit: false });
                                this.loadAllAuctions();
                              }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive ">
                        {auctions.length > 0 && (
                          <table className="table table-sm table-striped table-primary font-1vw">
                            <thead>
                              <tr>
                                <th className="font-1vw">{i18n.t("ID")}</th>
                                <th className="font-1vw">{i18n.t("title")}</th>
                                <th className="font-1vw">
                                  {i18n.t("latest_bid_amount")}
                                </th>
                                <th className="font-1vw">
                                  {i18n.t("current_amount")}
                                </th>
                                <th className="font-1vw">
                                  {i18n.t("end_date")}
                                </th>
                                <th className="font-1vw">
                                  {i18n.t("bid_count")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {auctions.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{item.id}</td>
                                    <td>
                                      {lng === "ar" && item.title_ar
                                        ? item.title_ar
                                        : item.title}
                                    </td>
                                    <td>{item.start_amount}</td>
                                    <td>{item.current_amount}</td>
                                    <td>{item.end_date_formatted}</td>
                                    <td>{item.bid_count}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
