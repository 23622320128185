import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getAllOrganization } from "../../models/organization";
import { getAllCategories } from "../../models/categories";
import i18n from "../../i18n";
import { CreateAuctionGeneral } from "./create_auction_general";
import { CreateAuctionNumbers } from "./create_auction_numbers";
export class CreateAuction extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 23.588,
      lng: 58.3829,
      input: {
        //description: RichTextEditor.createEmptyValue(),
      },
      row: [
        {
          phone_number: "",
          class: "",
          start_amount: "",
          bid_increment: "",
          start_date: "",
          end_date: "",
        },
      ],
      tempArray: {},
      imageRow: [{ auction_image: "" }],
      tempImages: {},
      errors: {},
      masks: {},
      groups: {},
      categories: {},
      organizations: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      isChecked: false,
      isFeatured: true,
      categoryList: "",
      places: [],
      uploadImgs: [],
      is_number: false,
      //value: RichTextEditor.createEmptyValue(),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadAllCategories();
    this.loadAllOrganisation();
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let organizations = response.data;
    this.setState({ organizations });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "mask") {
      this.loadMask(event.target.value);
    }
    if (event.target.name === "group") {
      this.loadGroup(event.target.value);
    }
    if (event.target.name === "category") {
      let is_number = false;
      let categories = this.state.categories;
      // eslint-disable-next-line eqeqeq
      let index = categories.findIndex((item) => item.id == event.target.value);
      is_number = categories[index].is_number ? true : false;
      console.log(categories[index]);
      this.setState({ is_number });
    }
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  toggleFeatured = () => {
    this.setState({
      isFeatured: !this.state.isFeatured,
    });
  };
  handleSelect = (selectedOption) => {
    let updateExtraTime = "";
    const checkCategory = (obj) => obj.label === "phoneNumber";
    if (!selectedOption.some(checkCategory)) {
      updateExtraTime = true;
    }
    const categoryArr = selectedOption.map((opt) => opt.value);
    this.setState({ categoryList: categoryArr, isChecked: updateExtraTime });
  };
  handleRowChange(event, i) {
    let row = this.state.row;
    row[i][event.target.name] = event.target.value;
    this.setState({ row });
  }
  render() {
    let categories = this.state.categories;
    let organizations = this.state.organizations;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-primary"
                      aria-current="page"
                    >
                      {i18n.t("create_auction")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div
                className="col-xl-9 col-lg-12 col-md-12 card"
                style={{ padding: 0 }}
              >
                <div className="card-header">
                  <h3 className="card-title">{i18n.t("create_auction")}</h3>
                </div>
                <div
                  className="card-body"
                  style={{ flex: "unset", height: "auto" }}
                >
                  <div className="row">
                    <div
                      className="col-sm-12 col-md-6"
                      style={{ paddingBottom: 0, marginBottom: 0 }}
                    >
                      <div
                        className="form-group"
                        style={{ paddingBottom: 0, marginBottom: 0 }}
                      >
                        <label className="form-label">Category</label>
                        <select
                          className="form-control"
                          data-placeholder="Select Group"
                          name="category"
                          value={this.state.input.category}
                          onChange={this.handleChange}
                        >
                          <option value="">--Category--</option>
                          {categories.length > 0 &&
                            categories.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.category_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div
                      className="col-sm-12 col-md-6"
                      style={{ paddingBottom: 0, marginBottom: 0 }}
                    >
                      <div
                        className="form-group"
                        style={{ paddingBottom: 0, marginBottom: 0 }}
                      >
                        <label className="form-label">Organization</label>
                        <select
                          className="form-control"
                          data-placeholder="Select Group"
                          name="organization"
                          value={this.state.input.organization}
                          onChange={this.handleChange}
                        >
                          <option value="">--Organization--</option>
                          {organizations.length > 0 &&
                            organizations.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    {this.state.is_number && (
                      <CreateAuctionNumbers
                        category={this.state.input.category}
                        organization={this.state.input.organization}
                      />
                    )}

                    {!this.state.is_number && (
                      <CreateAuctionGeneral
                        category={this.state.input.category}
                        organization={this.state.input.organization}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
