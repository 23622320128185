import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";
const user = getLoggedUser();

async function createData(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "blacklist",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllData() {
  return fetch(apiUrl + "blacklist", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getData(id) {
  return fetch(apiUrl + "blacklist/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateData(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "blacklist/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteData(id) {
  return fetch(apiUrl + "blacklist/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export { createData, getAllData, getData, updateData, deleteData };
