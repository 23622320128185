import React from "react";
import { createBulkAuction } from "../../models/auctions";
import { getAllMasks, getMask } from "../../models/masks";
import { getAllGroups, getGroup } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
//import RichTextEditor from 'react-rte';

export class CreateAuctionNumbers extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        is_extra_time: false,
        is_featured: true,
        is_zakath: false,
        //description: RichTextEditor.createEmptyValue(),
      },
      row: [
        {
          phone_number: "",
          class: "",
          start_amount: "",
          bid_increment: "",
          start_date: "",
          end_date: "",
        },
      ],
      tempArray: {},
      errors: {},
      masks: {},
      groups: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      //value: RichTextEditor.createEmptyValue(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleZakath = this.toggleZakath.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    input.category = this.props.category;
    input.organization = this.props.organization;
    this.setState({ input });

    this.loadAllMasks();
    this.loadAllGroups();
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadMask(selected_mask) {
    const response = await getMask(selected_mask);
    let result = response.data;
    let arr = this.state.row;
    for (let i = 0; i < arr.length; i++) {
      arr[i]["class"] = result.class;
      arr[i]["start_amount"] = result.start_amount;
      arr[i]["bid_increment"] = result.bid_increment;
    }
    let tempArray = this.state.tempArray;
    tempArray["class"] = result.class;
    tempArray["start_amount"] = result.start_amount;
    tempArray["bid_increment"] = result.bid_increment;
    this.setState({ row: arr });
  }

  async loadGroup(selected_group) {
    const response = await getGroup(selected_group);
    let result = response.data;
    let arr = this.state.row;
    for (let i = 0; i < arr.length; i++) {
      arr[i]["start_date"] = result.start_date;
      arr[i]["end_date"] = result.end_date;
      arr[i]["description"] = result.description;
      arr[i]["terms"] = result.terms;
      arr[i]["terms_arabic"] = result.terms_arabic;
      arr[i]["enquiry"] = result.enquiry;
    }

    let tempArray = this.state.tempArray;
    tempArray["start_date"] = result.start_date;
    tempArray["end_date"] = result.end_date;
    tempArray["description"] = result.description;
    tempArray["terms"] = result.terms;
    tempArray["terms_arabic"] = result.terms_arabic;
    tempArray["enquiry"] = result.enquiry;
    this.setState({ row: arr, tempArray });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "mask") {
      this.loadMask(event.target.value);
    }
    if (event.target.name === "group") {
      this.loadGroup(event.target.value);
    }
  }

  handleRowChange(event, i) {
    let row = this.state.row;
    row[i][event.target.name] = event.target.value;
    this.setState({ row });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({
      input,
    });
  };

  toggleZakath = () => {
    let input = this.state.input;
    input.is_zakath = !this.state.input.is_zakath;
    this.setState({
      input,
    });
  };

  toggleFeatured = () => {
    let input = this.state.input;
    input.is_featured = !this.state.input.is_featured;
    this.setState({
      input,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.rows = this.state.row;
      payload.enquiries = this.state.enquiries;
      const response = await createBulkAuction(payload);
      if (response && response.data && response.data.success) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            window.location.href = "";
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let row = this.state.row;
    let tempArray = this.state.tempArray;
    row.push({
      phone_number: tempArray.phone_number,
      class: tempArray.class,
      start_amount: tempArray.start_amount,
      bid_increment: tempArray.bid_increment,
      start_date: tempArray.start_date,
      end_date: tempArray.end_date,
      description: tempArray.description,
      terms: tempArray.terms,
      terms_arabic: tempArray.terms_arabic,
      enquiry: tempArray.enquiry,
    });
    this.setState({ row });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let masks = this.state.masks;
    let groups = this.state.groups;
    let rows = this.state.row;
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-md-12" style={{ padding: 0 }}>
          <form
            id="UpdateProfile"
            className=" mb-0"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <div className="card-body" style={{ paddingTop: 0 }}>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("group")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Group"
                      name="group"
                      value={this.state.input.group}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("group")}--</option>
                      {groups.length > 0 &&
                        groups.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.group_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("mask_name")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Mask"
                      name="mask"
                      value={this.state.input.selected_mask}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("mask_name")}--</option>
                      {masks.length > 0 &&
                        masks.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.mask_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <input
                      name="is_extra_time"
                      type="checkbox"
                      checked={this.state.input.is_extra_time}
                      onChange={this.toggleChange}
                      style={{ float: "left", margin: "4px" }}
                    />
                    <label className="form-label" style={{ float: "left" }}>
                      {i18n.t("extra_time")}
                    </label>
                    {this.state.input.is_extra_time && (
                      <input
                        name="extra_time"
                        type="number"
                        className="form-control"
                        checked={this.state.input.extra_time}
                        onChange={this.handleChange}
                        placeholder={i18n.t("in_minutes")}
                        style={{
                          margin: "-8px 8px",
                          width: "19rem",
                          position: "absolute",
                          right: "0.2rem",
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <input
                      name="is_featured"
                      type="checkbox"
                      checked={this.state.isFeatured}
                      onChange={this.handleChange}
                      style={{ float: "left", margin: "4px" }}
                    />
                    <label
                      className="form-label"
                      style={{ float: "left", marginRight: "1rem" }}
                    >
                      Is Featured
                    </label>

                    <input
                      name="is_zakath"
                      type="checkbox"
                      checked={this.state.input.is_zakath}
                      onChange={this.toggleZakath}
                      style={{ float: "left", margin: "4px" }}
                    />
                    <label
                      className="form-label"
                      style={{ float: "left", marginRight: "1rem" }}
                    >
                      Is Zakath
                    </label>
                  </div>
                </div>

                {rows.length > 0 &&
                  rows.map((item, i) => {
                    return (
                      <div className="container">
                        <hr />
                        <div className="row bg bg-light">
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("number")} {i + 1}
                              </label>
                              <input
                                type="text"
                                required={true}
                                name="phone_number"
                                className="form-control"
                                placeholder={i18n.t("number") + " " + (i + 1)}
                                value={this.state.row[i].phone_number}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("package")}
                              </label>
                              <select
                                className="form-control"
                                data-placeholder="Select Class"
                                name="class"
                                value={this.state.row[i].class}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              >
                                <option value="">
                                  --{i18n.t("package")}--
                                </option>
                                <option value="D">{i18n.t("diamond")}</option>
                                <option value="G">{i18n.t("gold")}</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("price")}
                              </label>
                              <input
                                type="text"
                                name="start_amount"
                                className="form-control"
                                placeholder="Price"
                                value={this.state.row[i].start_amount}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              rows && rows.length === i + 1
                                ? "col-sm-12 col-md-2"
                                : "col-sm-12 col-md-4"
                            }
                          >
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("incr_amount")}
                              </label>
                              <input
                                type="text"
                                name="bid_increment"
                                className="form-control"
                                placeholder="Increment Amount"
                                value={this.state.row[i].bid_increment}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("start_date")}{" "}
                                {this.state.row[i].start_date
                                  ? "(" + this.state.row[i].start_date + ")"
                                  : ""}
                              </label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                placeholder="Email"
                                name="start_date"
                                value={this.state.row[i].start_date}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("end_date")}{" "}
                                {this.state.row[i].end_date
                                  ? "(" + this.state.row[i].end_date + ")"
                                  : ""}
                              </label>
                              <input
                                type="datetime-local"
                                name="end_date"
                                className="form-control"
                                placeholder="Phone"
                                value={this.state.row[i].end_date}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          {rows && rows.length === i + 1 && (
                            <div className="col-sm-12 col-md-2">
                              <div
                                className="form-group"
                                style={{ textAlign: "right" }}
                              >
                                <label className="form-label">&nbsp;</label>
                                <button
                                  type="button"
                                  className="btn btn-info btn-block"
                                  onClick={this.appendData}
                                  style={{ marginRight: "20px" }}
                                >
                                  <i className="fa fa-plus"></i>
                                  <span>Add</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="card-footer" style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-primary">
                Save Auction
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
