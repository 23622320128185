import React from "react";
import { Spinner } from "react-bootstrap";
import i18n from "../../../i18n";
import { getAllGroups } from "../../../models/groups";
import { getAllAuctionsByGroup } from "../../../models/auctions";

let lang = localStorage.getItem("I18N_LANGUAGE");
export class DashboardIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      dash_panel: true,
      groups: {},
      auctions: {},
      auction_loader: false,
    };
    this.loadAllGroups = this.loadAllGroups.bind(this);
    this.loadByGroup = this.loadByGroup.bind(this);
    this.loadAuctions = this.loadAuctions.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    if (event.target.name === "group") {
      this.loadAuctions(this.state.input.group);
      input.auction = "";
    }
    this.setState({ input });
    this.loadByGroup();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctions(group) {
    if (group) {
      this.setState({ auction_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ auction_loader: false });
        this.setState({ auctions: response.data });
      }
    }
  }

  loadByGroup() {
    this.props.loadByGroup(this.state.input);
  }

  hideSideBar() {
    this.props.hideSideBar(!this.props.is_side_bar);
  }

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            {/** onClick={() => {
            this.setState({
              dash_panel: !this.state.dash_panel,
            });
          }}
          role="button" */}
            <span
              style={{ fontSize: "1rem", marginRight: "0.5rem" }}
              onClick={this.hideSideBar}
            >
              {this.props.is_side_bar && (
                <i
                  className={
                    lang === "ar" ? "fa fa-chevron-left" : "fa fa-chevron-right"
                  }
                ></i>
              )}
              {!this.props.is_side_bar && (
                <i
                  className={
                    lang === "ar" ? "fa fa-chevron-right" : "fa fa-chevron-left"
                  }
                ></i>
              )}
            </span>
            <select
              className="form-control"
              data-placeholder="Select Group"
              name="group"
              value={this.state.input.group}
              onChange={this.handleChange}
              style={{ width: "30%" }}
            >
              <option value="">{i18n.t("all")}</option>
              {groups.length > 0 &&
                groups.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {lang === "ar" &&
                      item.group_name_ar &&
                      item.group_name_ar !== "null"
                        ? item.group_name_ar
                        : item.group_name}
                    </option>
                  );
                })}
            </select>

            <select
              className="form-control"
              data-placeholder="Select Class"
              name="auction"
              value={this.state.input.auction}
              onChange={this.handleChange}
              style={{ width: "50%" }}
              disabled={!this.state.input.group}
            >
              <option value="">
                {!this.state.auction_loader
                  ? i18n.t("auctions")
                  : i18n.t("loading..")}
              </option>
              {!this.state.auction_loader &&
                auctions.length > 0 &&
                auctions.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {lang === "ar" ? item.title_ar : item.title}
                    </option>
                  );
                })}
            </select>

            <span>
              {this.props.dd_loading && (
                <Spinner animation="border" size="sm" />
              )}
              {!this.props.dd_loading && (
                <i className="fa fa-refresh" onClick={this.loadByGroup} />
              )}
            </span>
          </div>

          <div className={this.state.dash_panel ? "row m-3" : "row m-3 d-none"}>
            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#f5f5f5 !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/1.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-warning">
                      {i18n.t("active_auctions")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-warning">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading && this.props.active_auctions}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/2.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-info">
                      {i18n.t("active_bidders")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-info">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading && this.props.active_bidders}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/3.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-primary">
                      {i18n.t("total_bidders")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-primary">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading && this.props.total_bidders}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/7.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-info">
                      {i18n.t("total_bids")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-info">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading && this.props.total_bids}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/5.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-success">
                      {i18n.t("total_amount")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-success">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading && this.props.total_amount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/6.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-dark">
                      {i18n.t("auction_in_bidding")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-dark">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading &&
                          this.props.auction_in_bidding}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div
                className="card flex-row mt-3 mb-3"
                style={{ background: "#3199dd !important" }}
              >
                <div className="card-icon-custom p-1">
                  <img src="assets/dashboard/4.png" alt="" />
                </div>
                <div className="card-body-custom pt-5">
                  <div className="mb-4">
                    <h5 className="card-title mb-0 text-capitalize text-secondary">
                      {i18n.t("maximum_bid_amount")}
                    </h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-12">
                      <h4 className="d-flex align-items-center mb-0 text-secondary">
                        {this.props.dd_loading && (
                          <Spinner animation="border" size="sm" />
                        )}
                        {!this.props.dd_loading &&
                          this.props.maximum_bid_amount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
